import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import { getReportsPaymentsRequest, exportReportsPaymentsExcelRequest } from 'utils/api/baas/reports/payments';

import {
  getReportsPayments,
  getReportsPaymentsByPagination,
  getReportsPaymentsBySorting,
  exportReportsPayments,
} from './actions';

export function* handleGetReportsPaymentsRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsPayments.request());

    const { data, pagination } = yield call(baasRequest, getReportsPaymentsRequest, filters);

    yield put(getReportsPayments.success({ ...data, pagination }));
  } catch (err) {
    yield put(getReportsPayments.failure(err));
  }
}

export function* handleGetReportsPaymentsByPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsPaymentsByPagination.request());

    const { data: payments, pagination } = yield call(baasRequest, getReportsPaymentsRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getReportsPaymentsByPagination.success({ payments, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getReportsPaymentsByPagination.failure(err));
  }
}

export function* handleGetReportsPaymentsBySortingRequest(action) {
  const filters = action.payload;

  try {
    yield put(getReportsPaymentsBySorting.request());

    const { data, pagination } = yield call(baasRequest, getReportsPaymentsRequest, filters);

    yield put(getReportsPaymentsBySorting.success({ payments: data, pagination }));
  } catch (err) {
    yield put(getReportsPaymentsBySorting.failure(err));
  }
}

export function* handleExportReportsPaymentsExcelRequest(action) {
  const filters = action.payload;

  try {
    yield put(exportReportsPayments.request());

    const documentData = yield call(baasRequest, exportReportsPaymentsExcelRequest, filters);

    yield put(exportReportsPayments.success(documentData));
  } catch (err) {
    yield put(exportReportsPayments.failure(err));
  }
}

export default function* reportsPaymentsSaga() {
  yield takeLatest(getReportsPayments.TRIGGER, handleGetReportsPaymentsRequest);
  yield takeLatest(getReportsPaymentsByPagination.TRIGGER, handleGetReportsPaymentsByPaginationRequest);
  yield takeLatest(getReportsPaymentsBySorting.TRIGGER, handleGetReportsPaymentsBySortingRequest);
  yield takeLatest(exportReportsPayments.TRIGGER, handleExportReportsPaymentsExcelRequest);
}
