import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TabMui from '@material-ui/core/Tab';

const TabBase = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: theme.typography.pxToRem(125),
  },

  textColorPrimary: {
    color: theme.palette.common.black,
    '&$selected': {
      color: theme.palette.common.black,
    },
  },

  selected: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))(TabMui);

function Tab({ ...others }) {
  return <TabBase disableFocusRipple disableRipple {...others} />;
}

Tab.propTypes = {
  ...TabMui.propTypes,
};

export default Tab;
