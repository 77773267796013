export const GET_REPORTS_DISCOUNTS_RESUME = 'providers/ReportsDiscountsProvider/GET_REPORTS_DISCOUNTS_RESUME';
export const GET_REPORTS_DISCOUNTS_RESUME_BY_PAGINATION =
  'providers/ReportsDiscountsProvider/GET_REPORTS_DISCOUNTS_RESUME_BY_PAGINATION';
export const GET_REPORTS_DISCOUNTS_RESUME_BY_SORTING =
  'providers/ReportsDiscountsProvider/GET_REPORTS_DISCOUNTS_RESUME_BY_SORTING';
export const GET_REPORTS_DISCOUNTS_DETAILS = 'providers/ReportsDiscountsProvider/GET_REPORTS_DISCOUNTS_DETAILS';
export const GET_REPORTS_DISCOUNTS_DETAILS_BY_PAGINATION =
  'providers/ReportsDiscountsProvider/GET_REPORTS_DISCOUNTS_DETAILS_BY_PAGINATION';
export const GET_REPORTS_DISCOUNTS_DETAILS_BY_SORTING =
  'providers/ReportsDiscountsProvider/GET_REPORTS_DISCOUNTS_DETAILS_BY_SORTING';
export const EXPORT_REPORTS_DISCOUNTS = 'providers/ReportsDiscountsProvider/EXPORT_REPORTS_DISCOUNTS';
