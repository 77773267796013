import React, { memo, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import arrayMove from 'utils/array';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { withModifierGroups } from 'containers/ModifierGroupsProvider';

import Button from '@material-ui/core/Button';

import useUserStore from 'hooks/useUserStore';
import InfoToolTip from 'components/InfoToolTip';

import OptionsTable from './OptionsTable';
import OptionSelect from './OptionSelect';
import ModifierGroupSelect from './ModifierGroupSelect';

function ModifierOptions({
  currentModifierGroup,
  options,
  selectedOptions,
  onChange,
  handleAddOption,
  modifierGroupsList,
  loadModifierGroups,
  handleEditOptionPrice,
  handleEditOptionMenuOverridePrice,
  handleEditConditionalOptionPrice,
  handleEditConditionalOptionMenuOverridePrice,
  handleExpandOption,
  // Conditional Group
  conditionalGroup,
  updateOptionsContext,
  // Final Group
  handleRemoveFinalGroup,
  t,
}) {
  const { storeId: storeUuid } = useUserStore();
  const [showModifierGroupSelect, setShowModifierGroupSelect] = useState(false);
  const handleRemoveOption = (value) => {
    onChange(selectedOptions.filter((option) => option !== value));
  };
  useEffect(() => {
    setShowModifierGroupSelect(Boolean(conditionalGroup));
  }, [conditionalGroup]);
  const handleShowModifierGroupSelect = () => setShowModifierGroupSelect(!showModifierGroupSelect);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(selectedOptions, oldIndex, newIndex));
  };
  useEffect(() => {
    loadModifierGroups({ storeUuid, allModifiers: true });
  }, [loadModifierGroups, storeUuid]);

  // This ensures we don't show options which are already selected.
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <OptionSelect
            handleAddOption={handleAddOption}
            onChange={onChange}
            options={options}
            selectedOptions={selectedOptions}
          />
          {showModifierGroupSelect && (
            <ModifierGroupSelect
              conditionalGroup={conditionalGroup}
              currentModifierGroup={currentModifierGroup}
              modifierGroups={modifierGroupsList}
              updateOptionsContext={updateOptionsContext}
            />
          )}
        </Grid>

        <Grid item xs={5}>
          <InfoToolTip
            message={
              <div>
                {t('menuMaker:modifierGroupForm.labels.conditionalsInfo.line1')}
                <br />
                <br />
                {t('menuMaker:modifierGroupForm.labels.conditionalsInfo.line2')}
                <br />
                {t('menuMaker:modifierGroupForm.labels.conditionalsInfo.line3')}
              </div>
            }
          >
            <Button
              color="default"
              disabled={selectedOptions.length === 0}
              endIcon={<HelpOutlineOutlinedIcon />}
              onClick={handleShowModifierGroupSelect}
              size="large"
              style={{ marginTop: 5 }}
              variant="text"
            >
              {t('menuMaker:modifierGroupForm.labels.setConditionalPrices')}
            </Button>
          </InfoToolTip>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid item xs={7}>
          <OptionsTable
            conditionalGroup={conditionalGroup}
            handleEditConditionalOptionMenuOverridePrice={handleEditConditionalOptionMenuOverridePrice}
            handleEditConditionalOptionPrice={handleEditConditionalOptionPrice}
            handleEditOptionMenuOverridePrice={handleEditOptionMenuOverridePrice}
            handleEditOptionPrice={handleEditOptionPrice}
            handleExpandOption={handleExpandOption}
            handleRemoveFinalGroup={handleRemoveFinalGroup}
            handleRemoveOption={handleRemoveOption}
            onSortEnd={onSortEnd}
            selectedOptions={selectedOptions}
          />
        </Grid>
      </Box>
    </>
  );
}

ModifierOptions.propTypes = {
  currentModifierGroup: PropTypes.object,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  handleAddOption: PropTypes.func,
  // Modifier Groups
  modifierGroupsList: PropTypes.array,
  loadModifierGroups: PropTypes.func,
  // Conditional Group
  conditionalGroup: PropTypes.object,
  updateOptionsContext: PropTypes.func,
  // Prices Edition
  handleEditOptionPrice: PropTypes.func,
  handleEditOptionMenuOverridePrice: PropTypes.func,
  handleEditConditionalOptionPrice: PropTypes.func,
  handleEditConditionalOptionMenuOverridePrice: PropTypes.func,
  handleExpandOption: PropTypes.func,
  // Final Group
  handleRemoveFinalGroup: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withModifierGroups, withTranslation('menuMaker'), memo)(ModifierOptions);
