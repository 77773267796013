import React from 'react';
import PropTypes from 'prop-types';

import LabelWithTooltip from 'components/LabelWithTooltip';

import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

function CheckboxWithLabel({ label, defaultValue, informationText, onChange, inputProps, inputRef }) {
  // const [isChecked, setIsChecked] = React.useState(defaultValue);

  function renderLabel() {
    return <LabelWithTooltip label={label} tooltipText={informationText} />;
  }

  const handleChange = (event) => {
    onChange(event.target.checked);
    // defaultValue = event.target.checked;
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={defaultValue}
            inputProps={inputProps}
            inputRef={inputRef}
            name="checkboxWithLabel"
            onChange={handleChange}
          />
        }
        label={renderLabel()}
      />
    </FormGroup>
  );
}

CheckboxWithLabel.defaultProps = {
  defaultValue: false,
};

CheckboxWithLabel.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  informationText: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  inputRef: PropTypes.func,
};

export default CheckboxWithLabel;
