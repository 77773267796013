import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  priceTexField: {
    borderRadius: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'right',
    padding: theme.typography.pxToRem(10),
    color: '#2e2e2e',
    '& > input': {
      padding: 0,
      textAlign: 'right',
    },
  },
  searchBox: {
    marginBottom: theme.typography.pxToRem(14),
  },
  hidden: {
    display: 'none !important',
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    '& td, & th': {
      padding: `${theme.typography.pxToRem(12)}`,
    },
    '& caption': {
      ...theme.typography.body2,
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: 'left',
      captionSide: 'bottom',
    },
  },
  tableCollapse: {
    display: 'table',
    width: '100%',
    '& td, & th': {
      padding: `${theme.typography.pxToRem(10)}`,
      borderBottom: 'none',
      borderTop: '1px dashed #efefef',
    },
  },
  addOptionButton: {
    visibility: 'hidden',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  priceLabel: {
    background: '#efefef',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    padding: `${theme.typography.pxToRem(5)}`,
    borderRadius: theme.typography.pxToRem(4),
  },
  listContainer: {
    width: 'fit-content',
    border: '1px solid #0000003b',
    borderRadius: theme.typography.pxToRem(5),
    marginTop: theme.typography.pxToRem(14),

    '& li': {
      paddingLeft: theme.typography.pxToRem(0),

      '& button': {
        padding: `0 ${theme.typography.pxToRem(14)}`,
      },

      '& div': {
        paddingRight: `${theme.typography.pxToRem(36)}`,
      },
    },
  },
  modifierGroupContainer: {
    padding: theme.typography.pxToRem(25),
    border: '1px dashed #7F7F80',
    marginBottom: theme.typography.pxToRem(15),
    borderRadius: theme.typography.pxToRem(8),
  },
  modifierGroupBox: {
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(10)}`,
  },
  modifierGroupName: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: 0,
    color: '#2e2e2e',
    fontWeight: 600,
  },
  modifierGroupOptions: {
    fontSize: theme.typography.pxToRem(15),
  },
  actionItem: {
    fontSize: theme.typography.pxToRem(14),
    padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(30)}`,
  },
  finalGroupHead: {
    background: '#efefef',
    '& .name': {
      padding: `0 0 0 ${theme.typography.pxToRem(8)}`,
    },
    '& .close-button': {
      padding: 0,
    },
  },
}));

export default useStyles;
