import { useState, useEffect } from 'react';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import ButtonBaseMui from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';

import useBrands from 'containers/BrandsProvider/useBrands';
import useIconParrot from 'hooks/useIconParrot';

const ButtonBase = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    height: theme.typography.pxToRem(56),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.darkGrey[2],
    paddingInline: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))(ButtonBaseMui);

const MenuBase = withStyles(() => ({
  paper: {
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}))(Menu);

const MenuItemBase = withStyles((theme) => ({
  root: {
    padding: '12px 16px',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    '&$focusVisible': {
      backgroundColor: theme.palette.darkGrey[2],
    },
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.darkGrey[2],
    },

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[2],
    },
  },
}))(MenuItem);

const useStyles = makeStyles(() => ({
  iconOpenMenu: {
    transform: 'rotate(180deg)',
  },
}));

function MenuBrands() {
  const classes = useStyles();
  const { brands, brandsState, getBrands, loading: loadingBrands, setSelectedBrand, brandSelected } = useBrands();
  const { IconRestaurant, IconChevronDown } = useIconParrot({ icons: ['restaurant', 'chevronDown'] });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isEmpty(brandSelected)) {
      getBrands();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brandsState.isLoaded && brands.length && isEmpty(brandSelected)) {
      setSelectedBrand(brands[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsState.isLoaded, brands]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectedItem = (value) => () => {
    setSelectedBrand(value);
    setAnchorEl(null);
  };

  if (loadingBrands) {
    return <Skeleton height={56} variant="rect" width={200} />;
  }

  return (
    <Box>
      {brands?.length > 0 && (
        <ButtonBase disableRipple onClick={handleOpenMenu}>
          <IconRestaurant />
          <Box component="span">{brandSelected?.name}</Box>
          <IconChevronDown className={clsx({ [classes.iconOpenMenu]: Boolean(anchorEl) })} fontSize="small" />
        </ButtonBase>
      )}

      <MenuBase
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {brands?.map((brand) => (
          <MenuItemBase
            key={brand.uuid}
            disableRipple
            name={brand.name}
            onClick={handleSelectedItem(brand)}
            selected={brandSelected?.uuid === brand.uuid}
          >
            {brand.name}
          </MenuItemBase>
        ))}
      </MenuBase>
    </Box>
  );
}

export default MenuBrands;
