export async function createFileFromUrl(url) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url.replace(/\?.+/, '').split('/').pop();
  return new File([data], filename, metadata);
}

const MIN_WIDTH = 320;
const MIN_HEIGTH = 320;
const MAX_WIDTH = 1144;
const MAX_HEIGHT = 1144;

function inRange(value, a, b) {
  const min = Math.min.apply(Math, [a, b]);
  const max = Math.max.apply(Math, [a, b]);
  return value >= min && value <= max;
}

export function inWidthSizeImage(size) {
  return inRange(size, MIN_WIDTH, MAX_WIDTH);
}

export function inHeightSizeImage(size) {
  return inRange(size, MIN_HEIGTH, MAX_HEIGHT);
}

const MIN_HEIGHT_48_PX = 48;

export const sizesTypes = {
  MIN_HEIGHT_48_PX,
};

export function generateFileOfURL(fileURL) {
  if (fileURL && typeof fileURL === 'string') {
    const url = fileURL;

    const nameArray = url?.split('/');
    const nameString = nameArray[nameArray?.length - 1];

    const file = new File([url], nameString);

    return { name: nameString, file };
  }

  return false;
}

const MIN_WIDTH_250 = 250;
const MIN_HEIGHT_250 = 250;
const MAX_WIDTH_500 = 500;
const MAX_HEIGHT_500 = 500;

export function inWidthBrandImage(size) {
  return inRange(size, MIN_WIDTH_250, MAX_WIDTH_500);
}

export function inHeightBrandImage(size) {
  return inRange(size, MIN_HEIGHT_250, MAX_HEIGHT_500);
}
