import { createSelector } from 'reselect';

import { getFilteredPaymentsByText } from 'utils/payments';

import { initialState } from './reducer';

const selectPaymentsProviderDomain = (state) => state.restaurantPaymentsProvider || initialState;

const selectPayments = createSelector(selectPaymentsProviderDomain, ({ getPayments, sorting }) => {
  const paymentsList = [...getPayments.payments];
  let list = [...paymentsList];

  const { filterByText } = sorting;

  if (filterByText && filterByText.length >= 3) {
    list = getFilteredPaymentsByText(getPayments.payments, sorting);
  }

  return list?.filter(({ enableForAssignment }) => enableForAssignment !== false);
});

const selectPaymentsSortingState = createSelector(selectPaymentsProviderDomain, ({ sorting }) => sorting);

const selectPaymentsFetching = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.getPayments.fetching,
);

const selectPaymentsFetched = createSelector(selectPaymentsProviderDomain, (subState) => subState.getPayments.fetched);

const selectPaymentsError = createSelector(selectPaymentsProviderDomain, (subState) => subState.getPayments.error);

const selectCreatePayment = createSelector(selectPaymentsProviderDomain, (subState) => subState.createPayment.payment);

const selectCreatePaymentFetching = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.createPayment.fetching,
);

const selectCreatePaymentFetched = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.createPayment.fetched,
);

const selectCreatePaymentError = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.createPayment.error,
);

const selectDeletePaymentFetching = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.deletePayment.fetching,
);

const selectDeletePaymentFetched = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.deletePayment.fetched,
);

const selectDeletePaymentError = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.deletePayment.error,
);

const selectTogglePaymentAvailabilityFetching = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.togglePaymentAvailability.fetching,
);

const selectTogglePaymentAvailabilityFetched = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.togglePaymentAvailability.fetched,
);

const selectTogglePaymentAvailabilityError = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.togglePaymentAvailability.error,
);

const selectPaymentDetails = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.getPaymentDetail.payment,
);

const selectPaymentDetailsFetching = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.getPaymentDetail.fetching,
);

const selectPaymentDetailsError = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.getPaymentDetail.error,
);

const selectPaymentDetailsFetched = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.getPaymentDetail.fetched,
);

const selectEditPayment = createSelector(selectPaymentsProviderDomain, (subState) => subState.editPayment.payment);

const selectEditPaymentFetching = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.editPayment.fetching,
);

const selectEditPaymentFetched = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.editPayment.fetched,
);

const selectEditPaymentError = createSelector(selectPaymentsProviderDomain, (subState) => subState.editPayment.error);

const selectPaymentsPaginationInfo = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.getPayments.pagination,
);

const selectGetPaymentsPaginationScrollState = createSelector(
  selectPaymentsProviderDomain,
  (subState) => subState.paginationGetPayments,
);

export {
  selectPaymentsProviderDomain,
  selectPayments,
  selectPaymentsSortingState,
  selectPaymentsFetching,
  selectPaymentsFetched,
  selectPaymentsError,
  selectCreatePayment,
  selectCreatePaymentFetching,
  selectCreatePaymentFetched,
  selectCreatePaymentError,
  selectDeletePaymentFetching,
  selectDeletePaymentFetched,
  selectDeletePaymentError,
  selectTogglePaymentAvailabilityFetching,
  selectTogglePaymentAvailabilityFetched,
  selectTogglePaymentAvailabilityError,
  selectPaymentDetails,
  selectPaymentDetailsFetching,
  selectPaymentDetailsError,
  selectPaymentDetailsFetched,
  selectEditPayment,
  selectEditPaymentFetching,
  selectEditPaymentFetched,
  selectEditPaymentError,
  selectPaymentsPaginationInfo,
  selectGetPaymentsPaginationScrollState,
};
