/**
 *
 * ParrotSoftware
 * PaymentsGatewayProvider constants
 *
 */
export const GET_PAYMENTS_METHODS = 'providers/PaymentsGatewayProvider/GET_PAYMENTS_METHODS';
export const CREATE_PAYMENT_METHOD = 'providers/PaymentsGatewayProvider/CREATE_PAYMENT_METHOD';
export const RESUME_PAYMENT_METHOD = 'providers/PaymentsGatewayProvider/RESUME_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD_STATUS = 'providers/PaymentsGatewayProvider/UPDATE_PAYMENT_METHOD_STATUS';
export const REMOVE_PAYMENT_METHOD = 'providers/PaymentsGatewayProvider/REMOVE_PAYMENT_METHOD';
