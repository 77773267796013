import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getPayments,
  getPaymentDetail,
  createPayment,
  deletePayment,
  togglePaymentAvailability,
  editPayment,
  updatePaymentPosition,
  getPaymentsScrollPagination,
} from './actions';

import {
  selectPayments,
  selectPaymentsFetching,
  selectPaymentsFetched,
  selectPaymentsError,
  selectCreatePayment,
  selectCreatePaymentFetching,
  selectCreatePaymentFetched,
  selectCreatePaymentError,
  selectDeletePaymentFetching,
  selectDeletePaymentFetched,
  selectDeletePaymentError,
  selectTogglePaymentAvailabilityFetching,
  selectTogglePaymentAvailabilityFetched,
  selectTogglePaymentAvailabilityError,
  selectPaymentDetails,
  selectPaymentDetailsFetching,
  selectPaymentDetailsFetched,
  selectPaymentDetailsError,
  selectEditPayment,
  selectEditPaymentFetching,
  selectEditPaymentFetched,
  selectEditPaymentError,
  selectPaymentsPaginationInfo,
  selectGetPaymentsPaginationScrollState,
  selectPaymentsSortingState,
} from './selectors';

export function withRestaurantPaymentsHoc(Component) {
  function withRestaurantPayments(props) {
    return <Component {...props} />;
  }

  withRestaurantPayments.displayName = `withRestaurantPayments(${getDisplayName(Component)})`;

  return withRestaurantPayments;
}

const mapStateToProps = createStructuredSelector({
  paymentsList: selectPayments,
  paymentsFetching: selectPaymentsFetching,
  paymentsFetched: selectPaymentsFetched,
  paymentsError: selectPaymentsError,
  createPaymentObject: selectCreatePayment,
  createPaymentFetching: selectCreatePaymentFetching,
  createPaymentFetched: selectCreatePaymentFetched,
  createPaymentError: selectCreatePaymentError,
  deletePaymentFetching: selectDeletePaymentFetching,
  deletePaymentFetched: selectDeletePaymentFetched,
  deletePaymentError: selectDeletePaymentError,
  togglePaymentAvailabilityFetching: selectTogglePaymentAvailabilityFetching,
  togglePaymentAvailabilityFetched: selectTogglePaymentAvailabilityFetched,
  togglePaymentAvailabilityError: selectTogglePaymentAvailabilityError,
  paymentDetailObject: selectPaymentDetails,
  paymentDetailFetching: selectPaymentDetailsFetching,
  paymentDetailFetched: selectPaymentDetailsFetched,
  paymentDetailError: selectPaymentDetailsError,
  paymentEditObject: selectEditPayment,
  paymentEditFetching: selectEditPaymentFetching,
  paymentEditFetched: selectEditPaymentFetched,
  paymentEditError: selectEditPaymentError,
  paymentsPaginationInfo: selectPaymentsPaginationInfo,
  getPaymentsPaginationScrollState: selectGetPaymentsPaginationScrollState,
  sortingState: selectPaymentsSortingState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadPayments: (storeUuid) => dispatch(getPayments(storeUuid)),
    loadPaymentDetail: ({ storeUuid, paymentUuid }) => dispatch(getPaymentDetail({ storeUuid, paymentUuid })),
    createPayment: ({ storeUuid, payment }) => dispatch(createPayment({ storeUuid, payment })),
    deletePayment: ({ storeUuid, paymentUuid }) => dispatch(deletePayment({ storeUuid, paymentUuid })),
    togglePaymentAvailability: ({ storeUuid, paymentUuid, availability }) =>
      dispatch(togglePaymentAvailability({ storeUuid, paymentUuid, availability })),
    editPayment: ({ storeUuid, paymentUuid, payment }) => dispatch(editPayment({ storeUuid, paymentUuid, payment })),
    resetPayments: () => dispatch(getPayments.reset()),
    resetPaymentDetail: () => dispatch(getPaymentDetail.reset()),
    resetCreatePayment: () => dispatch(createPayment.reset()),
    resetDeletePayment: () => dispatch(deletePayment.reset()),
    resetTogglePaymentAvailability: () => dispatch(togglePaymentAvailability.reset()),
    resetEditPayment: () => dispatch(editPayment.reset()),
    updatePaymentPosition: (values) => dispatch(updatePaymentPosition(values)),
    loadPaymentsScrollPagination: (filters = {}) => dispatch(getPaymentsScrollPagination(filters)),
    resetLoadPaymentsScrollPagination: () => dispatch(getPaymentsScrollPagination.reset()),
    sortingPayments: (sorting = {}) => dispatch(getPayments.sorting(sorting)),
    resetSorting: () => dispatch(getPayments.resetSorting()),
    sortPosition: (payments) => dispatch(getPayments.sortPosition(payments)),
  };
}

export const withRestaurantPaymentsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRestaurantPaymentsState, withRestaurantPaymentsHoc);
