import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function CategoriesProvider({ children }) {
  useInjectSaga({ key: 'categoriesProvider', saga });
  useInjectReducer({ key: 'categoriesProvider', reducer });

  return React.Children.only(children);
}

CategoriesProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withCategories } from './withCategories';
export default compose(withConnect, memo)(CategoriesProvider);
