import { useCallback, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectAuthUser } from 'containers/AuthProvider/selectors';
import { setStore } from 'containers/AuthProvider/actions';

import { getUserStoresStoreKey, setUserCurrentStore, getUserCurrentStore } from 'utils/auth';

function useUserStore() {
  const dispatch = useDispatch();

  const allStoresRef = useRef(getUserStoresStoreKey());
  const storeUuid = getUserCurrentStore()?.uuid;

  const authUser = useSelector(selectAuthUser);
  const currentStoreRef = useRef(getUserCurrentStore());

  const [storeState, setStoreState] = useState({ selected: currentStoreRef.current, stores: allStoresRef.current });

  const handleSetStore = useCallback(
    (store) => {
      setUserCurrentStore(store);
      setStoreState((currentState) => ({ ...currentState, selected: store }));

      dispatch(setStore.setStore());
    },
    [dispatch],
  );

  return {
    storeId: storeUuid, // @depreciated
    storeUuid, // @depreciated
    storeName: currentStoreRef.current?.name,
    user: authUser, // @depreciated
    storeState,
    userState: {
      ...authUser,
    },
    setStore: handleSetStore,
  };
}

export default useUserStore;
