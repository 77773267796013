import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getProduct, createProduct } from './actions';
import {
  // Get Product
  selectGetProductInstance,
  selectGetProductError,
  selectGetProductFetching,
  selectGetProductFetched,
  // Create Product
  selectCreateProductInstance,
  selectCreateProductError,
  selectCreateProductCreating,
  selectCreateProductCreated,
} from './selectors';

export function withProductHoc(Component) {
  function withProduct(props) {
    return <Component {...props} />;
  }
  // Display Name for Easy Debugging
  withProduct.displayName = `withProduct(${getDisplayName(Component)})`;

  return withProduct;
}

const mapStateToProps = createStructuredSelector({
  // Get Modifier Groups
  getProductInstance: selectGetProductInstance,
  getProductFetching: selectGetProductError,
  getProductError: selectGetProductFetching,
  getProductFetched: selectGetProductFetched,
  // Delete Modifier Group
  createProductInstance: selectCreateProductInstance,
  createProductError: selectCreateProductError,
  createProductCreating: selectCreateProductCreating,
  createProductCreated: selectCreateProductCreated,
});

export function mapDispatchToProps(dispatch) {
  return {
    handleGetProduct: ({ storeUuid, productUuid }) => dispatch(getProduct({ storeUuid, productUuid })),
    resetGetProduct: () => dispatch(getProduct.reset()),
    handleCreateProduct: ({ storeUuid, product }) => dispatch(createProduct({ storeUuid, product })),
    resetCreateProduct: () => dispatch(createProduct.reset()),
  };
}

export const withProductState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withProductState, withProductHoc);
