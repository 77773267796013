import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectPermissionsProviderDomain = (state) => state.permissionsProvider || initialState;

// Get Permissions
const selectPermissions = createSelector(
  selectPermissionsProviderDomain,
  (permissionsState) => permissionsState.permissions.list,
);
const selectPermissionsError = createSelector(
  selectPermissionsProviderDomain,
  (permissionsState) => permissionsState.permissions.error,
);
const selectPermissionsIsFetching = createSelector(
  selectPermissionsProviderDomain,
  (permissionsState) => permissionsState.permissions.isFetching,
);
const selectPermissionsDidFetch = createSelector(
  selectPermissionsProviderDomain,
  (permissionsState) => permissionsState.permissions.didFetch,
);

export {
  selectPermissionsProviderDomain,
  // Get Permissions
  selectPermissions,
  selectPermissionsError,
  selectPermissionsIsFetching,
  selectPermissionsDidFetch,
};
