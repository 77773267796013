/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  createServiceArea,
  getServicesAreas,
  deleteServiceArea,
  getServiceAreaDetails,
  updateServiceArea,
  validateNameArea,
  serviceAreaChangeDefault,
  updateAreaPosition,
} from './actions';
import {
  selectServiceAreas,
  selectCreateServiceAreaState,
  selectServiceAreasState,
  selectDeleteServiceAreaState,
  selectServiceAreaDetails,
  selectServiceAreaDetailsState,
  selectUpdateServiceAreaState,
  selectValidateNameAreaState,
  selectServiceAreaChangeDefaultState,
} from './selectors';

export function withSettingsServiceAreasHoc(Component) {
  function withSettingsServiceAreas(props) {
    return <Component {...props} />;
  }

  withSettingsServiceAreas.displayName = `withSettingsServiceAreas(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withSettingsServiceAreas;
}

const mapStateToProps = createStructuredSelector({
  createServiceAreaState: selectCreateServiceAreaState,
  serviceAreas: selectServiceAreas,
  serviceAreasState: selectServiceAreasState,
  serviceAreaDetails: selectServiceAreaDetails,
  serviceAreaDetailsState: selectServiceAreaDetailsState,
  updateServiceAreaState: selectUpdateServiceAreaState,
  deleteServiceAreaState: selectDeleteServiceAreaState,
  validateNameAreaState: selectValidateNameAreaState,
  serviceAreaChangeDefaultState: selectServiceAreaChangeDefaultState,
});

function mapDispatchToProps(dispatch) {
  return {
    createServiceArea: (values) => dispatch(createServiceArea(values)),
    resetCreateServiceArea: () => dispatch(createServiceArea.reset()),
    loadServicesAreas: () => dispatch(getServicesAreas()),
    resetLoadServicesAreas: () => dispatch(getServicesAreas.reset()),
    loadServiceAreaDetails: (areaUuid) => dispatch(getServiceAreaDetails(areaUuid)),
    resetLoadServiceAreaDetails: () => dispatch(getServiceAreaDetails.reset()),
    updateServiceArea: (area) => dispatch(updateServiceArea(area)),
    resetUpdateServiceArea: () => dispatch(updateServiceArea.reset()),
    serviceAreaChangeDefault: (area) => dispatch(serviceAreaChangeDefault(area)),
    resetServiceAreaChangeDefault: () => dispatch(serviceAreaChangeDefault.reset()),
    deleteServiceArea: (areaUuid) => dispatch(deleteServiceArea(areaUuid)),
    resetDeleteServiceArea: () => dispatch(deleteServiceArea.reset()),
    validateName: (value) => dispatch(validateNameArea(value)),
    resetValidateName: () => dispatch(validateNameArea.reset()),
    sortLocalPosition: (sortedValues) => dispatch(getServicesAreas.sortPosition(sortedValues)),
    onUpdateOrder: (values) => dispatch(updateAreaPosition(values)),
  };
}

export const withSettingsServiceAreasState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withSettingsServiceAreasState, withSettingsServiceAreasHoc);
