import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { withListsOptions } from 'providers/ListsOptionsProvider';

import Box from '@material-ui/core/Box';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonOutlined from '@design-system/ButtonOutlined';

import TransferList from '@experimental-components/TransferList';
import HelperSkeleton from '@experimental-components/TransferList/HelperSkeleton';

import useSortingListsOptions from 'hooks/useSortingListsOptions';

import Dialog from './Dialog';
import { setProductsOptionsList } from './utils';
import useStyles from './styles';

function DialogAddProducts({
  open,
  onClose,
  onAction,
  title,
  description,
  optionsSelected,
  productsOptions,
  productsOptionsState,
  onPagination,
  productsOptionsPaginationValues,
  productsOptionsPaginationState,
  onSearch,
}) {
  const classes = useStyles();
  const { t } = useTranslation('categories');
  const [productsSelected, setProductsSelected] = useState([]);

  const { productsOptionsFilterState, productsOptionsFilterValues } = useSortingListsOptions();

  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && productsOptionsPaginationValues?.next) {
          onPagination();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return function cleanup() {
      observer.disconnect();
    };
  }, [onPagination, productsOptionsPaginationValues?.next, scrollPaginationRef]);

  function handleOnChangeSelectedProducts(products) {
    setProductsSelected(products);
  }

  function handleOnAcceptChanges() {
    onAction(productsSelected);
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <Dialog.Title>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.description}>{description}</Box>
      </Dialog.Title>
      <Dialog.Content className={classes.dialogContent}>
        {productsOptionsState.fetching && <HelperSkeleton />}
        {!productsOptionsState.fetching && (
          <TransferList
            isError={productsOptionsState.error || productsOptionsFilterState.error}
            isLoadingOptions={productsOptionsPaginationState.fetching}
            isLoadingOptionsByFilter={productsOptionsFilterState.fetching}
            isSearchFilter={productsOptionsFilterValues?.filterByText?.length > 0}
            labelOptionsAvailable={t('common:addProducts.labelProductsAvailable')}
            labelOptionsSelected={t('common:addProducts.labelProductsSelected')}
            onChange={handleOnChangeSelectedProducts}
            onSearch={onSearch}
            options={setProductsOptionsList(productsOptions)}
            optionsSelected={setProductsOptionsList(optionsSelected)}
            placeholderLabel={t('common:addProducts.placeHolderSearch')}
            scrollPaginationRef={scrollPaginationRef}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Box className={classes.actionsButton}>
          <ButtonOutlined disabled={productsOptionsState.fetching} fullWidth onClick={onClose}>
            {t('common:buttons.cancel')}
          </ButtonOutlined>
        </Box>

        <Box className={classes.actionsButton}>
          <ButtonPrimary disabled={productsOptionsState.fetching} fullWidth onClick={handleOnAcceptChanges}>
            {t('common:buttons.accept')}
          </ButtonPrimary>
        </Box>
      </Dialog.Actions>
    </Dialog>
  );
}

DialogAddProducts.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  productsOptions: PropTypes.array,
  optionsSelected: PropTypes.array,
  productsOptionsState: PropTypes.object,
  onPagination: PropTypes.func,
  productsOptionsPaginationValues: PropTypes.object,
  productsOptionsPaginationState: PropTypes.object,
  onSearch: PropTypes.func,
};

export default compose(withListsOptions)(DialogAddProducts);
