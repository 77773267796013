import produce from 'immer';

import { constructDataForModifiersGroup } from 'utils/menuPreview';

import { getMenuPreview, getMenuCategoryProducts, getMenuProductDetails, exportMenu } from './actions';

export const initialState = {
  getMenuPreview: {
    error: null,
    fetching: false,
    fetched: false,
    menu: {},
    menuCategories: {},
    menuProductsDetails: {},
  },

  getMenuCategoryProducts: {
    error: null,
    fetching: false,
    fetched: false,
  },

  getMenuProductDetails: {
    error: null,
    fetching: false,
    fetched: false,
  },

  exportMenu: {
    fetching: false,
    fetched: false,
    error: null,
    document: null,
  },
};

const menuMakerPreviewProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMenuPreview.REQUEST:
        draft.getMenuPreview.fetching = true;
        break;

      case getMenuPreview.SUCCESS:
        draft.getMenuPreview.fetching = false;
        draft.getMenuPreview.fetched = true;
        draft.getMenuPreview.menu = payload;
        draft.getMenuPreview.menuCategories = {};
        payload?.categoriesList?.forEach(function createCategoryMap(category) {
          if (category?.productCounter > 0) {
            draft.getMenuPreview.menuCategories[category?.uuid] = {
              ...category,
              products: [],
              isLoaded: false,
            };
          }
        });
        break;

      case getMenuPreview.FAILURE:
        draft.getMenuPreview.error = payload;
        draft.getMenuPreview.fetching = false;
        draft.getMenuPreview.fetched = false;
        break;

      case getMenuPreview.RESET:
        draft.getMenuPreview = { ...initialState.getMenuPreview };
        break;

      // load category products
      case getMenuCategoryProducts.REQUEST:
        draft.getMenuCategoryProducts.fetching = true;
        break;

      case getMenuCategoryProducts.SUCCESS:
        draft.getMenuCategoryProducts.fetching = false;
        draft.getMenuCategoryProducts.fetched = true;
        payload?.products.forEach((product) => {
          draft.getMenuPreview.menuProductsDetails[product?.uuid] = {
            isLoaded: false,
          };
        });
        draft.getMenuPreview.menuCategories[payload?.categoryUuid].products = payload?.products;
        draft.getMenuPreview.menuCategories[payload?.categoryUuid].isLoaded = true;
        break;

      case getMenuCategoryProducts.FAILURE:
        draft.getMenuCategoryProducts.error = payload;
        draft.getMenuCategoryProducts.fetching = false;
        draft.getMenuCategoryProducts.fetched = false;
        break;

      case getMenuCategoryProducts.RESET:
        draft.getMenuCategoryProducts = { ...initialState.getMenuCategoryProducts };
        break;

      // load products details
      case getMenuProductDetails.REQUEST:
        draft.getMenuProductDetails.fetching = true;
        break;

      case getMenuProductDetails.SUCCESS:
        draft.getMenuProductDetails.fetching = false;
        draft.getMenuProductDetails.fetched = true;
        draft.getMenuPreview.menuProductsDetails[payload?.productUuid] = {
          isLoaded: true,
          modifiers: constructDataForModifiersGroup(payload?.details),
        };
        break;

      case getMenuProductDetails.FAILURE:
        draft.getMenuProductDetails.error = payload;
        draft.getMenuProductDetails.fetching = false;
        draft.getMenuProductDetails.fetched = true;
        break;

      case getMenuProductDetails.RESET:
        draft.getMenuProductDetails = { ...initialState.getMenuProductDetails };
        break;

      case exportMenu.REQUEST:
        draft.exportMenu.fetching = true;
        draft.exportMenu.fetched = false;
        draft.exportMenu.error = null;
        draft.exportMenu.document = null;
        break;

      case exportMenu.SUCCESS:
        draft.exportMenu.fetching = false;
        draft.exportMenu.fetched = true;
        draft.exportMenu.error = null;
        draft.exportMenu.document = payload;
        break;

      case exportMenu.FAILURE:
        draft.exportMenu.fetching = false;
        draft.exportMenu.fetched = false;
        draft.exportMenu.error = payload;
        draft.exportMenu.document = null;
        break;

      case exportMenu.RESET:
        draft.exportMenu = { ...initialState.exportMenu };
        break;

      default:
        return draft;
    }
  });

export default menuMakerPreviewProviderReducer;
