import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectCategoryDetailProviderDomain = (state) => state.categoryDetailProvider || initialState;

const selectCategoryDetailStore = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.getCategoryDetail.category,
);

const selectCategoryDetailFetching = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.getCategoryDetail.fetching,
);

const selectCategoryDetailError = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.getCategoryDetail.error,
);

const selectCategoryDetailFetched = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.getCategoryDetail.fetched,
);

const selectEditCategoryStore = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.editCategory.category,
);

const selectEditCategoryFetching = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.editCategory.fetching,
);

const selectEditCategoryFetched = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.editCategory.fetched,
);

const selectEditCategoryError = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.editCategory.error,
);

const selectProductsSortCategory = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.getProductsSortCategory.products,
);

const selectProductsSortCategoryState = createSelector(
  selectCategoryDetailProviderDomain,
  ({ getProductsSortCategory }) => {
    return {
      fetching: getProductsSortCategory.fetching,
      fetched: getProductsSortCategory.fetched,
      error: getProductsSortCategory.error,
    };
  },
);

const selectUpdateProductsSortCategory = createSelector(
  selectCategoryDetailProviderDomain,
  (subState) => subState.updateProductsSortCategory.products,
);

const selectUpdateProductsSortCategoryState = createSelector(
  selectCategoryDetailProviderDomain,
  ({ updateProductsSortCategory }) => {
    return {
      fetching: updateProductsSortCategory.fetching,
      fetched: updateProductsSortCategory.fetched,
      error: updateProductsSortCategory.error,
    };
  },
);

export {
  selectCategoryDetailProviderDomain,
  selectCategoryDetailStore,
  selectCategoryDetailFetching,
  selectCategoryDetailError,
  selectCategoryDetailFetched,
  selectEditCategoryStore,
  selectEditCategoryFetching,
  selectEditCategoryFetched,
  selectEditCategoryError,
  selectProductsSortCategory,
  selectProductsSortCategoryState,
  selectUpdateProductsSortCategory,
  selectUpdateProductsSortCategoryState,
};
