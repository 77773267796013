import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsPaymentsProviderDomain = (state) => state.reportsPaymentsProvider || initialState;

const selectReportsPaymentsSummaries = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.getReportsPayments.summaries,
);

const selectReportsPaymentsHeaders = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.getReportsPayments.headers,
);

const selectReportsPaymentsList = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.getReportsPayments.payments,
);

const selectReportsPaymentsPagination = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.getReportsPayments.pagination,
);

const selectReportsPaymentsPaginationState = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.loadPaginationStatus,
);

const selectReportsPaymentsState = createSelector(
  selectReportsPaymentsProviderDomain,
  ({ getReportsPayments: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsPaymentsSortingState = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.sortingStatus,
);

const selectExportReportsPaymentsState = createSelector(
  selectReportsPaymentsProviderDomain,
  ({ exportReportsPayments: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectExportReportsPaymentsDocument = createSelector(
  selectReportsPaymentsProviderDomain,
  (subState) => subState.exportReportsPayments.document,
);

export {
  selectReportsPaymentsProviderDomain,
  selectReportsPaymentsSummaries,
  selectReportsPaymentsHeaders,
  selectReportsPaymentsList,
  selectReportsPaymentsPagination,
  selectReportsPaymentsPaginationState,
  selectReportsPaymentsState,
  selectReportsPaymentsSortingState,
  selectExportReportsPaymentsState,
  selectExportReportsPaymentsDocument,
};
