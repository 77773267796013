import clsx from 'clsx';

import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectMui from '@material-ui/core/Select';

import InputTextFiled from '@design-system/InputTextFiled';
import IconChevronDown from '@experimental-components/IconsComponents/ChevronDown';

const SelectRoot = withStyles((theme) => ({
  select: {
    borderRadius: theme.shape.borderRadius,
    padding: '1.1rem 1rem',
  },

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(SelectMui);

const InputLabelRoot = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.common.black,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey[50],
    fontSize: 16,
  },
}))(FormHelperText);

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
  },

  menuPaper: {
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #F2F2F4',
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}));

function Select({
  id,
  error,
  fullWidth,
  label,
  helperText,
  errorMsg,
  disabled,
  required,
  variant,
  hideLabel,
  hideLabelHelperTex,
  ...others
}) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => <IconChevronDown className={clsx(props.className, classes.iconRoot)} color="#000" />;

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required}>
      <InputLabelRoot htmlFor={id} shrink>
        {label}
      </InputLabelRoot>

      <SelectRoot
        disabled={disabled}
        error={error}
        IconComponent={iconComponent}
        id={id}
        input={<InputTextFiled hideLabel={hideLabel} hideLabelHelperTex={hideLabelHelperTex} />}
        MenuProps={menuProps}
        required={required}
        variant={variant}
        {...others}
      />
      {!hideLabelHelperTex && <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>}
    </FormControl>
  );
}

Select.propTypes = {
  ...InputBase.propTypes,
};

export default Select;
