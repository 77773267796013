import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionDetailsMui from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  content: {
    margin: 0,
  },
}));

function AccordionDetails({ children, css, customClasses = {}, ...others }) {
  const classes = useStyles();

  return (
    <AccordionDetailsMui classes={{ root: classes.root, content: classes.content, ...customClasses }} {...others}>
      <Box css={{ ...css }} pl={5} pr={5} pt={6}>
        {children}
      </Box>
    </AccordionDetailsMui>
  );
}

AccordionDetails.propTypes = {
  ...AccordionDetailsMui.propTypes,
};

export default AccordionDetails;
