/**
 *
 * ParrotSoftware
 * SettingsServiceAreasProvider constants
 *
 */
export const GET_SERVICES_AREAS = 'providers/SettingsServiceAreasProvider/GET_SERVICES_AREAS';
export const VALIDATE_NAME_AREA = 'providers/SettingsServiceAreasProvider/VALIDATE_NAME_AREA';
export const CREATE_SERVICE_AREA = 'providers/SettingsServiceAreasProvider/CREATE_SERVICE_AREA';
export const DELETE_SERVICE_AREA = 'providers/SettingsServiceAreasProvider/DELETE_SERVICE_AREA';
export const UPDATE_SERVICE_AREA = 'providers/SettingsServiceAreasProvider/UPDATE_SERVICE_AREA';
export const SERVICE_AREA_CHANGE_DEFAULT = 'providers/SettingsServiceAreasProvider/SERVICE_AREA_CHANGE_DEFAULT';
export const GET_SERVICE_AREA_DETAILS = 'providers/SettingsServiceAreasProvider/GET_SERVICE_AREA_DETAILS';
export const UPDATE_AREA_POSITION = 'providers/SettingsServiceAreasProvider/UPDATE_AREA_POSITION';
