import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectGetProductsOptionsFilterState,
  selectGetProductsAutocompleteFilterState,
} from 'providers/ListsOptionsProvider/selectors';
import { getProductsOptionsByFilter, getProductsAutocompleteByFilter } from 'providers/ListsOptionsProvider/actions';

export function useSortingListsOptions() {
  const dispatch = useDispatch();
  const { fetching, fetched, error, filterByText, orderBy, soldAlone, lastFilterOptions } = useSelector(
    selectGetProductsOptionsFilterState,
  );
  const productsAutocompleteFilterState = useSelector(selectGetProductsAutocompleteFilterState);

  function handleSetProductsOptionsFilterByText(filtersArgs) {
    const filters = filtersArgs;

    if (!filters?.filterByText || filters?.filterByText === null || filters?.filterByText === undefined) {
      delete filters?.filterByText;
    }

    dispatch(getProductsOptionsByFilter(filters));

    dispatch(getProductsOptionsByFilter.filterText(filters));
  }

  function handleResetProductsOptionsFilter() {
    dispatch(getProductsOptionsByFilter.resetFilterText());
  }

  function handleSetProductsAutocompleteFilterByText(filtersArgs) {
    const filters = filtersArgs;

    dispatch(getProductsAutocompleteByFilter(filters));

    dispatch(getProductsAutocompleteByFilter.filterText(filters));
  }

  function handleResetProductsAutocompleteFilter() {
    dispatch(getProductsAutocompleteByFilter.resetFilterText());
  }

  return {
    setProductsOptionsFilterByText: useCallback(handleSetProductsOptionsFilterByText, [dispatch]),
    resetProductsOptionsFilter: useCallback(handleResetProductsOptionsFilter, [dispatch]),
    productsOptionsFilterState: {
      fetching,
      fetched,
      error,
    },
    productsOptionsFilterValues: {
      [filterByText.length && 'filterByText']: filterByText,
      orderBy,
      soldAlone,
    },
    productsOptionsFilterList: lastFilterOptions,
    getProductsAutocompleteFilter: {
      setProductsAutocompleteFilterByText: useCallback(handleSetProductsAutocompleteFilterByText, [dispatch]),
      resetProductsAutocompleteFilter: useCallback(handleResetProductsAutocompleteFilter, [dispatch]),
      productsAutocompleteFilterState: {
        fetching: productsAutocompleteFilterState.fetching,
        fetched: productsAutocompleteFilterState.fetched,
        error: productsAutocompleteFilterState.error,
      },
      productsAutocompleteFilterValues: {
        [productsAutocompleteFilterState.filterByText.length &&
        'filterByText']: productsAutocompleteFilterState.filterByTex,
        orderBy: productsAutocompleteFilterState.orderBy,
      },
      productsAutocompleteFilterList: productsAutocompleteFilterState.lastFilterOptions,
    },
  };
}

export default useSortingListsOptions;
