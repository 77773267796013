import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectModifierGroupsProviderDomain = (state) => state.modifierGroupsProvider || initialState;

// Get Modifier Groups
const selectModifierGroups = createSelector(
  selectModifierGroupsProviderDomain,
  ({ getModifierGroups }) => getModifierGroups.list,
);

const selectModifierGroupsError = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.getModifierGroups.error,
);

const selectModifierGroupsFetching = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.getModifierGroups.fetching,
);

const selectModifierGroupsFetched = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.getModifierGroups.fetched,
);

const selectModifierGroupsPagination = createSelector(
  selectModifierGroupsProviderDomain,
  (subState) => subState.getModifierGroups.pagination,
);

const selectPaginationModifierGroupState = createSelector(
  selectModifierGroupsProviderDomain,
  (subState) => subState.paginationModifierGroup,
);

// crete Modifier Group
const selectCreateModifierGroup = createSelector(
  selectModifierGroupsProviderDomain,
  (subState) => subState.createModifierGroup.modifierGroup,
);

const selectCreateModifierGroupError = createSelector(
  selectModifierGroupsProviderDomain,
  (subState) => subState.createModifierGroup.error,
);

const selectCreateModifierGroupFetching = createSelector(
  selectModifierGroupsProviderDomain,
  (subState) => subState.createModifierGroup.fetching,
);

const selectCreateModifierGroupFetched = createSelector(
  selectModifierGroupsProviderDomain,
  (subState) => subState.createModifierGroup.fetched,
);

// Delete Modifier Group
const selectDeleteModifierGroupError = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.deleteModifierGroup.error,
);

const selectDeleteModifierGroupFetching = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.deleteModifierGroup.fetching,
);

const selectDeleteModifierGroupFetched = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.deleteModifierGroup.fetched,
);

// Duplicate Modifier Group
const selectDuplicateModifierGroupError = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.duplicateModifierGroup.error,
);

const selectDuplicateModifierGroupFetching = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.duplicateModifierGroup.fetching,
);

const selectDuplicateModifierGroupFetched = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.duplicateModifierGroup.fetched,
);
const selectDuplicateModifierGroupNewInstance = createSelector(
  selectModifierGroupsProviderDomain,
  (modifierGroupsState) => modifierGroupsState.duplicateModifierGroup.newInstance,
);

export {
  selectModifierGroupsProviderDomain,
  // Get Modifier Groups
  selectModifierGroups,
  selectModifierGroupsError,
  selectModifierGroupsFetching,
  selectModifierGroupsFetched,
  selectModifierGroupsPagination,
  selectPaginationModifierGroupState,
  // create modifier group
  selectCreateModifierGroup,
  selectCreateModifierGroupError,
  selectCreateModifierGroupFetching,
  selectCreateModifierGroupFetched,
  // Delete Modifier Group
  selectDeleteModifierGroupError,
  selectDeleteModifierGroupFetching,
  selectDeleteModifierGroupFetched,
  // Duplicate Modifier Group
  selectDuplicateModifierGroupFetching,
  selectDuplicateModifierGroupError,
  selectDuplicateModifierGroupFetched,
  selectDuplicateModifierGroupNewInstance,
};
