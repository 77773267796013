import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import lowerCase from 'lodash/lowerCase';

import { getFilteredMenuProductsByText } from 'utils/products';

import { initialState } from './reducer';

const selectMenuProductsProviderDomain = (state) => state.menuProductsProvider || initialState;

const selectProducts = createSelector(selectMenuProductsProviderDomain, ({ getProducts }) => {
  let productsList = [...getProducts.products];

  const { filterByText } = getProducts.sorting;

  if (filterByText && filterByText.length) {
    productsList = getFilteredMenuProductsByText(getProducts.products, getProducts.sorting);
  }

  const productListOrdered = orderBy(
    productsList.map((item) => ({ ...item, name: lowerCase(item.name) })),
    [getProducts.sorting.property],
    [getProducts.sorting.direction],
  );

  return productListOrdered.map((item) => ({
    ...item,
    name: productsList.find((product) => product.uuid === item.uuid).name,
  }));
});

const selectProductsSoldAlonePagination = createSelector(
  selectMenuProductsProviderDomain,
  ({ productsSoldAlonePagination, getProducts }) => {
    const pagination = { ...productsSoldAlonePagination, count: getProducts.pagination.count };

    return pagination;
  },
);

const selectMenusProductsPagination = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.getProducts.pagination,
);

const selectMenusProductsSorting = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.getProducts.sorting,
);

const selectMenuProductsFetching = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.getProducts.fetching,
);

const selectMenuProductsFetched = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.getProducts.fetched,
);

const selectMenuProductsError = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.getProducts.error,
);

const selectChangeProductPriceFetching = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.changeProductPrice.fetching,
);

const selectChangeProductPriceFetched = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.changeProductPrice.fetched,
);

const selectChangeProductPriceError = createSelector(
  selectMenuProductsProviderDomain,
  (subState) => subState.changeProductPrice.error,
);

export {
  selectMenuProductsProviderDomain,
  selectProducts,
  selectMenusProductsPagination,
  selectProductsSoldAlonePagination,
  selectMenusProductsSorting,
  selectMenuProductsFetching,
  selectMenuProductsFetched,
  selectMenuProductsError,
  selectChangeProductPriceFetching,
  selectChangeProductPriceFetched,
  selectChangeProductPriceError,
};
