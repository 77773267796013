/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ButtonPrimary from '@design-system/ButtonPrimary';
import InputTextFiled from '@design-system/InputTextFiled';

import ProductDrawer from 'containers/ProductDrawer';

import ViewPanelLayout from 'components/ViewPanelLayout';
import PopupAlert from 'components/PopupAlert';
import ModifierOptions from 'components/ModifierOptions';

import useViewPanel from 'hooks/useViewPanel';

import { mapNewProductToOption, mapProductsToOptions, mapOptionsToSave } from 'utils/mapping';
import { getUuidProducts } from 'utils/listsOptions';

import { EXACT_REQUIRED_TYPE, RANGE_REQUIRED_TYPE, SELECTION_RULES } from './constants';

import ModifierGroupRules from './ModifierGroupRules';
import AddProducts from './AddProducts';
import useStyles from './styles';

function ModifierGroupForm({ action, t, modifierGroup, loading, products, saveModifierGroup }) {
  const classes = useStyles();
  const { setOpen: setOpenViewPanel } = useViewPanel();
  // Utilities
  const history = useHistory();
  const {
    handleSubmit,
    errors,
    register,
    control,
    formState,
    setError,
    clearErrors,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: modifierGroup?.name,
      shouldForceSelection: modifierGroup?.selectionType === 'FORCE_PRODUCT_SELECTION',
    },
  });
  const { isValid } = formState;

  const { fields: conditionalRules } = useFieldArray({
    control,
    name: 'conditionalRules',
  });
  const isExistingModifierGroup = !!modifierGroup?.uuid;

  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [{ selectedOptions, conditionalGroup }, setOptionsContext] = useState({
    selectedOptions: [],
    conditionalGroup: null,
  });
  const [successMessage, setSuccessMessage] = useState({ open: false, message: null });
  const [productsAssigned, setProductsAssigned] = useState([]);

  const setSelectedOptions = useCallback(
    (newSelectedOptions) => {
      setOptionsContext({ selectedOptions: newSelectedOptions, conditionalGroup });
    },
    [conditionalGroup],
  );
  const initConditionalRules = (newConditionalGroup, defaultRules = []) => {
    if (newConditionalGroup) {
      const hasEnoughDefaults = defaultRules.length === newConditionalGroup.options.length;
      const newConditionalRules = newConditionalGroup.options.map((option, index) => {
        return {
          uuid: option.uuid,
          name: option.name || option.product?.name,
          min: hasEnoughDefaults ? defaultRules[index].min : '',
          max: hasEnoughDefaults ? defaultRules[index].max : '',
          exact: hasEnoughDefaults ? defaultRules[index].max : '',
          repeated: hasEnoughDefaults ? defaultRules[index].repeated : '',
          selectionType: SELECTION_RULES.NOT_RULE_DEFINED,
          requiredType: hasEnoughDefaults ? defaultRules[index].requiredType : EXACT_REQUIRED_TYPE,
        };
      });
      setValue('conditionalRules', newConditionalRules, { shouldDirty: true });
    } else {
      setValue('conditionalRules', [], { shouldDirty: true });
    }
  };
  const updateOptionsContext = (newConditionalGroup) => {
    const newSelectedOptions = selectedOptions.map((selectedOption) => {
      processConditionalGroup(selectedOption, newConditionalGroup);
      return selectedOption;
    });
    initConditionalRules(newConditionalGroup);
    setOptionsContext({ selectedOptions: newSelectedOptions, conditionalGroup: newConditionalGroup });
  };

  useEffect(() => {
    if (!isExistingModifierGroup) {
      reset();
    }
  }, [isExistingModifierGroup, reset]);

  useEffect(() => {
    if (modifierGroup?.uuid) {
      const newSelectedOptions = modifierGroup.options.map((option) => {
        processMenuOverrides(option);
        if (modifierGroup.conditionalGroup) processConditionalGroup(option, modifierGroup.conditionalGroup);
        return option;
      });
      const hasValidConditionalRules =
        modifierGroup.conditionalGroup &&
        modifierGroup.conditionalGroup.options &&
        modifierGroup.conditionalRules &&
        modifierGroup.conditionalGroup.options.length === modifierGroup.conditionalRules.length;
      if (hasValidConditionalRules) {
        const newConditionalRules = modifierGroup.conditionalRules.map((rule, index) => {
          const ruleName =
            modifierGroup.conditionalGroup.options[index].name ||
            modifierGroup.conditionalGroup.options[index]?.product.name;
          return {
            uuid: rule.conditionalOption,
            name: ruleName,
            min: rule.min,
            max: rule.max,
            exact: rule.max,
            repeated: rule.repeated,
            selectionType: rule.selectionType,
            requiredType: rule.requiredType,
          };
        });
        setValue('conditionalRules', newConditionalRules, { shouldValidate: true });
      }
      setOptionsContext({ selectedOptions: newSelectedOptions, conditionalGroup: modifierGroup.conditionalGroup });
    }
  }, [modifierGroup, setValue]);

  function handleGoBackButtonClick() {
    history.push('/menus/modifier-groups');
    setOpenViewPanel(false);
  }

  useEffect(() => {
    register({ name: 'productsField' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (modifierGroup?.appliedProducts) {
      setValue('productsField', getUuidProducts(modifierGroup?.appliedProducts));
      setProductsAssigned(modifierGroup?.appliedProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, modifierGroup?.appliedProducts]);

  function processConditionalGroup(option, newConditionalGroup) {
    const conditionalOptions = newConditionalGroup?.options || [];
    const conditionals = [];
    conditionalOptions.forEach((conditionalOption) => {
      let matchOption = option.conditionals.filter((optionConditionalOption) => {
        return conditionalOption.uuid === optionConditionalOption.conditionalOption;
      })[0];
      if (!matchOption) {
        const conditionalOptionName = conditionalOption.name || conditionalOption.product.name;
        const optionName = option.name || option.product.name;
        matchOption = {
          product: null,
          parentOption: option.uuid,
          conditionalOption: conditionalOption.uuid,
          menuOverrides: [],
          name: `${optionName} - ${conditionalOptionName}`,
          description: `${option.description} - ${conditionalOption.description}`,
          additionalPrice: option.additionalPrice,
          availability: option.availability,
        };
      }
      const optionMenuOverrides = [];
      option.menuOverrides.forEach((menuOverride) => {
        let matchMenuOverride = matchOption.menuOverrides.filter((optionMenuOverride) => {
          return menuOverride.menuUuid === optionMenuOverride.menuUuid;
        })[0];
        if (!matchMenuOverride) {
          matchMenuOverride = {
            menu: menuOverride.menu,
            menuUuid: menuOverride.menuUuid,
            productPrice: matchOption.product?.price || menuOverride.productPrice || 0,
            optionPrice: matchOption.additionalPrice || menuOverride.optionPrice || 0,
          };
        }
        optionMenuOverrides.push(matchMenuOverride);
      });
      matchOption.menuOverrides = optionMenuOverrides;
      conditionals.push(matchOption);
    });
    option.conditionals = conditionals;
  }
  function processMenuOverrides(option) {
    option.expanded = false;
    const menuOverrides = option.menuOverrides || [];
    option.menuOverrides = menuOverrides.map((menuOverride) => {
      menuOverride.optionPrice = menuOverride.optionPrice || menuOverride.productPrice || 0;
      return menuOverride;
    });
  }

  function handleModifierGroupSave(values) {
    const selectionType = values?.shouldForceSelection
      ? SELECTION_RULES.FORCE_PRODUCT_SELECTION
      : SELECTION_RULES.NOT_RULE_DEFINED;

    const updatedModifierGroup = {
      ...modifierGroup,
      name: values.name,
      selectionType,
      min: values.min,
      max: values.max,
      repeated: values.repeated,
      [values?.requiredType && 'requiredType']: values?.requiredType,
      products: values?.productsField ?? [],
    };

    updatedModifierGroup.options = mapOptionsToSave(selectedOptions);

    if (conditionalGroup) {
      updatedModifierGroup.conditionalGroup = conditionalGroup.uuid;

      updatedModifierGroup.conditionalRules = values.conditionalRules.map((rule, ruleIndex) => {
        const maxValue = rule.requiredType === RANGE_REQUIRED_TYPE ? rule.max : rule.exact;
        return {
          conditionalOption: conditionalRules[ruleIndex].uuid,
          min: rule.min,
          max: maxValue,
          repeated: rule.repeated,
          requiredType: rule.requiredType,
          selectionType,
        };
      });
    } else {
      updatedModifierGroup.conditionalGroup = null;
      updatedModifierGroup.conditionalRules = [];
    }

    saveModifierGroup(updatedModifierGroup);
  }

  const handleOnSelectProduct = (newOptions) => {
    const currentConditionalGroup = conditionalGroup || modifierGroup?.conditionalGroup;
    const newSelectedOptions = newOptions.map((option) => {
      processMenuOverrides(option);
      if (currentConditionalGroup) processConditionalGroup(option, currentConditionalGroup);
      return option;
    });
    setOptionsContext({ selectedOptions: newSelectedOptions, conditionalGroup });
  };

  const handleOpenProductDrawer = () => {
    setOpenNewProduct(true);
  };

  const handleCloseProductDrawer = () => {
    setOpenNewProduct(false);
  };

  const handleAfterSave = (product) => {
    const newSelectedOptions = selectedOptions.concat(mapNewProductToOption(product));
    setSelectedOptions(newSelectedOptions);
    setSuccessMessage({
      message: t('menuMaker:modifierGroupForm.labels.newProductAdded'),
      open: true,
    });
  };

  const handleExpandOption = (option, index) => () => {
    if (modifierGroup) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[index].expanded = !selectedOptions[index].expanded;
      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleEditOptionPrice = (option, index) => (event) => {
    if (modifierGroup) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[index].additionalPrice = event.currentTarget.value;
      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleEditOptionMenuOverridePrice = (option, optionIndex, menuOverride, menuOverrideIndex) => (event) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[optionIndex].menuOverrides[menuOverrideIndex].optionPrice = event.currentTarget.value;
    setSelectedOptions(newSelectedOptions);
  };

  const handleEditConditionalOptionPrice = (optionIndex, conditionalIndex) => (event) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[optionIndex].conditionals[conditionalIndex].additionalPrice = event.currentTarget.value;
    setSelectedOptions(newSelectedOptions);
  };

  const handleEditConditionalOptionMenuOverridePrice = (optionIndex, conditionalIndex, menuOverrideIndex) => (
    event,
  ) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[optionIndex].conditionals[conditionalIndex].menuOverrides[menuOverrideIndex].optionPrice =
      event.currentTarget.value;
    setSelectedOptions(newSelectedOptions);
  };

  const handleRemoveFinalGroup = (optionIndex) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[optionIndex].finalGroup = null;
    setSelectedOptions(newSelectedOptions);
  };

  function handleOnChangeAssignedProducts(valuesAssigned) {
    setValue('productsField', getUuidProducts(valuesAssigned));
    setProductsAssigned(valuesAssigned);
  }

  return (
    <ViewPanelLayout
      actionComponent={
        <ButtonPrimary disabled={loading || !isValid} loading={loading} onClick={handleSubmit(handleModifierGroupSave)}>
          {t('common:buttons.save')}
        </ButtonPrimary>
      }
      disabledGoBack={loading}
      labelGoBack={t('common:buttons.toReturn')}
      onGoBack={handleGoBackButtonClick}
      px={0}
    >
      <Box className={classes.root}>
        <Grid container spacing={5}>
          <Grid item>
            <Typography className={classes.title}>{t(`menuMaker:sections.${action}ModifierGroup`)}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Grid item xs={6}>
                <InputTextFiled
                  autoFocus
                  defaultValue={modifierGroup?.name || ''}
                  error={Boolean(errors.name)}
                  fullWidth
                  helperText={errors?.name?.message}
                  inputProps={{ maxLength: 50 }}
                  inputRef={register({
                    required: t('menuMaker:modifierGroupForm.name.requiredError'),
                  })}
                  label={t('menuMaker:modifierGroupForm.name.placeholder')}
                  name="name"
                />
              </Grid>

              <Box mt={6}>
                <Typography className={classes.subtitle} gutterBottom variant="h6">
                  {t('menuMaker:modifierGroupForm.labels.optionsToInclude')}
                </Typography>
                <ModifierOptions
                  conditionalGroup={conditionalGroup}
                  currentModifierGroup={modifierGroup}
                  handleAddOption={handleOpenProductDrawer}
                  handleEditConditionalOptionMenuOverridePrice={handleEditConditionalOptionMenuOverridePrice}
                  handleEditConditionalOptionPrice={handleEditConditionalOptionPrice}
                  handleEditOptionMenuOverridePrice={handleEditOptionMenuOverridePrice}
                  handleEditOptionPrice={handleEditOptionPrice}
                  handleExpandOption={handleExpandOption}
                  handleRemoveFinalGroup={handleRemoveFinalGroup}
                  onChange={handleOnSelectProduct}
                  options={mapProductsToOptions(products)}
                  selectedOptions={selectedOptions}
                  updateOptionsContext={updateOptionsContext}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ModifierGroupRules
              clearErrors={clearErrors}
              conditionalGroup={conditionalGroup}
              conditionalRules={conditionalRules}
              control={control}
              controller={Controller}
              errors={errors}
              getValues={getValues}
              modifierGroup={modifierGroup}
              register={register}
              setError={setError}
              setValue={setValue}
              watch={watch}
            />
          </Grid>
        </Grid>
      </Box>
      <AddProducts onChangeProductList={handleOnChangeAssignedProducts} productList={productsAssigned} />
      <PopupAlert message={successMessage.message} severity="success" shouldDisplay={successMessage.open} />
      <ProductDrawer handleAfterSave={handleAfterSave} handleOnClose={handleCloseProductDrawer} open={openNewProduct} />
    </ViewPanelLayout>
  );
}

ModifierGroupForm.propTypes = {
  action: PropTypes.string,
  products: PropTypes.array,
  t: PropTypes.func,
  modifierGroup: PropTypes.object,
  saveModifierGroup: PropTypes.func,
  loading: PropTypes.bool,
};

export default compose(withTranslation('menuMaker'), withTranslation('modifierGroups'), memo)(ModifierGroupForm);
