import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsCancellationsProviderDomain = (state) => state.reportsCancellationsProvider || initialState;

const selectReportsCancellationsResume = createSelector(
  selectReportsCancellationsProviderDomain,
  (subState) => subState.getReportsCancellationsResume.resume,
);

const selectReportsCancellationsResumeState = createSelector(
  selectReportsCancellationsProviderDomain,
  ({ getReportsCancellationsResume: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsCancellationsDetails = createSelector(
  selectReportsCancellationsProviderDomain,
  (subState) => subState.getReportsCancellationsDetails.cancellations,
);

const selectReportsCancellationsDetailsState = createSelector(
  selectReportsCancellationsProviderDomain,
  ({ getReportsCancellationsDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsCancellationsDetailsPagination = createSelector(
  selectReportsCancellationsProviderDomain,
  (subState) => subState.getReportsCancellationsDetails.pagination,
);

const selectReportsCancellationsDetailsPaginationState = createSelector(
  selectReportsCancellationsProviderDomain,
  (subState) => subState.loadPaginationStatus,
);

const selectReportsCancellationsDetailsSortingState = createSelector(
  selectReportsCancellationsProviderDomain,
  (subState) => subState.sortingStatus,
);

const selectExportReportsCancellationsState = createSelector(
  selectReportsCancellationsProviderDomain,
  ({ exportReportsCancellations: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectExportReportsCancellationsDocument = createSelector(
  selectReportsCancellationsProviderDomain,
  (subState) => subState.exportReportsCancellations.document,
);

export {
  selectReportsCancellationsProviderDomain,
  selectReportsCancellationsResume,
  selectReportsCancellationsResumeState,
  selectReportsCancellationsDetails,
  selectReportsCancellationsDetailsState,
  selectReportsCancellationsDetailsPagination,
  selectReportsCancellationsDetailsPaginationState,
  selectReportsCancellationsDetailsSortingState,
  selectExportReportsCancellationsState,
  selectExportReportsCancellationsDocument,
};
