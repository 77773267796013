import produce from 'immer';

import { DEFAULT_SIZE_PAGE, DEFAULT_PAGE } from 'utils/products';

import { getMenusProducts, changeMenuProductPrice } from './actions';

export const initialState = {
  getProducts: {
    error: null,
    fetching: false,
    fetched: false,
    products: [],
    pagination: {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_SIZE_PAGE,
      count: 0,
    },
    sorting: {
      property: 'name',
      filterByText: '',
      direction: 'asc',
    },
  },

  productsSoldAlonePagination: {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_SIZE_PAGE,
    count: 0,
  },

  sorting: {
    property: 'name',
    filterByText: '',
    direction: 'asc',
  },

  changeProductPrice: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const menuProductsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMenusProducts.REQUEST:
        draft.getProducts = { ...initialState.getProducts, fetching: true };
        draft.productsSoldAlonePagination = { ...initialState.productsSoldAlonePagination };
        break;

      case getMenusProducts.SUCCESS:
        draft.getProducts.fetching = false;
        draft.getProducts.fetched = true;
        draft.getProducts.products = payload.data;
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...payload.pagination,
        };
        break;

      case getMenusProducts.FAILURE:
        draft.getProducts.error = payload;
        draft.getProducts.fetching = false;
        draft.getProducts.fetched = false;
        break;

      case getMenusProducts.SORTING:
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...initialState.getProducts.pagination,
        };
        draft.productsSoldAlonePagination = { ...initialState.productsSoldAlonePagination };

        draft.getProducts.sorting = {
          ...draft.getProducts.sorting,
          ...payload,
        };
        break;

      case getMenusProducts.RESET_ALL_SORTING:
        draft.getProducts.sorting = {
          ...initialState.getProducts.sorting,
        };
        break;

      case getMenusProducts.PAGINATION:
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...payload,
        };
        break;

      case getMenusProducts.PAGINATION_SOLD_ALONE:
        draft.productsSoldAlonePagination = {
          ...draft.productsSoldAlonePagination,
          ...payload,
        };
        break;

      case getMenusProducts.RESET_PAGINATION:
        draft.getProducts.pagination = { ...initialState.getProducts.pagination };
        break;

      case getMenusProducts.RESET_ALL_PAGINATION:
        draft.getProducts.pagination = { ...initialState.getProducts.pagination };
        draft.productsSoldAlonePagination = { ...initialState.productsSoldAlonePagination };
        break;

      case getMenusProducts.RESET:
        draft.getProducts = { ...initialState.getProducts };
        break;

      case changeMenuProductPrice.REQUEST:
        draft.changeProductPrice.fetching = true;
        draft.changeProductPrice.error = false;
        draft.changeProductPrice.fetched = false;
        break;

      case changeMenuProductPrice.SUCCESS:
        draft.changeProductPrice.fetching = false;
        draft.changeProductPrice.fetched = true;
        break;

      case changeMenuProductPrice.FAILURE:
        draft.changeProductPrice.error = payload;
        draft.changeProductPrice.fetching = false;
        draft.changeProductPrice.fetched = false;
        break;

      case changeMenuProductPrice.RESET:
        draft.changeProductPrice = { ...initialState.changeProductPrice };
        break;

      default:
        return draft;
    }
  });

export default menuProductsProviderReducer;
