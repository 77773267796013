import { Children } from 'react';
import PropTypes from 'prop-types';

import PaymentsGatewayProvider from 'providers/PaymentsGatewayProvider';

function PaymentsRootProvider({ children }) {
  return <PaymentsGatewayProvider>{Children.only(children)}</PaymentsGatewayProvider>;
}

PaymentsRootProvider.propTypes = {
  children: PropTypes.element,
};

export default PaymentsRootProvider;
