import { Children } from 'react';
import PropTypes from 'prop-types';

import InvoicesGlobalProvider from 'providers/InvoicesGlobalProvider';
import InvoiceCatalogProvider from 'providers/InvoiceCatalogProvider';
import InvoicesGeneratedProvider from 'providers/InvoicesGeneratedProvider';

function InvoicingRootProvider({ children }) {
  return (
    <InvoicesGeneratedProvider>
      <InvoicesGlobalProvider>
        <InvoiceCatalogProvider>{Children.only(children)}</InvoiceCatalogProvider>
      </InvoicesGlobalProvider>
    </InvoicesGeneratedProvider>
  );
}

InvoicingRootProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InvoicingRootProvider;
