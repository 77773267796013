/* eslint-disable no-unused-vars */
import produce from 'immer';

import { getModifierGroup, editModifierGroup } from './actions';

export const initialState = {
  getModifierGroup: {
    error: null,
    fetching: false,
    fetched: false,
    modifierGroup: {},
  },
  editModifierGroup: {
    error: null,
    fetching: false,
    fetched: false,
    modifierGroup: {},
  },
};

const modifierGroupReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      // Get Modifier Group
      case getModifierGroup.TRIGGER:
        draft.getModifierGroup.fetching = true;
        draft.getModifierGroup.fetched = false;
        draft.getModifierGroup.error = null;
        break;

      case getModifierGroup.SUCCESS:
        draft.getModifierGroup.fetched = true;
        draft.getModifierGroup.fetching = false;
        draft.getModifierGroup.modifierGroup = payload;
        break;

      case getModifierGroup.FAILURE:
        draft.getModifierGroup.fetching = false;
        draft.getModifierGroup.fetched = false;
        draft.getModifierGroup.error = payload.error;
        break;

      case getModifierGroup.RESET:
        draft.getModifierGroup = { ...initialState.getModifierGroup };
        break;
      // edit Modifier Group
      case editModifierGroup.REQUEST:
        draft.editModifierGroup = { ...initialState.editModifierGroup, fetching: true };
        break;

      case editModifierGroup.SUCCESS:
        draft.editModifierGroup.fetching = false;
        draft.editModifierGroup.fetched = true;
        draft.editModifierGroup.modifierGroup = payload;
        break;

      case editModifierGroup.FAILURE:
        draft.editModifierGroup.error = payload.error;
        draft.editModifierGroup.fetching = false;
        draft.editModifierGroup.fetched = false;
        break;

      case editModifierGroup.RESET:
        draft.editModifierGroup = { ...initialState.editModifierGroup };
        break;
      default:
        return draft;
    }
  });

export default modifierGroupReducer;
