import { Children } from 'react';
import PropTypes from 'prop-types';

import InventoriesAdminProvider from 'providers/InventoriesAdminProvider';
import InventoriesSupplies from 'providers/InventoriesSuppliesProvider';

import ComposeProviders from 'components/ComposeProviders';

function InventoriesRootsProvider({ children }) {
  return (
    <ComposeProviders providers={[<InventoriesAdminProvider />, <InventoriesSupplies />]}>
      {Children.only(children)}
    </ComposeProviders>
  );
}

InventoriesRootsProvider.propTypes = {
  children: PropTypes.element,
};

export default InventoriesRootsProvider;
