import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import CheckboxMui from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ReactComponent as CheckboxCheckedIcon } from './icons/CheckboxCheckedIcon.svg';
import { ReactComponent as CheckboxCheckedDisabledIcon } from './icons/CheckboxCheckedDisabledIcon.svg';
import { ReactComponent as CheckboxIcon } from './icons/CheckboxIcon.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from './icons/CheckboxIndeterminateIcon.svg';

const CheckboxBase = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(CheckboxMui);

const FormControlLabelBase = withStyles((theme) => ({
  label: {
    '&$disabled': {
      color: theme.palette.darkGrey[15],
    },
  },

  disabled: {
    color: theme.palette.darkGrey[15],
  },
}))(FormControlLabel);

function Checkbox({ name, defaultChecked, indeterminate, isCheckedDisabled, ...others }) {
  return (
    <FormControlLabelBase
      control={
        <CheckboxBase
          checkedIcon={isCheckedDisabled ? <CheckboxCheckedDisabledIcon /> : <CheckboxCheckedIcon />}
          defaultChecked={defaultChecked}
          disableRipple
          icon={<CheckboxIcon />}
          indeterminate={indeterminate}
          indeterminateIcon={<CheckboxIndeterminateIcon />}
        />
      }
      name={name}
      {...others}
    />
  );
}

Checkbox.propTypes = {
  ...CheckboxMui.propTypes,
};

Checkbox.propTypes = {
  isCheckedDisabled: PropTypes.bool,
};

export default Checkbox;
