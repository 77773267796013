import sortBy from 'lodash/sortBy';

export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

const ACTION_TYPE_DELETE = 'DELETE';
const ACTION_TYPE_DUPLICATE = 'DUPLICATE';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

export const actionTypes = {
  DELETE: ACTION_TYPE_DELETE,
  DUPLICATE: ACTION_TYPE_DUPLICATE,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredCategoriesByText(categories, sorting) {
  return categories.filter(
    (category) =>
      FILTER_PROPERTIES.filter((property) =>
        category[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

export function filterCategoriesByName(categoriesByUuid, searchValue) {
  const filteredCategoriesByUuid = {};
  Object.keys(categoriesByUuid).forEach((uuid) => {
    const category = categoriesByUuid[uuid];
    if (!searchValue || category.name.toLowerCase().includes(searchValue.toLowerCase())) {
      filteredCategoriesByUuid[category.uuid] = category;
    }
  });
  return filteredCategoriesByUuid;
}

export function getMenusOfFields(menusObject = {}) {
  const menusArray = [];
  Object.values(menusObject).forEach((menuUuid) => {
    if (menuUuid) menusArray.push({ uuid: menuUuid });
  });

  return menusArray;
}

export const defaultCategoryObject = {
  availability: 'AVAILABLE',
  legacy_id: null,
  name: null,
  sort_position: 0,
  store: null,
  menus: [],
};

export function setProductsSortingPosition(products) {
  return products?.map((product, index) => ({
    ...product,
    sortingPosition: index,
  }));
}

export function setProductsSortBy(products) {
  return sortBy(products, ['sortingPosition', 'name']);
}

export function isMenuFieldWasSelected(menusField = {}) {
  const menusArray = Object.entries(menusField);
  return menusArray.some((menu) => typeof menu[1] !== 'boolean');
}

export function joinBrand(menuList, allMenus) {
  return menuList.map((menu) => {
    return {
      ...menu,
      brand: allMenus.find((m) => m.uuid === menu.uuid)?.brand || {},
    };
  });
}

export function getUuidProducts(products) {
  if (products) return products?.map((product) => product?.uuid);

  return [];
}
