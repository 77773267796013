/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
  },

  paper: {
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
    marginLeft: theme.spacing(2),
  },

  label: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(22),
  },

  checkBoxWrapper: {
    display: 'flex',
    alignContent: 'baseline',
    alignItems: 'center',
  },
}));

export default useStyles;
