import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import modifierGroupsSaga from './sagas';
import reducer from './reducer';

export function ModifierGroupsProvider({ children }) {
  useInjectSaga({ key: 'modifierGroupsProvider', saga: modifierGroupsSaga });
  useInjectReducer({ key: 'modifierGroupsProvider', reducer });

  return React.Children.only(children);
}

ModifierGroupsProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withModifierGroups } from './withModifierGroups';
export default compose(withConnect, memo)(ModifierGroupsProvider);
