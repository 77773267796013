import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectRoleProviderDomain = (state) => state.roleProvider || initialState;

// Get Role
const selectRole = createSelector(selectRoleProviderDomain, (roleState) => roleState.getRole.instance);
const selectRoleError = createSelector(selectRoleProviderDomain, (roleState) => roleState.getRole.error);
const selectRoleIsFetching = createSelector(selectRoleProviderDomain, (roleState) => roleState.getRole.isFetching);
const selectRoleDidFetch = createSelector(selectRoleProviderDomain, (roleState) => roleState.getRole.didFetch);

// Save Role
const selectSaveRoleError = createSelector(selectRoleProviderDomain, (roleState) => roleState.saveRole.error);
const selectSaveRoleIsFetching = createSelector(selectRoleProviderDomain, (roleState) => roleState.saveRole.isFetching);
const selectSaveRoleDidFetch = createSelector(selectRoleProviderDomain, (roleState) => roleState.saveRole.didFetch);

export {
  selectRoleProviderDomain,
  // Get Role
  selectRole,
  selectRoleError,
  selectRoleIsFetching,
  selectRoleDidFetch,
  // Save Role
  selectSaveRoleError,
  selectSaveRoleIsFetching,
  selectSaveRoleDidFetch,
};
