import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getReportsPayments,
  getReportsPaymentsByPagination,
  getReportsPaymentsBySorting,
  exportReportsPayments,
} from './actions';

import {
  selectReportsPaymentsSummaries,
  selectReportsPaymentsHeaders,
  selectReportsPaymentsList,
  selectReportsPaymentsPagination,
  selectReportsPaymentsPaginationState,
  selectReportsPaymentsState,
  selectReportsPaymentsSortingState,
  selectExportReportsPaymentsState,
  selectExportReportsPaymentsDocument,
} from './selectors';

export function withReportsPaymentsHoc(Component) {
  function withReportsPayments(props) {
    return <Component {...props} />;
  }

  withReportsPayments.displayName = `withReportsPayments(${getDisplayName(Component)})`;
  return withReportsPayments;
}

const mapStateToProps = createStructuredSelector({
  reportsPaymentsSummaries: selectReportsPaymentsSummaries,
  reportsPaymentsHeaders: selectReportsPaymentsHeaders,
  reportsPaymentsRows: selectReportsPaymentsList,
  reportsPaymentsState: selectReportsPaymentsState,
  reportsPaymentsPaginationValues: selectReportsPaymentsPagination,
  reportsPaymentsPaginationState: selectReportsPaymentsPaginationState,
  reportsPaymentsSortingState: selectReportsPaymentsSortingState,
  exportReportsPaymentsDocument: selectExportReportsPaymentsDocument,
  exportReportsPaymentsState: selectExportReportsPaymentsState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsPayments: (filters = {}) => dispatch(getReportsPayments(filters)),
    loadReportsPaymentsPagination: (filters = {}) => dispatch(getReportsPaymentsByPagination(filters)),
    loadReportsPaymentsSorting: (filters = {}) => dispatch(getReportsPaymentsBySorting(filters)),
    exportReportsPayments: (filters = {}) => dispatch(exportReportsPayments(filters)),
    resetReportsPayments: () => dispatch(getReportsPayments.reset()),
    resetReportsPaymentsPagination: () => dispatch(getReportsPaymentsByPagination.reset()),
    resetReportsPaymentsSorting: () => dispatch(getReportsPaymentsBySorting.reset()),
    resetExportReportsPayments: () => dispatch(exportReportsPayments.reset()),
  };
}

export const withReportsPaymentsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withReportsPaymentsState, withReportsPaymentsHoc);
