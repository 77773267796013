import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectMenuMakerPreviewProviderDomain = (state) => state.menuMakerPreviewProvider || initialState;

const selectMenuPreview = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuPreview.menu,
);
const selectMenuCategoriesPreview = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuPreview.menuCategories,
);

const selectMenuProductsDetailsPreview = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuPreview.menuProductsDetails,
);

const selectMenuPreviewFetching = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuPreview.fetching,
);

const selectMenuPreviewFetched = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuPreview.fetched,
);

const selectMenuPreviewError = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuPreview.error,
);

const selectMenuCategoryProductsFetching = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuCategoryProducts.fetching,
);

const selectMenuCategoryProductsFetched = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuCategoryProducts.fetched,
);

const selectMenuCategoryProductsError = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuCategoryProducts.error,
);

const selectMenuProductDetailsFetching = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuProductDetails.fetching,
);

const selectMenuProductDetailsFetched = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuProductDetails.fetched,
);

const selectMenuProductDetailsError = createSelector(
  selectMenuMakerPreviewProviderDomain,
  (subState) => subState.getMenuProductDetails.error,
);

const selectExportMenuState = createSelector(selectMenuMakerPreviewProviderDomain, (subState) => subState.exportMenu);

export {
  selectMenuMakerPreviewProviderDomain,
  selectMenuPreview,
  selectMenuCategoriesPreview,
  selectMenuProductsDetailsPreview,
  selectMenuPreviewFetching,
  selectMenuPreviewFetched,
  selectMenuPreviewError,
  selectMenuCategoryProductsFetching,
  selectMenuCategoryProductsFetched,
  selectMenuCategoryProductsError,
  selectMenuProductDetailsFetching,
  selectMenuProductDetailsFetched,
  selectMenuProductDetailsError,
  selectExportMenuState,
};
