import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import rolesSaga from './sagas';
import reducer from './reducer';

export function RolesProvider({ children }) {
  useInjectSaga({ key: 'rolesProvider', saga: rolesSaga });
  useInjectReducer({ key: 'rolesProvider', reducer });

  return React.Children.only(children);
}

RolesProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withRoles } from './withRoles';
export default compose(withConnect, memo)(RolesProvider);
