import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectMenuMakerDetailsProviderDomain = (state) => state.menuMakerDetailsProvider || initialState;

const selectMenu = createSelector(selectMenuMakerDetailsProviderDomain, (subState) => subState.menuDetails.menu);

const selectMenuFetching = createSelector(
  selectMenuMakerDetailsProviderDomain,
  (subState) => subState.menuDetails.fetching,
);

const selectMenuFetched = createSelector(
  selectMenuMakerDetailsProviderDomain,
  (subState) => subState.menuDetails.fetched,
);

const selectMenuError = createSelector(selectMenuMakerDetailsProviderDomain, (subState) => subState.menuDetails.error);

const selectEditMenuFetching = createSelector(
  selectMenuMakerDetailsProviderDomain,
  (subState) => subState.editMenu.fetching,
);

const selectEditMenuFetched = createSelector(
  selectMenuMakerDetailsProviderDomain,
  (subState) => subState.editMenu.fetched,
);

const selectEditMenuError = createSelector(selectMenuMakerDetailsProviderDomain, (subState) => subState.editMenu.error);

export {
  selectMenuMakerDetailsProviderDomain,
  selectMenu,
  selectMenuFetching,
  selectMenuFetched,
  selectMenuError,
  selectEditMenuFetching,
  selectEditMenuFetched,
  selectEditMenuError,
};
