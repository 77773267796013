import { createSelector } from 'reselect';

import { getFilteredVoidReasonsByText } from 'utils/voidReasons';

import { initialState } from './reducer';

const selectVoidReasonsProviderDomain = (state) => state.restaurantVoidReasonsProvider || initialState;

const selectVoidReasons = createSelector(selectVoidReasonsProviderDomain, ({ getVoidReasons, sorting }) => {
  let voidReasonsList = [...getVoidReasons.voidReasons];

  const { filterByText } = sorting;

  if (filterByText && filterByText.length >= 3) {
    voidReasonsList = getFilteredVoidReasonsByText(getVoidReasons.voidReasons, sorting);
  }

  return voidReasonsList;
});

const selectVoidReasonsPagination = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.getVoidReasons.pagination,
);

const selectVoidReasonsSorting = createSelector(selectVoidReasonsProviderDomain, (subState) => subState.sorting);

const selectVoidReasonsFetching = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.getVoidReasons.fetching,
);

const selectVoidReasonsFetched = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.getVoidReasons.fetched,
);

const selectVoidReasonsError = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.getVoidReasons.error,
);

const selectVoidReasonsPaginationStatus = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.loadPaginationStatus,
);

const selectCreateVoidReason = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.createVoidReason.voidReason,
);

const selectCreateVoidReasonFetching = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.createVoidReason.fetching,
);

const selectCreateVoidReasonFetched = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.createVoidReason.fetched,
);

const selectCreateVoidReasonError = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.createVoidReason.error,
);

const selectDeleteVoidReasonFetching = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.deleteVoidReason.fetching,
);

const selectDeleteVoidReasonFetched = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.deleteVoidReason.fetched,
);

const selectDeleteVoidReasonError = createSelector(
  selectVoidReasonsProviderDomain,
  (subState) => subState.deleteVoidReason.error,
);

export {
  selectVoidReasonsProviderDomain,
  selectVoidReasons,
  selectVoidReasonsPagination,
  selectVoidReasonsSorting,
  selectVoidReasonsFetching,
  selectVoidReasonsFetched,
  selectVoidReasonsError,
  selectVoidReasonsPaginationStatus,
  selectCreateVoidReason,
  selectCreateVoidReasonFetching,
  selectCreateVoidReasonFetched,
  selectCreateVoidReasonError,
  selectDeleteVoidReasonFetching,
  selectDeleteVoidReasonFetched,
  selectDeleteVoidReasonError,
};
