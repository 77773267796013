export const GET_PAYMENTS = 'containers/RestaurantPaymentsProvider/GET_PAYMENTS';

export const GET_PAYMENT_DETAIL = 'containers/RestaurantPaymentsProvider/GET_PAYMENT_DETAIL';

export const CREATE_PAYMENT = 'containers/RestaurantPaymentsProvider/CREATE_PAYMENT';

export const DELETE_PAYMENT = 'containers/RestaurantPaymentsProvider/DELETE_PAYMENT';

export const TOGGLE_AVAILABILITY_PAYMENT = 'containers/RestaurantPaymentsProvider/TOGGLE_AVAILABILITY_PAYMENT';

export const EDIT_PAYMENT = 'containers/RestaurantPaymentsProvider/EDIT_PAYMENT';

export const UPDATE_PAYMENT_POSITION = 'containers/RestaurantPaymentsProvider/UPDATE_PAYMENT_POSITION';

export const GET_PAYMENTS_SCROLL_PAGINATION = 'containers/RestaurantPaymentsProvider/GET_PAYMENTS_SCROLL_PAGINATION';
