import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'containers/AuthProvider';
import useAmplitude from 'providers/AmplitudeProvider/useAmplitude';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import SignInLayout from 'components/SignInLayout';

import { ReactComponent as IconParrotPOS } from 'images/svg/brand/parrot_connect_large.svg';

import { removeAuthToken, removeCookieUserStore, removeUserStoresStoreKey, removeUserCurrentStore } from 'utils/auth';
import { removeBrandSelectedLocalStorage } from 'utils/menuMaker';

import useStyles from './styles';

function SignOut({ t }) {
  const classes = useStyles();
  const history = useHistory();
  const { resetGetUser, resetLicenseState, clearLicenseStateChannel } = useAuth();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent('@event.$login.logout');
    removeAuthToken();
    removeCookieUserStore();
    removeUserStoresStoreKey();
    removeUserCurrentStore();
    resetGetUser();
    resetLicenseState();
    clearLicenseStateChannel();
    removeBrandSelectedLocalStorage();
    history.push('/signin?continue=%0');
  });

  return (
    <SignInLayout>
      <div className={classes.header}>
        <IconParrotPOS className={classes.parrotS} />
      </div>

      <Box mb={2} mt={4}>
        <Typography gutterBottom variant="h6">
          {t('common:auth.signingOut')}
        </Typography>
      </Box>

      <Box align="center" mb={2} width="80%">
        <CircularProgress />
      </Box>
    </SignInLayout>
  );
}

SignOut.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(SignOut);
