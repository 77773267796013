import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionMui from '@material-ui/core/Accordion';

import AccordionSummary from './AccordionSummary';
import AccordionDetails from './AccordionDetails';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
  },
}));

function Accordion({ ...others }) {
  const classes = useStyles();

  return <AccordionMui classes={{ root: classes.root }} elevation={0} {...others} />;
}

Accordion.Summary = AccordionSummary;
Accordion.Details = AccordionDetails;

Accordion.propTypes = {
  ...AccordionMui.propTypes,
};

export default Accordion;
