import PropTypes from 'prop-types';

import { useForm, FormProvider } from 'react-hook-form';

import ProductForm from 'components/Products/ProductForm';
import { actions } from 'utils/menuMaker';

function AddProductForm({ categoriesMenusList = [], kitchenAreas, ...other }) {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      kitchenAreasFields: [],
    },
  });

  return (
    <FormProvider {...formMethods}>
      <ProductForm
        action={actions.ADD}
        categoriesMenusList={categoriesMenusList}
        kitchenAreas={kitchenAreas}
        {...other}
      />
    </FormProvider>
  );
}

AddProductForm.propTypes = {
  kitchenAreas: PropTypes.array,
  categoriesMenusList: PropTypes.array,
};

export default AddProductForm;
