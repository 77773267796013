import { takeLatest } from 'redux-saga/effects';

import { getModifierGroup, editModifierGroup } from '../actions';

import { handleGetModifierGroupRequest } from './getModifierGroup';
import { handleEditModifierGroupRequest } from './editModifierGroup';

export default function* modifierGroupSaga() {
  yield takeLatest(getModifierGroup.TRIGGER, handleGetModifierGroupRequest);
  yield takeLatest(editModifierGroup.TRIGGER, handleEditModifierGroupRequest);
}
