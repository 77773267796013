import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsDiscountsProviderDomain = (state) => state.reportsDiscountsProvider || initialState;

const selectReportsDiscountsResume = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.getReportsDiscountsResume.resume,
);

const selectReportsDiscountsResumeState = createSelector(
  selectReportsDiscountsProviderDomain,
  ({ getReportsDiscountsResume: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsDiscountsResumePagination = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.getReportsDiscountsResume.pagination,
);

const selectReportsDiscountsResumePaginationState = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.loadPaginationResumeStatus,
);

const selectReportsDiscountsResumeSortingState = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.sortingResumeStatus,
);

const selectReportsDiscountsDetails = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.getReportsDiscountsDetails.discounts,
);

const selectReportsDiscountsDetailsState = createSelector(
  selectReportsDiscountsProviderDomain,
  ({ getReportsDiscountsDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsDiscountsDetailsPagination = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.getReportsDiscountsDetails.pagination,
);

const selectReportsDiscountsDetailsPaginationState = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.loadPaginationDetailsStatus,
);

const selectReportsDiscountsDetailsSortingState = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.sortingDetailsStatus,
);

const selectExportReportsDiscountsState = createSelector(
  selectReportsDiscountsProviderDomain,
  ({ exportReportsDiscounts: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectExportReportsDiscountsDocument = createSelector(
  selectReportsDiscountsProviderDomain,
  (subState) => subState.exportReportsDiscounts.document,
);

export {
  selectReportsDiscountsProviderDomain,
  selectReportsDiscountsResume,
  selectReportsDiscountsResumeState,
  selectReportsDiscountsResumePagination,
  selectReportsDiscountsResumePaginationState,
  selectReportsDiscountsResumeSortingState,
  selectReportsDiscountsDetails,
  selectReportsDiscountsDetailsState,
  selectReportsDiscountsDetailsPagination,
  selectReportsDiscountsDetailsPaginationState,
  selectReportsDiscountsDetailsSortingState,
  selectExportReportsDiscountsState,
  selectExportReportsDiscountsDocument,
};
