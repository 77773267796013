import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import useTheme from '@material-ui/core/styles/useTheme';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import { ViewPanelLayout } from 'Layout';

import SelectDS from '@design-system/Select';
import ButtonPrimary from '@design-system/ButtonPrimary';
import InputTextFiled from '@design-system/InputTextFiled';
import Accordion from '@design-system/Accordion';
import IconButton from '@design-system/IconButton';

import Help from '@experimental-components/IconsComponents/Help';
import NumberField from '@experimental-components/NumberField';

import MultiSelect from 'components/MultiSelect';
import ImageDropzone from 'components/ImageDropzone';
import RadioWithLabels from 'components/RadioWithLabel';
import ConfirmDialog from 'components/ProductForm/modalConfirm';

import Divider from '@experimental-components/Divider';

import useUserStore from 'hooks/useUserStore';
import { units, pieceUnits, gramUnits, kilogramUnits, litreUnits, millilitreUnits } from 'utils/products';
import { taxProductKeys, taxServingUnitTypes } from 'utils/products/taxTypes';

import relativePercentageDiff from 'utils/products/priceVariation';

import {
  actionsTypes,
  getMergeKitchenAreasAdd,
  getMergeKitchenAreasRemove,
  getCategoriesChanges,
  getKitchenAreasDetail,
} from 'utils/kitchenAreas/makeKitchenAreasFields';

import { IS_ENABLE } from 'utils/kitchenAreas/isEnable';

import ToolTip from '../ToolTip';

import { SUBMIT_CHANGE_PRICE_EVENT_KEY, formatPrice, statusType, PERCENTAGE_PRICE_VARIATION } from './utils';

import KitchenAreasAsigned from './KitchenAreasAsigned';
import KitchenAreasBox from './kitchenAreasBox';
import useStyles from './styles';

function ProductForm({
  action,
  availableCategories,
  kitchenAreas,
  loading,
  modifierGroupsList,
  onUpdateProductPrice,
  product,
  saveProduct,
  t,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors, formState, clearErrors, reset, getValues, control } = useFormContext();
  const [selectedVirtualCategories, setSelectedVirtualCategories] = useState([]);
  const [canSelectOtherAreas, setCanSelectOtherAreas] = useState(product?.kitchenAreasAssignation.length > 0);

  const kitchenAreasFields = useWatch({
    control,
    name: 'kitchenAreasFields',
  });

  const { isValid: isValidationForm } = formState;

  const price = product?.price || '0.00';
  const isExistingProduct = !!product?.uuid;

  const initialState = {
    isSoldAlone: true,
    hasFixedPrice: true,
    servingUnit: pieceUnits,
    image: null,
  };

  const { storeId: storeUuid } = useUserStore();
  const [state, setState] = useState(initialState);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedModifierGroups, setSelectedModifierGroups] = useState([]);
  const [initialImageUrl, setInitialImageUrl] = useState(product?.image);
  const [showConfirmModiferGroup, setShowConfirmModiferGroup] = useState({});
  const [showConfirmAreasKitchen, setShowConfirmAreasKitchen] = useState({});
  const [isPriceOverRange, setIsPriceOverRange] = useState({});
  const [newProductPrice, setNewProductPrice] = useState();
  const [currentMenuToChange, setCurrentMenuToChange] = useState();
  const [virtualKitchenAreas, setVirtualKitchenAreas] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [toolTipMessage, setToolTipMessage] = useState('');

  const selectableUnits = units.filter((unit) => unit !== pieceUnits);

  const isValid =
    isValidationForm && (state.isSoldAlone && canSelectOtherAreas && IS_ENABLE ? kitchenAreasFields?.length > 0 : true);

  useEffect(() => {
    const initialCategories = [];
    product?.categories?.forEach((group) => {
      availableCategories?.forEach((category) => {
        if (category.uuid === group.category.uuid) {
          initialCategories.push(category);
        }
      });
    });
    setSelectedCategories(initialCategories);
  }, [product, availableCategories]);

  useEffect(() => {
    if (product && Object.keys(product).length > 0) {
      const isSoldAlone = product.soldAlone;
      const hasFixedPrice = !isSoldAlone || !product || product.servingUnit === pieceUnits;
      const servingUnit = product?.servingUnit || pieceUnits;
      setState((s) => ({
        ...s,
        isSoldAlone,
        hasFixedPrice,
        servingUnit,
        image: null,
      }));
    }
    const modifierGroupsByUuid = {};
    modifierGroupsList.forEach((modifierGroup) => {
      modifierGroupsByUuid[modifierGroup.uuid] = modifierGroup;
    });

    const initialProductModifierGroups = [];
    product?.modifierGroups?.forEach((group) => {
      const modifier = modifierGroupsByUuid[group.modifierGroup.uuid];
      if (modifier) {
        initialProductModifierGroups.push(modifier);
      }
    });
    setSelectedModifierGroups(initialProductModifierGroups);
  }, [product, modifierGroupsList]);

  function renderProductCatalogueItem(selectedKey) {
    const renderSelected = (
      <Box color={theme.palette.darkGrey[50]} fontSize={14}>
        {t('common:actions.select')}
      </Box>
    );

    if (selectedKey) {
      const itemSelected = taxProductKeys.filter((item) => item.key === selectedKey);
      return itemSelected[0]?.label;
    }

    return renderSelected;
  }

  function renderServingUnitItem(selectedKey) {
    const renderSelected = (
      <Box color={theme.palette.darkGrey[50]} fontSize={14}>
        {t('common:actions.select')}
      </Box>
    );

    if (selectedKey) {
      const itemSelected = taxServingUnitTypes.filter((item) => item.key === selectedKey);
      return itemSelected[0]?.label;
    }

    return renderSelected;
  }

  function handleGoBackButtonClick() {
    history.push('/menus/products');
  }

  function handleServingUnitChangeEvent(event) {
    setState((s) => ({ ...s, servingUnit: event.target.value }));
  }

  function handleCancelConfirmation() {
    setIsPriceOverRange({ show: false });
  }

  function handleConfirmProductPriceChange() {
    onUpdateProductPrice({
      menuUuid: currentMenuToChange?.menu?.uuid,
      productUuid: product?.uuid,
      price: newProductPrice,
      availability: statusType.STATUS_AVAILABLE,
    });
    setIsPriceOverRange({ show: false });
  }

  const handlePriceVariation = (menu) => (event) => {
    setCurrentMenuToChange(menu);
    const currentPriceMenu = product?.menus?.find((productMenu) => productMenu?.uuid === menu.uuid);
    const updatedPrice = formatPrice(event.target.value);
    if (event.key === SUBMIT_CHANGE_PRICE_EVENT_KEY) {
      if (currentPriceMenu.price > 0 && updatedPrice !== currentPriceMenu.price) {
        const priceVariation = relativePercentageDiff(currentPriceMenu.price, updatedPrice);
        if (priceVariation > PERCENTAGE_PRICE_VARIATION) {
          setNewProductPrice(updatedPrice);
          return setIsPriceOverRange({ show: true, menuName: menu?.menu?.name });
        }
        return onUpdateProductPrice({
          menuUuid: menu?.menu?.uuid,
          productUuid: product?.uuid,
          price: updatedPrice,
          availability: statusType.STATUS_AVAILABLE,
        });
      }
    }
  };

  function setSoldAlone(value, isSoldAloneLabels) {
    reset({ kitchenAreasFields: [] });
    if (value === 'No') {
      setSelectedCategories([]);
      if (!getValues?.description) {
        reset({ description: product?.description || getValues?.description });
      }
      clearErrors('description');
    }

    const isSoldAlone = value === isSoldAloneLabels[0];
    const servingUnit = isSoldAlone ? pieceUnits : state.servingUnit;
    const hasFixedPrice = !isSoldAlone ? true : state.hasFixedPrice;

    setState((s) => ({ ...s, isSoldAlone, servingUnit, hasFixedPrice }));
  }

  function renderIsSoldAloneSelection() {
    const isSoldAloneLabels = [
      t('menuMaker:productForm.isSoldAlone.trueLabel'),
      t('menuMaker:productForm.isSoldAlone.falseLabel'),
    ];

    function handleIsSoldAloneChange(value) {
      if (product && IS_ENABLE) {
        setShowConfirmModiferGroup({
          message: value === 'No' ? 'changeToNoIdependent' : 'changeToIdependent',
          value,
          isSoldAloneLabels,
        });
        return;
      }
      setSoldAlone(value, isSoldAloneLabels);
    }

    return (
      <RadioWithLabels
        currentValue={state.isSoldAlone ? isSoldAloneLabels[0] : isSoldAloneLabels[1]}
        informationText={t('menuMaker:productForm.isSoldAlone.helperLabel')}
        onSelectionChanged={handleIsSoldAloneChange}
        radioLabels={isSoldAloneLabels}
        titleLabel={t('menuMaker:productForm.isSoldAlone.label')}
      />
    );
  }

  function renderModifiers() {
    const sortedModifierGroups = Object.values(modifierGroupsList).sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Accordion>
        <Accordion.Summary>
          <Box fontSize={18} fontWeight="fontWeightMedium">
            {t('menuMaker:productForm.modifierGroupsPicker.header')}
          </Box>
        </Accordion.Summary>

        <Accordion.Details>
          <MultiSelect
            getOptionLabel={(option) => option.name}
            getOptionUniqueKey={(option) => option.uuid}
            inputPlaceholderText={t('menuMaker:productForm.modifierGroupsPicker.inputPlaceholder')}
            onChange={(values) => setSelectedModifierGroups(values)}
            options={sortedModifierGroups}
            selectedOptions={selectedModifierGroups}
            titleLabel={t('menuMaker:productForm.modifierGroupsPicker.title')}
          />
        </Accordion.Details>
      </Accordion>
    );
  }

  function renderIsSoldAlone() {
    const fixedPriceLabels = [
      t('menuMaker:productForm.priceType.fixedLabel'),
      t('menuMaker:productForm.priceType.perUnitLabel'),
    ];

    function handlePriceTypeChange(value) {
      const hasFixedPrice = value === fixedPriceLabels[0];
      const servingUnitIfNotFixed = state.servingUnit === pieceUnits ? selectableUnits[0] : state.servingUnit;
      const servingUnit = hasFixedPrice ? pieceUnits : servingUnitIfNotFixed;
      setState((s) => ({ ...s, hasFixedPrice, servingUnit }));
    }

    return (
      <>
        <Box mb={3}>
          <RadioWithLabels
            currentValue={state.hasFixedPrice ? fixedPriceLabels[0] : fixedPriceLabels[1]}
            disabled={isExistingProduct}
            onSelectionChanged={handlePriceTypeChange}
            radioLabels={fixedPriceLabels}
            titleLabel={t('menuMaker:productForm.priceType.label')}
          />
        </Box>
        <Box mb={3}>{renderPriceTypeUnit()}</Box>
        <Box mb={3}>{renderPriceInput()}</Box>
        <Box mb={3}>{renderPricePerMenu()}</Box>
        <Box mb={3}>{renderCategories()}</Box>
      </>
    );
  }

  function renderIsNotSoldAlone() {
    return renderPriceInput();
  }

  function handleImageChange(imageFile) {
    setInitialImageUrl(null);
    setState((s) => ({ ...s, image: imageFile }));
  }

  function handleProductSave(data) {
    const categories = selectedCategories.map((category, index) => ({
      category: category.uuid,
      sorting_position: index,
    }));

    const modifierGroups = selectedModifierGroups.map((modifier, index) => ({
      modifier_group: modifier.uuid,
      sorting_position: index,
    }));

    let kitchenAreasValues = {};
    if (canSelectOtherAreas && IS_ENABLE) {
      kitchenAreasValues = {
        uuids: data?.kitchenAreasFields,
        can_print: !data.kitchenAreasFieldsCanPrint,
      };
    }

    const newOrUpdatedProduct = {
      name: data.name,
      description: data.description || '',
      price: data.price,
      serving_unit: state.servingUnit,
      modifier_groups: modifierGroups,
      categories,
      image: state.image,
      sold_alone: state.isSoldAlone,
      pos_name: data.posName,
      sku: data.sku,
      kitchen_areas: kitchenAreasValues,
    };

    if (isExistingProduct) {
      newOrUpdatedProduct.uuid = product.uuid;
    }
    if (data.productCatalogueFiled) {
      newOrUpdatedProduct.tax_product_key = data.productCatalogueFiled;
    }
    if (data.taxServingUnitFiled) {
      newOrUpdatedProduct.tax_serving_unit = data.taxServingUnitFiled;
    }
    saveProduct({
      storeUuid,
      product: newOrUpdatedProduct,
    });
  }

  function renderPriceTypeUnit() {
    if (state.hasFixedPrice || state.servingUnit === pieceUnits) {
      return null;
    }

    return (
      <FormControl>
        <Typography gutterBottom variant="h6">
          {t('menuMaker:productForm.unitTypeLabel')}
        </Typography>

        <Select
          disabled={isExistingProduct}
          id="price-unit-select"
          labelId="demo-simple-select-label"
          onChange={handleServingUnitChangeEvent}
          value={state.servingUnit}
        >
          {selectableUnits.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {t(`menuMaker:productForm.units.${value}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  function renderPriceInput() {
    const unitMap = {
      [gramUnits]: 'gr',
      [kilogramUnits]: 'kg',
      [litreUnits]: 'lt',
      [millilitreUnits]: 'ml',
    };
    let endAdornment;
    if (state.servingUnit !== pieceUnits) {
      endAdornment = <InputAdornment position="end">/{unitMap[state.servingUnit]}</InputAdornment>;
    }
    return (
      <Box>
        <Typography gutterBottom variant="h6">
          {t('menuMaker:productForm.price.label')}
        </Typography>

        <TextField
          defaultValue={price}
          disabled={loading}
          error={Boolean(errors?.price)}
          helperText={errors?.price?.message}
          InputProps={{
            endAdornment,
            name: 'price',
            startAdornment: (
              <InputAdornment color={errors?.price && 'error'} position="start">
                $
              </InputAdornment>
            ),
            inputProps: {
              step: '0.01',
              type: 'number',
            },
          }}
          inputRef={register({
            min: {
              message: t('menuMaker:productForm.price.noNegativeValuesErrorLabel'),
              value: 0,
            },
            maxLength: {
              message: t('menuMaker:productForm.price.tooManyDigitsErrorLabel'),
              value: 10,
            },
            required: 'This field is required',
          })}
          name="price"
          placeholder={t('menuMaker:productForm.price.placeholderLabel')}
          variant="outlined"
        />
      </Box>
    );
  }

  function proccessSelectedCategories(selectedOptions, categoriesChanges) {
    setSelectedCategories(selectedOptions);
    if (categoriesChanges.actionType === actionsTypes.ACTION_TYPE_NOTHING) return;

    let newKitchenAreas = [];
    if (categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD) {
      newKitchenAreas = getMergeKitchenAreasAdd(kitchenAreasFields, categoriesChanges?.kitchenAreasAssignation);
    } else {
      newKitchenAreas = getMergeKitchenAreasRemove(
        kitchenAreasFields,
        selectedCategories,
        categoriesChanges?.kitchenAreasAssignation,
      );
    }
    reset({ kitchenAreasFields: newKitchenAreas });
  }
  function proccessSelectedCategoriesVirtual(selectedOptions, categoriesChanges) {
    setSelectedVirtualCategories(selectedOptions);
    let newKitchenAreas = [];
    if (categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD) {
      const defaultConfig = {};
      newKitchenAreas = getMergeKitchenAreasAdd(kitchenAreasFields, categoriesChanges?.kitchenAreasAssignation);

      if (kitchenAreasFields?.length > 0) {
        newKitchenAreas.forEach((kictehArea) => {
          defaultConfig[`${kictehArea}Field`] = true;
        });
      }
    } else {
      newKitchenAreas = getMergeKitchenAreasRemove(
        kitchenAreasFields,
        selectedCategories,
        categoriesChanges?.kitchenAreasAssignation,
      );
    }
    setVirtualKitchenAreas(newKitchenAreas);
  }

  function handleChangeCategories(selectedOptions) {
    const categoriesChanges = getCategoriesChanges(selectedCategories, selectedOptions);
    if (!IS_ENABLE) {
      setSelectedCategories(selectedOptions);
      return;
    }
    if (product && !canSelectOtherAreas) {
      if (categoriesChanges.actionType !== actionsTypes.ACTION_TYPE_NOTHING && !canSelectOtherAreas) {
        setShowConfirmAreasKitchen({
          message:
            categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD
              ? t('menuMaker:dialogConfirmKitchenAreas.messageAddMode')
              : t('menuMaker:dialogConfirmKitchenAreas.messageRemoveMode'),
          show: true,
          isAddMode: categoriesChanges.actionType === actionsTypes.ACTION_TYPE_ADD,
        });
        proccessSelectedCategoriesVirtual(selectedOptions, categoriesChanges);
      }
    } else if (product && canSelectOtherAreas) {
      setSelectedCategories(selectedOptions);
    } else if (!product && !canSelectOtherAreas) {
      proccessSelectedCategories(selectedOptions, categoriesChanges);
    } else {
      setSelectedCategories(selectedOptions);
    }
  }

  function renderPricePerMenu() {
    if (product?.menus?.length > 0) {
      return (
        <Box>
          <Box
            alignItems="center"
            css={{
              '& > :not(:first-child)': {
                marginLeft: theme.spacing(2),
              },
            }}
            display="flex"
            mb={2}
          >
            <Box fontSize="1.25rem" fontWeight="fontWeightMedium">
              {t('menuMaker:productForm.pricePerMenu.label')}
            </Box>
            <IconButton onClick={handleSetAnchorEl('menuMaker:productForm.pricePerMenu.helperText')}>
              <Help />
            </IconButton>
          </Box>
          <Grid container direction="row" spacing={4}>
            {product?.menus?.map((menu, index) => (
              <Grid key={menu?.uuid} item md={4} sm={6} xl={4}>
                <Controller
                  control={control}
                  defaultValue={menu?.price}
                  name={`pricePerMenu[${index}].${menu.uuid}`}
                  render={({ onChange, value, name, ref }) => (
                    <NumberField
                      decimalScale={2}
                      fullWidth
                      inputRef={ref}
                      label={menu?.menu?.name}
                      name={name}
                      onChange={onChange}
                      onKeyDown={handlePriceVariation(menu)}
                      prefix="$"
                      suffixAdornment={<KeyboardReturnIcon color="action" fontSize="small" />}
                      value={value}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }
    return null;
  }

  function renderCategories() {
    const sortedCategories = availableCategories?.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <MultiSelect
        getOptionLabel={(option) => option.name}
        getOptionUniqueKey={(option) => option.uuid}
        inputPlaceholderText={t('menuMaker:productForm.categoryPicker.inputPlaceholder')}
        onChange={handleChangeCategories}
        options={sortedCategories}
        selectedOptions={selectedCategories}
        titleLabel={t('menuMaker:productForm.categoryPicker.title')}
      />
    );
  }

  function handleConfirmChangeIndependent() {
    setSoldAlone(showConfirmModiferGroup.value, showConfirmModiferGroup.isSoldAloneLabels);
    setShowConfirmModiferGroup({});
  }

  function handleCancelConfirmNewAreasKitchen() {
    setShowConfirmAreasKitchen({ show: false });
    setSelectedVirtualCategories([]);
    setVirtualKitchenAreas([]);
  }

  function handleConfirmNewAreasKitchen() {
    setSelectedCategories(selectedVirtualCategories);
    handleCancelConfirmNewAreasKitchen();
    reset({ kitchenAreasFields: virtualKitchenAreas });
  }

  function handleCanSelectAreas(event) {
    const { checked } = event.target;
    setCanSelectOtherAreas(checked);
    if (checked && kitchenAreasFields?.length > 0) {
      reset({ kitchenAreasFields: [] });
    } else {
      const categoriesChanges = getCategoriesChanges([], selectedCategories);
      const newKitchenAreas = getMergeKitchenAreasAdd([], categoriesChanges?.kitchenAreasAssignation);
      reset({ kitchenAreasFields: newKitchenAreas });
    }
  }

  const handleSetAnchorEl = (msg) => (event) => {
    setAnchorEl(event.currentTarget);
    setToolTipMessage(msg);
  };

  function handleSetAnchorElClose() {
    setAnchorEl(null);
  }

  return (
    <ViewPanelLayout
      actionComponent={
        <ButtonPrimary disabled={loading || !isValid} loading={loading} onClick={handleSubmit(handleProductSave)}>
          {t('common:buttons.save')}
        </ButtonPrimary>
      }
      disabledGoBack={loading}
      labelGoBack={t('common:buttons.toReturn')}
      onGoBack={handleGoBackButtonClick}
      pb={4}
      pl={0}
      pr={0}
    >
      <Box pl={4} pr={4}>
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography className={classes.title}>{t(`menuMaker:sections.${action}Product`)}</Typography>
              </Grid>

              <Grid item md={9} xs={12}>
                <InputTextFiled
                  autoFocus
                  defaultValue={product?.name || ''}
                  disabled={loading}
                  error={Boolean(errors?.name)}
                  errorMsg={errors?.name?.message}
                  fullWidth
                  inputProps={{ name: 'name', maxLength: 50 }}
                  inputRef={register({
                    required: t('common:messages.requireField'),
                  })}
                  label={t('menuMaker:productForm.namePlaceholder')}
                  name="name"
                  required
                />
              </Grid>

              <Grid item md={9} xs={12}>
                <InputTextFiled
                  defaultValue={product?.description || ''}
                  disabled={loading}
                  error={Boolean(errors?.description)}
                  errorMsg={errors?.description?.message}
                  fullWidth
                  inputProps={{ name: 'description', maxLength: 250 }}
                  inputRef={register(
                    state.isSoldAlone && {
                      minLength: {
                        value: 4,
                        message: t('common:errors.minLength4Field'),
                      },
                    },
                  )}
                  label={t('menuMaker:productForm.descriptionLabel')}
                  name="description"
                />
              </Grid>

              <Grid item>{renderIsSoldAloneSelection()}</Grid>
              <Grid item>{state.isSoldAlone && renderIsSoldAlone()}</Grid>
              <Grid item>{!state.isSoldAlone && renderIsNotSoldAlone()}</Grid>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <ImageDropzone
              buttonLabel={
                product?.image
                  ? t('menuMaker:productForm.image.buttonLabelChange')
                  : t('menuMaker:productForm.image.buttonLabelNew')
              }
              disabled={loading}
              dropzoneDescription={t('menuMaker:productForm.image.requirements')}
              initialImageUrl={initialImageUrl}
              onImageChange={handleImageChange}
              t={t}
              titleLabel={t('menuMaker:productForm.image.title')}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        {state.isSoldAlone && renderModifiers()}
        <Accordion>
          <Accordion.Summary>
            <Box fontSize={18} fontWeight="fontWeightMedium">
              {t('menuMaker:productForm.advancedConfiguration.header')}
            </Box>
          </Accordion.Summary>

          <Accordion.Details>
            <Grid container spacing={6}>
              <Grid container item>
                <Grid item xs={3}>
                  <Typography gutterBottom variant="h6">
                    {t('menuMaker:productForm.skuLabel')}
                  </Typography>

                  <TextField
                    defaultValue={product?.sku || null}
                    disabled={loading}
                    InputProps={{
                      name: 'sku',
                    }}
                    inputRef={register()}
                    name="sku"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography gutterBottom variant="h6">
                    {t('menuMaker:productForm.posNameLabel')}
                  </Typography>

                  <TextField
                    defaultValue={product?.posName || null}
                    InputProps={{
                      name: 'posName',
                    }}
                    inputRef={register()}
                    name="posName"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={3}>
                <Grid item xs>
                  <Box fontSize={18} fontWeight={theme.typography.fontWeightMedium}>
                    SAT
                  </Box>
                </Grid>

                <Grid container item spacing={4} xs={12}>
                  <Grid item md={3}>
                    <Controller
                      control={control}
                      name="taxServingUnitFiled"
                      render={({ onChange, value, name, ref }) => (
                        <SelectDS
                          defaultValue=""
                          disabled={loading}
                          displayEmpty
                          fullWidth
                          inputRef={ref}
                          label={t('menuMaker:productForm.unitMeasurement')}
                          name={name}
                          onChange={onChange}
                          renderValue={(selected) => renderServingUnitItem(selected)}
                          value={value}
                        >
                          {taxServingUnitTypes.map((taxKey) => (
                            <MenuItem key={taxKey.key} value={taxKey.key}>
                              {taxKey.label}
                            </MenuItem>
                          ))}
                        </SelectDS>
                      )}
                    />
                  </Grid>

                  <Grid item md={3}>
                    <Controller
                      control={control}
                      name="productCatalogueFiled"
                      render={({ onChange, value, name, ref }) => (
                        <SelectDS
                          defaultValue=""
                          disabled={loading}
                          displayEmpty
                          fullWidth
                          inputRef={ref}
                          label={t('menuMaker:productForm.productCatalogue')}
                          name={name}
                          onChange={onChange}
                          renderValue={(selected) => renderProductCatalogueItem(selected)}
                          value={value}
                        >
                          {taxProductKeys.map((taxKey) => (
                            <MenuItem key={taxKey.key} value={taxKey.key}>
                              {taxKey.label}
                            </MenuItem>
                          ))}
                        </SelectDS>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Accordion.Details>
        </Accordion>
      </Box>

      {state.isSoldAlone && IS_ENABLE && (
        <>
          <Box mb={7} mt={7}>
            <Divider />
          </Box>
          <KitchenAreasAsigned
            canSelectOtherArea={canSelectOtherAreas}
            control={control}
            kitchenAreas={kitchenAreas}
            loading={loading}
            onChangeCanSelectAreas={handleCanSelectAreas}
            register={register}
          />
        </>
      )}
      <ConfirmDialog
        id="dialogConfirmModifer"
        labelAccept={t('common:buttons.confirm')}
        labelCancel={t('common:buttons.cancel')}
        message={t(`menuMaker:dialogConfirmModiferGroup.${showConfirmModiferGroup.message}`)}
        onAccept={handleConfirmChangeIndependent}
        onCancel={() => {
          setShowConfirmModiferGroup({});
        }}
        open={Object.keys(showConfirmModiferGroup).length > 0}
        title={t('menuMaker:dialogConfirmModiferGroup.title')}
      />
      <ConfirmDialog
        id="dialogConfirmKitchenAreas"
        labelAccept={showConfirmAreasKitchen.isAddMode ? t('common:buttons.confirm') : t('common:buttons.accept')}
        labelCancel={t('common:buttons.cancel')}
        message={showConfirmAreasKitchen.message}
        onAccept={handleConfirmNewAreasKitchen}
        onCancel={handleCancelConfirmNewAreasKitchen}
        open={showConfirmAreasKitchen.show}
        title={t('menuMaker:dialogConfirmKitchenAreas.title')}
      >
        {!canSelectOtherAreas && virtualKitchenAreas?.length > 0 && showConfirmAreasKitchen.isAddMode && (
          <KitchenAreasBox
            kitchenAreasNames={getKitchenAreasDetail(virtualKitchenAreas, kitchenAreas).map((ka) => ka.name)}
          />
        )}
      </ConfirmDialog>
      <ConfirmDialog
        className={classes.modal}
        labelAccept={t('common:buttons.accept')}
        labelCancel={t('common:buttons.toReturn')}
        message={t('menuMaker:dialogConfirmPriceVariation.helperText')}
        onAccept={handleConfirmProductPriceChange}
        onCancel={handleCancelConfirmation}
        open={isPriceOverRange.show}
        title={t('menuMaker:dialogConfirmPriceVariation.title', { currentMenuName: isPriceOverRange.menuName })}
      />
      <ToolTip anchorEl={anchorEl} message={toolTipMessage} onClose={handleSetAnchorElClose} />
    </ViewPanelLayout>
  );
}

ProductForm.propTypes = {
  action: PropTypes.string,
  availableCategories: PropTypes.array,
  kitchenAreas: PropTypes.array,
  loading: PropTypes.bool,
  modifierGroupsList: PropTypes.array,
  onUpdateProductPrice: PropTypes.func,
  product: PropTypes.object,
  saveProduct: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(ProductForm);
