export const GET_REPORTS = 'containers/ReportsProvider/GET_REPORTS';
export const GET_REPORTS_BY_PAGINATION = 'containers/ReportsProvider/GET_REPORTS_BY_PAGINATION';
export const EXPORT_REPORTS_TO_EXCEL = 'containers/ReportsProvider/EXPORT_REPORTS_TO_EXCEL';
export const GET_REPORTS_PRODUCT_DETAILS = 'containers/ReportsProvider/GET_REPORTS_PRODUCT_DETAILS';
export const GET_REPORTS_BY_SORTING = 'containers/ReportsProvider/GET_REPORTS_BY_SORTING';

export const GET_REPORTS_SESSIONS = 'containers/ReportsProvider/GET_REPORTS_SESSIONS';
export const GET_REPORTS_SESSIONS_PAGINATION = 'containers/ReportsProvider/GET_REPORTS_SESSIONS_PAGINATION';
export const GET_REPORTS_SESSIONS_DETAILS = 'containers/ReportsProvider/GET_REPORTS_SESSIONS_DETAILS';
export const EXPORT_REPORTS_TO_EXCEL_DETAILS = 'containers/ReportsProvider/EXPORT_REPORTS_TO_EXCEL_DETAILS';
export const GET_REPORTS_SESSIONS_DETAILS_PAGINATION =
  'containers/ReportsProvider/GET_REPORTS_SESSIONS_DETAILS_PAGINATION';
export const GET_REPORTS_SESSIONS_DETAILS_ALL = 'containers/ReportsProvider/GET_REPORTS_SESSIONS_DETAILS_ALL';
