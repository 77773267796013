import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getMenuPreview, getMenuCategoryProducts, getMenuProductDetails, exportMenu } from './actions';
import {
  selectMenuPreview,
  selectMenuCategoriesPreview,
  selectMenuProductsDetailsPreview,
  selectMenuPreviewFetching,
  selectMenuPreviewFetched,
  selectMenuPreviewError,
  selectMenuCategoryProductsFetching,
  selectMenuCategoryProductsFetched,
  selectMenuCategoryProductsError,
  selectMenuProductDetailsFetching,
  selectMenuProductDetailsFetched,
  selectMenuProductDetailsError,
  selectExportMenuState,
} from './selectors';

export function withMenuMakerPreviewHoc(Component) {
  function withMenuMakerPreview(props) {
    return <Component {...props} />;
  }

  withMenuMakerPreview.displayName = `withMenuMakerPreview(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withMenuMakerPreview;
}

const mapStateToProps = createStructuredSelector({
  menu: selectMenuPreview,
  menuCategories: selectMenuCategoriesPreview,
  menuProductsDetails: selectMenuProductsDetailsPreview,
  menuFetching: selectMenuPreviewFetching,
  menuFetched: selectMenuPreviewFetched,
  menuError: selectMenuPreviewError,
  categoryProductsFetching: selectMenuCategoryProductsFetching,
  categoryProductsFetched: selectMenuCategoryProductsFetched,
  categoryProductsError: selectMenuCategoryProductsError,
  productsDetailsFetching: selectMenuProductDetailsFetching,
  productsDetailsFetched: selectMenuProductDetailsFetched,
  productsDetailsError: selectMenuProductDetailsError,
  exportMenuState: selectExportMenuState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadMenu: ({ menuUuid, storeUuid }) => dispatch(getMenuPreview({ menuUuid, storeUuid })),
    loadCategoryProducts: ({ menuUuid, storeUuid, categoryUuid }) =>
      dispatch(getMenuCategoryProducts({ menuUuid, storeUuid, categoryUuid })),
    loadProductsDetails: ({ menuUuid, storeUuid, productUuid }) =>
      dispatch(getMenuProductDetails({ menuUuid, storeUuid, productUuid })),
    exportMenu: (values) => dispatch(exportMenu(values)),
    resetMenuPreview: () => dispatch(getMenuPreview.reset()),
    resetCategoryProducts: () => dispatch(getMenuCategoryProducts.reset()),
    resetProductsDetails: () => dispatch(getMenuProductDetails.reset()),
    resetExportMenu: () => dispatch(exportMenu.reset()),
  };
}

export const withMenuMakerPreviewState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withMenuMakerPreviewState, withMenuMakerPreviewHoc);
