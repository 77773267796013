export const GET_INVOICES_ORDERS_EDIT = 'providers/InvoicesTicketsEditorProvider/GET_INVOICES_ORDERS_EDIT';
export const GET_INVOICES_ORDERS_EDIT_BY_PAGINATION =
  'providers/InvoicesTicketsEditorProvider/GET_INVOICES_ORDERS_EDIT_BY_PAGINATION';
export const GET_INVOICES_ORDERS_PRE_EDIT = 'providers/InvoicesTicketsEditorProvider/GET_INVOICES_ORDERS_PRE_EDIT';
export const UPDATE_INVOICES_ORDERS = 'providers/InvoicesTicketsEditorProvider/UPDATE_INVOICES_ORDERS';
export const RESET_EDITED_INVOICES_ORDERS = 'providers/InvoicesTicketsEditorProvider/RESET_EDITED_INVOICES_ORDERS';
export const GENERATE_INVOICING = 'providers/InvoicesTicketsEditorProvider/GENERATE_INVOICING';

export const GET_INVOICES_ORDER_DETAILS_EDIT =
  'providers/InvoicesTicketsEditorProvider/GET_INVOICES_ORDER_DETAILS_EDIT';
export const UPDATE_ORDER_REMOVE_ITEM = 'providers/InvoicesTicketsEditorProvider/UPDATE_ORDER_REMOVE_ITEM';
export const UPDATE_ORDER_DISCOUNT = 'providers/InvoicesTicketsEditorProvider/UPDATE_ORDER_DISCOUNT';
export const REVERT_CHANGES_ORDER_EDITED = 'providers/InvoicesTicketsEditorProvider/REVERT_CHANGES_ORDER_EDITED';
export const SAVE_CHANGES_ORDER_EDITED = 'providers/InvoicesTicketsEditorProvider/SAVE_CHANGES_ORDER_EDITED';
