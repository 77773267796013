import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormHelperText, OutlinedInput } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withRequiredLicense } from 'containers/AuthProvider';
import { withProduct } from 'containers/ProductProvider';

import PopupAlert from 'components/PopupAlert';

import useUserStore from 'hooks/useUserStore';

import useStyles from './styles';

const ProductDrawer = ({
  handleCreateProduct,
  createProductInstance,
  createProductError,
  createProductCreated,
  createProductCreating,
  resetCreateProduct,
  newProductName,
  open,
  handleAfterSave,
  handleOnClose,
  t,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, reset, errors } = useForm();
  const { storeId: storeUuid } = useUserStore();
  useEffect(() => {
    if (createProductCreated) {
      handleAfterSave(createProductInstance);
      resetCreateProduct();
      reset();
      handleOnClose();
    }
  }, [createProductCreated, createProductInstance, handleAfterSave, handleOnClose, reset, resetCreateProduct]);
  const handleSaveClick = (product) => {
    handleCreateProduct({ storeUuid, product });
  };
  return (
    <Drawer anchor="right" open={open}>
      <form className={classes.form} onSubmit={handleSubmit(handleSaveClick)}>
        <Box className={classes.formContent}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Typography gutterBottom variant="h6">
                {t('menuMaker:productForm.title.new')}
              </Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right' }} xs={2}>
              <IconButton aria-label="drag" onClick={handleOnClose} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <br />
          <Box component="div" display="block">
            <TextField
              className={classes.input}
              defaultValue={newProductName}
              error={Boolean(errors.name)}
              fullWidth
              helperText={t(errors.name?.message)}
              InputProps={{ name: 'name' }}
              inputRef={register({
                required: t('common:errors.requiredField'),
              })}
              label={t('menuMaker:productForm.namePlaceholder')}
              size="small"
              variant="standard"
            />
          </Box>
          <Box component="div" display="block">
            <Typography className={classes.label} variant="h6">
              {t('menuMaker:productForm.descriptionLabel')}
            </Typography>
          </Box>
          <Box component="div" display="block">
            <TextField
              className={classes.input}
              InputProps={{ name: 'description' }}
              inputRef={register()}
              variant="outlined"
            />
          </Box>
          <Box component="div" display="block">
            <Typography className={classes.label} variant="h6">
              {t('menuMaker:productForm.price.label')}
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  className={classes.inputPrice}
                  error={Boolean(errors.price)}
                  inputProps={{ name: 'price' }}
                  inputRef={register({
                    required: t('common:errors.requiredField'),
                  })}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  type="number"
                  variant="outlined"
                />
                <FormHelperText>{t(errors.price?.message)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={8} />
          </Grid>
        </Box>
        <br />
        <Box className={classes.saveContainer} component="div" display="block">
          {createProductCreating && <CircularProgress className={classes.saveButtonProgress} size={35} />}
          {!createProductCreating && (
            <Button
              className={classes.saveButton}
              color="primary"
              disabled={createProductCreating}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('menuMaker:productForm.saveButtonLabel')}
            </Button>
          )}
        </Box>
      </form>
      <PopupAlert message="Saved!" severity="success" shouldDisplay={createProductCreated} />
      <PopupAlert message="Error!" severity="error" shouldDisplay={createProductError} />
    </Drawer>
  );
};

ProductDrawer.propTypes = {
  // Create Product
  handleCreateProduct: PropTypes.func,
  createProductInstance: PropTypes.object,
  createProductError: PropTypes.bool,
  createProductCreated: PropTypes.bool,
  createProductCreating: PropTypes.bool,
  resetCreateProduct: PropTypes.func,
  // Properties
  handleOnClose: PropTypes.func,
  handleAfterSave: PropTypes.func,
  newProductName: PropTypes.string,
  open: PropTypes.bool,
  t: PropTypes.func,
};
export default compose(memo, withRequiredLicense(), withProduct, withTranslation('menuMaker'))(ProductDrawer);
