import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectRolesProviderDomain = (state) => state.rolesProvider || initialState;

// Get Roles
const selectRoles = createSelector(selectRolesProviderDomain, (rolesState) => rolesState.getRoles.list);
const selectRolesError = createSelector(selectRolesProviderDomain, (rolesState) => rolesState.getRoles.error);
const selectRolesIsFetching = createSelector(selectRolesProviderDomain, (rolesState) => rolesState.getRoles.isFetching);
const selectRolesDidFetch = createSelector(selectRolesProviderDomain, (rolesState) => rolesState.getRoles.didFetch);

// Duplicate Role
const selectDuplicateRole = createSelector(
  selectRolesProviderDomain,
  (rolesState) => rolesState.duplicateRole.newInstance,
);
const selectDuplicateRoleError = createSelector(
  selectRolesProviderDomain,
  (rolesState) => rolesState.duplicateRole.error,
);
const selectDuplicateRoleIsFetching = createSelector(
  selectRolesProviderDomain,
  (rolesState) => rolesState.duplicateRole.isFetching,
);
const selectDuplicateRoleDidFetch = createSelector(
  selectRolesProviderDomain,
  (rolesState) => rolesState.duplicateRole.didFetch,
);

// Delete Role
const selectDeleteRoleError = createSelector(selectRolesProviderDomain, (rolesState) => rolesState.deleteRole.error);
const selectDeleteRoleIsFetching = createSelector(
  selectRolesProviderDomain,
  (rolesState) => rolesState.deleteRole.isFetching,
);
const selectDeleteRoleDidFetch = createSelector(
  selectRolesProviderDomain,
  (rolesState) => rolesState.deleteRole.didFetch,
);

// permissions by roles
const selectPermissionsByRoles = createSelector(
  selectRolesProviderDomain,
  (subState) => subState.getPermissionsByRoles.permissions,
);
const selectPermissionsByRolesState = createSelector(
  selectRolesProviderDomain,
  ({ getPermissionsByRoles: { fetching, fetched, error } }) => ({
    fetching,
    fetched,
    error,
  }),
);

export {
  selectRolesProviderDomain,
  // Get Roles
  selectRoles,
  selectRolesError,
  selectRolesIsFetching,
  selectRolesDidFetch,
  // Duplicate Role
  selectDuplicateRole,
  selectDuplicateRoleError,
  selectDuplicateRoleIsFetching,
  selectDuplicateRoleDidFetch,
  // Delete Role
  selectDeleteRoleError,
  selectDeleteRoleIsFetching,
  selectDeleteRoleDidFetch,
  // permissions by roles
  selectPermissionsByRoles,
  selectPermissionsByRolesState,
};
