import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_REPORTS_PAYMENTS,
  GET_REPORTS_PAYMENTS_BY_PAGINATION,
  GET_REPORTS_PAYMENTS_BY_SORTING,
  EXPORT_REPORTS_PAYMENTS,
} from './constants';

export const getReportsPayments = extendRoutine(createRoutine(GET_REPORTS_PAYMENTS), 'RESET');
export const getReportsPaymentsByPagination = extendRoutine(createRoutine(GET_REPORTS_PAYMENTS_BY_PAGINATION), 'RESET');
export const getReportsPaymentsBySorting = extendRoutine(createRoutine(GET_REPORTS_PAYMENTS_BY_SORTING), [
  'RESET',
  'SORTING',
  'RESET_SORTING',
]);
export const exportReportsPayments = extendRoutine(createRoutine(EXPORT_REPORTS_PAYMENTS), 'RESET');
