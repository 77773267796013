import { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@design-system/Checkbox';
import IconButton from '@design-system/IconButton';

import Help from '@experimental-components/IconsComponents/Help';
import Find from '@experimental-components/IconsComponents/Find';
import SelectKitchenAreas from '@experimental-components/SelectKitchenAreas';

import { Controller, useWatch } from 'react-hook-form';

import arrayToObjectUuid from 'utils/arrayToObjectUuid';
import { getKitchenAreasDetail } from 'utils/kitchenAreas/makeKitchenAreasFields';

import ToolTip from '../ToolTip';

import useStyles from './styles';

function KitchenAreasAsigned({ register, canSelectOtherArea, control, kitchenAreas, loading, onChangeCanSelectAreas }) {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation('kitchenAreas');
  const [anchorEl, setAnchorEl] = useState(null);
  const [toolTipMessage, setToolTipMessage] = useState('');

  const kitchenAreasFieldsCanPrint = useWatch({
    control,
    name: 'kitchenAreasFieldsCanPrint',
  });

  const kitchenAreasFields = useWatch({
    control,
    name: 'kitchenAreasFields',
  });

  const kitchenNames = getKitchenAreasDetail(kitchenAreasFields, kitchenAreas)
    .map((ka) => ka?.name)
    .join(', ');

  const labelText = canSelectOtherArea
    ? t('kitchenAreas:messages.kitchenAreasPrintingOverride', { kitchenAreas: kitchenNames })
    : t('kitchenAreas:messages.kitchenAreasPrinting', { kitchenAreas: kitchenNames });

  const handleSetAnchorEl = (msg) => (event) => {
    setAnchorEl(event.currentTarget);
    setToolTipMessage(msg);
  };

  function handleSetAnchorElClose() {
    setAnchorEl(null);
  }

  function renderKitchenAreasSelectItem(selectedState) {
    if (selectedState?.length > 0) {
      return selectedState
        ?.filter((uuid) => arrayToObjectUuid(kitchenAreas)[uuid]?.name)
        .map((uuid) => arrayToObjectUuid(kitchenAreas)[uuid]?.name)
        .join(', ');
    }

    return (
      <Box color={theme.palette.darkGrey[50]} component="span">
        {t('kitchenAreas:messages.selectArea')}
      </Box>
    );
  }

  return (
    <Box mb={6}>
      <Box pl={4} pr={4}>
        <Box>
          <Box fontSize={18} fontWeight="fontWeightMedium">
            {t('kitchenAreas:headerDetails.kitchenArea')}
          </Box>

          <Box className={classes.label} mt={1}>
            {labelText}
          </Box>
        </Box>
        <Box
          css={{
            '& > :not(:first-child)': {
              marginTop: theme.spacing(3),
            },
          }}
          mt={5}
        >
          <Box
            alignItems="center"
            css={{
              '& > :not(:first-child)': {
                marginLeft: theme.spacing(5),
              },
            }}
            display="flex"
          >
            <Controller
              control={control}
              name="kitchenAreasFields"
              render={({ onChange, value, name }) => (
                <SelectKitchenAreas
                  displayEmpty
                  // inputRef={ref}
                  isDisabled={loading || kitchenAreasFieldsCanPrint || !canSelectOtherArea}
                  isFullWidth
                  multiple
                  name={name}
                  onChange={onChange}
                  renderValue={(selected) => renderKitchenAreasSelectItem(selected)}
                  value={value}
                >
                  {kitchenAreas?.map((kitchenA) => (
                    <MenuItem key={kitchenA?.uuid} value={kitchenA?.uuid}>
                      <Checkbox checked={value?.indexOf(kitchenA?.uuid) > -1} />
                      <ListItemText primary={kitchenA?.name} />
                    </MenuItem>
                  ))}
                </SelectKitchenAreas>
              )}
              rules={{ required: true }}
            />
            <Box
              alignItems="center"
              css={{
                '& > :not(:first-child)': {
                  marginLeft: theme.spacing(3),
                },
              }}
              display="flex"
            >
              <Checkbox
                defaultChecked={canSelectOtherArea}
                disabled={loading}
                inputRef={register()}
                label={t('kitchenAreas:messages.selectOtherArea')}
                name="canSelectOtherAreaField"
                onChange={onChangeCanSelectAreas}
              />

              <Box>
                <IconButton onClick={handleSetAnchorEl('kitchenAreas:messages.selectOtherAreaToolTip')}>
                  <Help />
                </IconButton>
              </Box>
            </Box>
            <Box
              alignItems="center"
              css={{
                '& > :not(:first-child)': {
                  marginLeft: theme.spacing(3),
                },
              }}
              display="flex"
            >
              <Checkbox
                defaultChecked={kitchenAreasFieldsCanPrint}
                disabled={loading}
                inputRef={register()}
                label={t('kitchenAreas:messages.notPrint')}
                name="kitchenAreasFieldsCanPrint"
              />

              <Box>
                <IconButton onClick={handleSetAnchorEl('kitchenAreas:messages.notPrintToolTip')}>
                  <Help />
                </IconButton>
              </Box>
            </Box>
          </Box>

          {kitchenAreas.length === 0 && (
            <Box alignItems="left" display="flex" flexDirection="column" mb={8} mt={10}>
              <Box maxWidth={theme.typography.pxToRem(220)} textAlign="center" width="100%">
                <Find />
                <Box className={classes.label} mt={2} width="100%">
                  {t('common:messages.emptyInformation')}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <ToolTip anchorEl={anchorEl} message={toolTipMessage} onClose={handleSetAnchorElClose} />
    </Box>
  );
}

KitchenAreasAsigned.propTypes = {
  kitchenAreas: PropTypes.array,
  loading: PropTypes.bool,
  register: PropTypes.func,
  control: PropTypes.object,
  canSelectOtherArea: PropTypes.bool,
  onChangeCanSelectAreas: PropTypes.func,
};

export default KitchenAreasAsigned;
