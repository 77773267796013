import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectPinProviderDomain = (state) => state.pinProvider || initialState;

const selectValidatePinFetching = createSelector(selectPinProviderDomain, (subState) => subState.validatePin.fetching);
const selectValidatePinFetched = createSelector(selectPinProviderDomain, (subState) => subState.validatePin.fetched);
const selectValidatePinError = createSelector(selectPinProviderDomain, (subState) => subState.validatePin.error);

const selectGeneratePosPin = createSelector(selectPinProviderDomain, (subState) => subState.generatePosPin.posPin);
const selectGeneratePosPinFetching = createSelector(
  selectPinProviderDomain,
  (subState) => subState.generatePosPin.fetching,
);
const selectGeneratePosPinFetched = createSelector(
  selectPinProviderDomain,
  (subState) => subState.generatePosPin.fetched,
);
const selectGeneratePosPinError = createSelector(selectPinProviderDomain, (subState) => subState.generatePosPin.error);

export {
  selectPinProviderDomain,
  selectValidatePinFetching,
  selectValidatePinFetched,
  selectValidatePinError,
  selectGeneratePosPin,
  selectGeneratePosPinFetching,
  selectGeneratePosPinFetched,
  selectGeneratePosPinError,
};
