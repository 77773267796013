import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { routerActions } from 'connected-react-router';

import { selectIsAuthenticated } from 'containers/AuthProvider/selectors';

import AppFrame from 'components/AppFrame';

import AppProviders from './Providers';

/* istanbul ignore next */
const redirectWrapper = connectedReduxRedirect({
  redirectPath: '/signin',
  authenticatedSelector: (state) => selectIsAuthenticated(state),
  allowRedirectBack: true,
  redirectAction: routerActions.replace,
  redirectQueryParamName: 'continue',
  wrapperDisplayName: 'appRouteAuthWrapper',
});

export function AppRoute({ chrome, ...other }) {
  const route = <Route {...other} />;

  if (chrome) {
    return (
      <AppProviders>
        <AppFrame>{route}</AppFrame>
      </AppProviders>
    );
  }

  return <AppProviders>{route}</AppProviders>;
}

AppRoute.propTypes = {
  chrome: PropTypes.bool,
};

AppRoute.defaultProps = {
  chrome: true,
};

export default redirectWrapper(AppRoute);
