import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';

import Layout, { ContentLayout } from 'Layout';

import { withKitchenAreas } from 'providers/KitchenAreasProvider';
import { withRequiredLicense } from 'containers/AuthProvider';
import { withProducts } from 'containers/ProductsProvider';
import { withCategories } from 'containers/CategoriesProvider';
import { withModifierGroups } from 'containers/ModifierGroupsProvider';
import { withProduct } from 'containers/ProductProvider';
import { editProduct } from 'containers/Product/actions';
import { selectProductEditFetched } from 'containers/Product/selectors';

import useNotification from '@design-system/Notification/useNotification';
import PageError from 'components/PageError';
import useUserStore from 'hooks/useUserStore';
import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import HeaderForm from 'components/HeaderForm';
import ProductFormSkeleton from 'components/ProductFormSkeleton';

import useSortingProducts from 'hooks/useSortingProducts';
import usePaginationProducts from 'hooks/usePaginationProducts';

import { statusType } from 'utils/products';
import { getEnabledKitchenAreas } from 'utils/kitchenAreas/makeKitchenAreasFields';

import AddProductForm from './AddProductForm';
import ProductsTableWrapper from './ProductsTableWrapper';

export function Products({
  categoriesMenusList,
  loadProducts,
  products,
  categories,
  productsFetching,
  productsError,
  resetProducts,
  loadCategories,
  categoriesList,
  categoriesFetched,
  categoriesFetching,
  loadModifierGroups,
  modifierGroupsList,
  modifierGroupsFetched,
  modifierGroupsFetching,
  createProduct,
  createProductFetching,
  createProductFetched,
  createProductError,
  resetCreateProduct,
  handleToggleProductAvailability,
  toggleProductAvailabilityFetching,
  toggleProductAvailabilityFetched,
  toggleProductAvailabilityError,
  resetToggleProductAvailability,
  handleDeleteProduct,
  deleteProductFetching,
  deleteProductFetched,
  deleteProductError,
  resetDeleteProduct,
  updateProductImage,
  updateProductImageFetching,
  updateProductImageFetched,
  updateProductImageError,
  location,
  loadKitchenAreas,
  kitchenAreas,
  kitchenAreasState,
  resetUpdateProductImage,
  t,
}) {
  const history = useHistory();
  const [typeOfProductAvailability, setTypeOfProductAvailability] = useState(null);
  const { storeId } = useUserStore();
  const { setSortingByFilters, resetSorting, filtersSorting } = useSortingProducts();
  const { resetPagination } = usePaginationProducts();
  const { closeAllSnackbar, setNotification } = useNotification();

  useEffect(() => {
    loadProducts({ page: 1 });
  }, [loadProducts]);

  useEffect(() => {
    if (location.pathname === '/menus/products/create') {
      loadKitchenAreas();
      loadCategories({ storeUuid: storeId, allCategories: true });
      loadModifierGroups({ storeUuid: storeId, allModifiers: true });
    }
  }, [loadCategories, loadKitchenAreas, loadModifierGroups, location.pathname, storeId]);

  useEffect(() => {
    if (createProductFetched) {
      setNotification(t('menuMaker:productForm.messages.saveSuccess'), { variant: 'success' });
      history.push({
        pathname: '/menus/products',
      });
    }

    if (createProductError) {
      setNotification(t('menuMaker:productForm.messages.saveError'), { variant: 'error' });
    }
  }, [createProductFetched, createProductError, history, setNotification, t]);

  useEffect(() => {
    if (deleteProductFetched) {
      setNotification(t('menuMaker:productForm.messages.deleteSuccess'), { variant: 'success' });
    }

    if (deleteProductError) {
      setNotification(t('menuMaker:productForm.messages.deleteError'), { variant: 'error' });
    }
  }, [deleteProductError, deleteProductFetched, setNotification, t]);

  useEffect(() => {
    if (updateProductImageFetched) {
      setNotification(t('menuMaker:productUpdateImage.updateImageSuccess'), { variant: 'success' });
    }

    if (updateProductImageError) {
      setNotification(t('menuMaker:productUpdateImage.updateImageError'), { variant: 'error' });
    }
  }, [setNotification, t, updateProductImageError, updateProductImageFetched]);

  function handleSortingByTextProducts(values) {
    setSortingByFilters({ ...values });
  }

  function handleSortingByCategories(values) {
    setSortingByFilters({ ...values });
  }

  function handelSortingByTypeSold(values) {
    setSortingByFilters({ ...values });
  }

  function handleSortingByBrands(values) {
    setSortingByFilters({ ...values });
  }

  function handleCreateNewProduct() {
    resetCreateProduct();
    resetPagination();
    resetSorting();

    history.push(`/menus/products/create`);
  }

  function handleLoadProducts() {
    loadProducts({ page: 1 });
  }

  function hanldeUpdateProductImage(productUuid, image) {
    updateProductImage({ storeUuid: storeId, productUuid, image });
  }

  function handleToggleProductStock(productParams) {
    setTypeOfProductAvailability(productParams.availability);
    handleToggleProductAvailability(productParams);
  }

  function handleClearActions() {
    resetCreateProduct();
    resetPagination();
    resetSorting();
  }

  useEffect(() => {
    if (toggleProductAvailabilityFetched && typeOfProductAvailability === statusType.STATUS_UNAVAILABLE) {
      setNotification(t('menuMaker:messages.inStockHidedSuccess'), { variant: 'success' });
    } else if (toggleProductAvailabilityFetched && typeOfProductAvailability === statusType.STATUS_AVAILABLE) {
      setNotification(t('menuMaker:messages.inStockIncludedSuccess'), { variant: 'success' });
    }

    if (toggleProductAvailabilityFetched || toggleProductAvailabilityError) {
      resetToggleProductAvailability();
    }
  }, [
    toggleProductAvailabilityFetched,
    toggleProductAvailabilityError,
    resetToggleProductAvailability,
    typeOfProductAvailability,
    setNotification,
    t,
  ]);

  useEffect(() => {
    return function cleanup() {
      resetProducts();
      resetCreateProduct();
      resetDeleteProduct();
      resetToggleProductAvailability();
      resetUpdateProductImage();
      closeAllSnackbar();
    };
  }, [
    resetProducts,
    resetCreateProduct,
    resetDeleteProduct,
    resetToggleProductAvailability,
    resetUpdateProductImage,
    closeAllSnackbar,
  ]);

  return (
    <Layout>
      <Route exact path="/menus/products">
        <ContentLayout>
          <ContentLayout.Header>
            <MenuMakerTabsNavigation />
          </ContentLayout.Header>

          <ContentLayout.Container pb="0" pl="0" pr="0">
            <HeaderForm
              addButtonLabel={t('menuMaker:buttons.newProduct')}
              categoriesList={categories}
              isShowCategories
              isShowFilterBrandsInFilters
              isShowFilterProducts
              onAddButtonClick={handleCreateNewProduct}
              onSearchBoxChange={handleSortingByTextProducts}
              onSelectBrandsChange={handleSortingByBrands}
              onSelectedCategoriesChange={handleSortingByCategories}
              onSelectTypeSoldProductChange={handelSortingByTypeSold}
              px={4}
              searchLabel={t('menuMaker:headerForm.searchProduct')}
            />

            {productsError && (
              <PageError
                id="menusError"
                labelAction={t('common:buttons.retry')}
                message={t('menuMaker:errors.loadedError')}
                onAction={handleLoadProducts}
              />
            )}

            {!productsError && (
              <Box mb={6}>
                <ProductsTableWrapper
                  categoriesByUuid={categories}
                  deleteProduct={handleDeleteProduct}
                  deleteProductError={Boolean(deleteProductError)}
                  deleteProductFetched={deleteProductFetched}
                  deleteProductLoading={deleteProductFetching}
                  filters={filtersSorting}
                  isLoading={productsFetching}
                  isSearchFilter={filtersSorting?.isSearchFilter}
                  loadingEnabledProduct={toggleProductAvailabilityFetching}
                  loadingUpdateProductImage={updateProductImageFetching}
                  onClearActions={handleClearActions}
                  onClearNotification={closeAllSnackbar}
                  onUpdateProductImage={hanldeUpdateProductImage}
                  products={products}
                  t={t}
                  toggleProductStock={handleToggleProductStock}
                />
              </Box>
            )}
          </ContentLayout.Container>
        </ContentLayout>
      </Route>

      <Route exact path="/menus/products/create">
        {(kitchenAreasState.fetching || categoriesFetching || modifierGroupsFetching) && (
          <ProductFormSkeleton pathGoBack="/menus/products" />
        )}

        {kitchenAreasState.fetched && modifierGroupsFetched && categoriesFetched && (
          <AddProductForm
            availableCategories={categoriesList}
            categoriesMenusList={categoriesMenusList}
            error={createProductError}
            kitchenAreas={getEnabledKitchenAreas(kitchenAreas)}
            loading={createProductFetching}
            modifierGroupsList={modifierGroupsList}
            saveProduct={createProduct}
          />
        )}
      </Route>
    </Layout>
  );
}

Products.propTypes = {
  categoriesMenusList: PropTypes.array,
  loadProducts: PropTypes.func,
  products: PropTypes.array,
  categories: PropTypes.object,
  productsFetching: PropTypes.bool,
  productsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetProducts: PropTypes.func,
  loadCategories: PropTypes.func,
  categoriesList: PropTypes.array,
  categoriesFetched: PropTypes.bool,
  loadModifierGroups: PropTypes.func,
  modifierGroupsList: PropTypes.array,
  modifierGroupsFetched: PropTypes.bool,
  createProduct: PropTypes.func,
  createProductFetching: PropTypes.bool,
  createProductFetched: PropTypes.bool,
  resetCreateProduct: PropTypes.func,
  createProductError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  handleToggleProductAvailability: PropTypes.func,
  toggleProductAvailabilityFetching: PropTypes.bool,
  toggleProductAvailabilityFetched: PropTypes.bool,
  toggleProductAvailabilityError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetToggleProductAvailability: PropTypes.func,
  handleDeleteProduct: PropTypes.func,
  deleteProductFetching: PropTypes.bool,
  deleteProductFetched: PropTypes.bool,
  deleteProductError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetDeleteProduct: PropTypes.func,
  updateProductImage: PropTypes.func,
  updateProductImageFetching: PropTypes.bool,
  updateProductImageFetched: PropTypes.bool,
  updateProductImageError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetUpdateProductImage: PropTypes.func,
  location: PropTypes.object,
  loadKitchenAreas: PropTypes.func,
  kitchenAreas: PropTypes.array,
  kitchenAreasState: PropTypes.object,
  categoriesFetching: PropTypes.bool,
  modifierGroupsFetching: PropTypes.bool,
  t: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  productEditFetched: selectProductEditFetched,
});

export function mapDispatchToProps(dispatch) {
  return {
    resetEditProduct: () => dispatch(editProduct.reset()),
  };
}

export const productState = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  memo,
  withRequiredLicense(),
  productState,
  withProduct,
  withCategories,
  withModifierGroups,
  withProducts,
  withKitchenAreas,
  withTranslation('menuMaker'),
)(Products);
