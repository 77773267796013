import produce from 'immer';

import baseState from 'utils/baseState';
import { sortingDirection } from 'utils/reports/tables';

import {
  getReportsPayments,
  getReportsPaymentsByPagination,
  getReportsPaymentsBySorting,
  exportReportsPayments,
} from './actions';

export const initialState = {
  getReportsPayments: {
    ...baseState,
    summaries: [],
    headers: [],
    payments: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },
  loadPaginationStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },

  sortingStatus: {
    ...baseState,
    columnSelected: 0,
    columnDirection: sortingDirection.ASC,
    lastSortingRows: {},
  },

  exportReportsPayments: {
    ...baseState,
    document: null,
  },
};

const reportsPaymentsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsPayments.REQUEST:
        draft.getReportsPayments = {
          ...initialState.getReportsPayments,
          fetching: true,
        };
        break;

      case getReportsPayments.SUCCESS:
        draft.getReportsPayments.fetching = false;
        draft.getReportsPayments.fetched = true;
        draft.getReportsPayments.summaries = payload.summaries;
        draft.getReportsPayments.headers = payload.headers;
        draft.getReportsPayments.payments = payload.rows;
        draft.getReportsPayments.pagination = {
          ...initialState.getReportsPayments.pagination,
          ...payload.pagination,
        };
        break;

      case getReportsPayments.FAILURE:
        draft.getReportsPayments.error = payload;
        draft.getReportsPayments.fetching = false;
        draft.getReportsPayments.fetched = false;
        break;

      case getReportsPayments.RESET:
        draft.getReportsPayments = {
          ...initialState.getReportsPayments,
        };
        break;

      // pagination
      case getReportsPaymentsByPagination.REQUEST:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: true,
        };
        break;

      case getReportsPaymentsByPagination.SUCCESS: {
        const currentReportsPaymentsPaginationState = draft.getReportsPayments;
        let newsRows = [...currentReportsPaymentsPaginationState?.payments];
        newsRows = [...newsRows, ...payload?.payments?.rows];

        draft.getReportsPayments = {
          ...currentReportsPaymentsPaginationState,
          payments: [...newsRows],
          pagination: { ...currentReportsPaymentsPaginationState?.pagination, ...payload.pagination },
        };

        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.payments?.rows],
        };
        break;
      }

      case getReportsPaymentsByPagination.FAILURE:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getReportsPaymentsByPagination.RESET:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
        };
        break;

      // sorting
      case getReportsPaymentsBySorting.REQUEST: {
        const currentReportsPaymentsSortingState = draft.getReportsPayments;
        draft.getReportsPayments = {
          ...currentReportsPaymentsSortingState,
          pagination: {
            ...initialState.getReportsPayments.pagination,
          },
        };

        draft.sortingStatus = {
          ...draft.sortingStatus,
          fetching: true,
          fetched: false,
          error: null,
        };

        break;
      }

      case getReportsPaymentsBySorting.SUCCESS: {
        const currentReportsPaymentsSortingState = draft.getReportsPayments;
        draft.getReportsPayments = {
          ...currentReportsPaymentsSortingState,
          payments: payload?.payments?.rows,
          pagination: { ...currentReportsPaymentsSortingState.pagination, ...payload?.pagination },
        };

        draft.sortingStatus = {
          ...draft.sortingStatus,
          fetching: false,
          fetched: true,
          lastSortingRows: payload?.payments?.rows,
        };
        break;
      }

      case getReportsPaymentsBySorting.SORTING:
        draft.sortingStatus = {
          ...draft.sortingStatus,
          fetched: false,
          columnSelected: payload.selected,
          columnDirection: payload.direction,
        };
        break;

      case getReportsPaymentsBySorting.RESET_SORTING:
        draft.sortingStatus = {
          ...initialState.sortingStatus,
        };
        break;

      case getReportsPaymentsBySorting.FAILURE:
        draft.sortingStatus = {
          ...draft.sortingStatus,
          error: payload,
          fetching: false,
          fetched: false,
        };

        break;

      case getReportsPaymentsBySorting.RESET:
        draft.sortingStatus = {
          ...initialState.sortingStatus,
        };
        break;

      // export file excel
      case exportReportsPayments.REQUEST:
        draft.exportReportsPayments = {
          ...initialState.exportReportsPayments,
          fetching: true,
        };
        break;

      case exportReportsPayments.SUCCESS:
        draft.exportReportsPayments.fetching = false;
        draft.exportReportsPayments.fetched = true;
        draft.exportReportsPayments.document = payload;

        break;

      case exportReportsPayments.FAILURE:
        draft.exportReportsPayments.error = payload;
        draft.exportReportsPayments.fetching = false;
        draft.exportReportsPayments.fetched = false;
        break;

      case exportReportsPayments.RESET:
        draft.exportReportsPayments = {
          ...initialState.exportReportsPayments,
        };
        break;

      default:
        return draft;
    }
  });

export default reportsPaymentsReducer;
