import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import roleSaga from './sagas';
import reducer from './reducer';

export function RoleProvider({ children }) {
  useInjectSaga({ key: 'roleProvider', saga: roleSaga });
  useInjectReducer({ key: 'roleProvider', reducer });

  return React.Children.only(children);
}

RoleProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withRole } from './withRole';
export default compose(withConnect, memo)(RoleProvider);
