import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import ButtonBase from '@material-ui/core/ButtonBase';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useAuth } from 'containers/AuthProvider';
import useAmplitude from 'providers/AmplitudeProvider/useAmplitude';

import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import useUserStore from 'hooks/useUserStore';
import useEnvironment from 'hooks/useEnvironment';

import MenuItem from './MenuItem';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: (props) => (props.isTestEnv ? 'rgba(0,0,0,0.2)' : '#262626'),
    padding: '0.625rem 1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: '100%',
    minHeight: theme.typography.pxToRem(56),

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },

  iconOpen: {
    transform: 'rotate(180deg)',
  },
}));

function MenuStores() {
  const { isTestEnv } = useEnvironment();

  const theme = useTheme();
  const classes = useStyles({ isTestEnv });
  const history = useHistory();
  const { logEvent } = useAmplitude();

  const [isOpen, setIsOpen] = useState(false);
  const { storeState, setStore } = useUserStore();
  const { loadGetUser, loadLicenseState } = useAuth();

  const isShowListStores = storeState?.stores?.length > 1;

  const handleOpenMenu = () => {
    setIsOpen((open) => !open);
  };

  const handleSelectedItem = (store) => () => {
    logEvent('@event.$store.storeChanged');
    setStore(store);
    setIsOpen(false);
    loadGetUser();
    loadLicenseState();
    // This solution is temporary
    history.replace('/');
  };

  return (
    <Box>
      <ButtonBase
        classes={{ root: classes.buttonRoot }}
        disabled={!isShowListStores}
        disableRipple
        onClick={handleOpenMenu}
      >
        <Box component="span" fontSize={theme.typography.pxToRem(16)} textAlign="left">
          {storeState.selected?.name}
        </Box>

        {isShowListStores && <ChevronDown className={clsx({ [classes.iconOpen]: isOpen })} />}
      </ButtonBase>

      {isShowListStores && (
        <Fade in={isOpen}>
          <Box
            bgcolor={isTestEnv ? 'rgba(0,0,0,0.2)' : '#262626'}
            color="#fff"
            mt={theme.typography.pxToRem(8)}
            position="absolute"
            width="100%"
            zIndex={theme.zIndex.modal}
          >
            <Box component="ul" css={{ listStyle: 'none' }} p="0.5rem 0">
              {storeState?.stores?.map((store) => {
                if (storeState?.selected?.uuid !== store?.uuid) {
                  return (
                    <MenuItem
                      key={store?.uuid}
                      onClick={handleSelectedItem(store)}
                      selected={storeState.selected?.uuid === store?.uuid}
                    >
                      {store?.name}
                    </MenuItem>
                  );
                }

                return null;
              })}
            </Box>
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default MenuStores;
