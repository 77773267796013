import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectReportsResumeProviderDomain = (state) => state.reportsResumeProvider || initialState;

const selectReportsResumeSummaries = createSelector(
  selectReportsResumeProviderDomain,
  (subState) => subState.getReportsResumeSummaries.summaries,
);

const selectReportsResumeSummariesState = createSelector(
  selectReportsResumeProviderDomain,
  ({ getReportsResumeSummaries: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsResumeSalesChannels = createSelector(
  selectReportsResumeProviderDomain,
  (subState) => subState.getReportsResumeSalesChannels.salesChannels,
);

const selectReportsResumeSalesChannelsState = createSelector(
  selectReportsResumeProviderDomain,
  ({ getReportsResumeSalesChannels: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsResumeTopProducts = createSelector(
  selectReportsResumeProviderDomain,
  (subState) => subState.getReportsResumeTopProducts.topProducts,
);

const selectReportsResumeTopProductsState = createSelector(
  selectReportsResumeProviderDomain,
  ({ getReportsResumeTopProducts: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsResumeSalesBrands = createSelector(
  selectReportsResumeProviderDomain,
  (subState) => subState.getReportsResumeSalesBrands.salesBrands,
);

const selectReportsResumeSalesBrandsState = createSelector(
  selectReportsResumeProviderDomain,
  ({ getReportsResumeSalesBrands: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsResumeSalesCategories = createSelector(
  selectReportsResumeProviderDomain,
  (subState) => subState.getReportsResumeSalesCategories.salesCategories,
);

const selectReportsResumeSalesCategoriesState = createSelector(
  selectReportsResumeProviderDomain,
  ({ getReportsResumeSalesCategories: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectReportsResumeSalesPeriod = createSelector(
  selectReportsResumeProviderDomain,
  (subState) => subState.getReportsResumeSalesPeriod.salesPeriod,
);

const selectReportsResumeSalesPeriodState = createSelector(
  selectReportsResumeProviderDomain,
  ({ getReportsResumeSalesPeriod: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectReportsResumeProviderDomain,
  selectReportsResumeSummaries,
  selectReportsResumeSummariesState,
  selectReportsResumeSalesChannels,
  selectReportsResumeSalesChannelsState,
  selectReportsResumeTopProducts,
  selectReportsResumeTopProductsState,
  selectReportsResumeSalesBrands,
  selectReportsResumeSalesBrandsState,
  selectReportsResumeSalesCategories,
  selectReportsResumeSalesCategoriesState,
  selectReportsResumeSalesPeriod,
  selectReportsResumeSalesPeriodState,
};
