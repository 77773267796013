/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider selectors
 *
 */
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

import { groupTablesByServiceAreas } from 'utils/settings/tables';

import { initialState } from './reducer';

const selectSettingsAdminTablesProviderDomain = (state) => state.settingsAdminTablesProvider || initialState;

const selectCreateTableState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ createTable: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectGetTables = createSelector(selectSettingsAdminTablesProviderDomain, ({ getTables }) => {
  return sortBy(getTables.tables, [
    (table) => {
      return table?.name?.toLowerCase();
    },
  ]);
});

const selectTablesGroupByServiceArea = createSelector(selectSettingsAdminTablesProviderDomain, ({ getTables }) => {
  return groupTablesByServiceAreas(getTables.tables);
});

const selectGetTablesState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ getTables: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectGetTableDetails = createSelector(
  selectSettingsAdminTablesProviderDomain,
  (subState) => subState.getTableDetails.table,
);

const selectGetTableDetailsState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ getTableDetails: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectUpdateTableState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ updateTable: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectDeleteTableState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ deleteTable: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectCreateTablesBulkState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ createTablesBulk: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectValidateNameState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  ({ validateName: { isNameRegistered } }) => ({
    isNameRegistered,
  }),
);

const selectTablesPaginationInfo = createSelector(
  selectSettingsAdminTablesProviderDomain,
  (subState) => subState.getTables.pagination,
);

const selectGetTablesPaginationScrollState = createSelector(
  selectSettingsAdminTablesProviderDomain,
  (subState) => subState.paginationGetTables,
);

export {
  selectSettingsAdminTablesProviderDomain,
  selectCreateTableState,
  selectGetTables,
  selectGetTableDetails,
  selectGetTableDetailsState,
  selectGetTablesState,
  selectUpdateTableState,
  selectDeleteTableState,
  selectCreateTablesBulkState,
  selectValidateNameState,
  selectTablesPaginationInfo,
  selectGetTablesPaginationScrollState,
  selectTablesGroupByServiceArea,
};
