import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

function SidebarLayoutSection({ children, css, ...others }) {
  const theme = useTheme();

  return (
    <Box
      component="ul"
      css={{
        listStyle: 'none',
        '& > :not(:first-child)': {
          marginTop: theme.spacing(3),
        },
        ...css,
      }}
      m={0}
      p={0}
      {...others}
    >
      {children}
    </Box>
  );
}

SidebarLayoutSection.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
};

export default SidebarLayoutSection;
