import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectProductProviderDomain = (state) => state.productProvider || initialState;

// Get Product
const selectGetProductInstance = createSelector(
  selectProductProviderDomain,
  (productState) => productState.getProduct.product,
);

const selectGetProductError = createSelector(
  selectProductProviderDomain,
  (productState) => productState.getProduct.error,
);

const selectGetProductFetching = createSelector(
  selectProductProviderDomain,
  (productState) => productState.getProduct.fetching,
);

const selectGetProductFetched = createSelector(
  selectProductProviderDomain,
  (productState) => productState.getProduct.fetched,
);

// Create Modifier Group
const selectCreateProductInstance = createSelector(
  selectProductProviderDomain,
  (productState) => productState.createProduct.product,
);

const selectCreateProductError = createSelector(
  selectProductProviderDomain,
  (productState) => productState.createProduct.error,
);

const selectCreateProductCreating = createSelector(
  selectProductProviderDomain,
  (productState) => productState.createProduct.creating,
);

const selectCreateProductCreated = createSelector(
  selectProductProviderDomain,
  (productState) => productState.createProduct.created,
);

export {
  selectProductProviderDomain,
  // Get Product
  selectGetProductInstance,
  selectGetProductError,
  selectGetProductFetching,
  selectGetProductFetched,
  // Create Product
  selectCreateProductInstance,
  selectCreateProductError,
  selectCreateProductCreating,
  selectCreateProductCreated,
};
