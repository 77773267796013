/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';

import authReducer from 'containers/AuthProvider/reducer';
import { setStore } from 'containers/AuthProvider/actions';

import globalReducer from './globalReducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    global: globalReducer,
    router: connectRouter(history),
    auth: authReducer,
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    const currentState = state;

    if (action.type === setStore.SET_STORE) {
      state = undefined;
      state = {
        global: { ...currentState.global },
        router: { ...currentState.router },
        auth: { ...currentState.auth },
      };
    }

    return appReducer(state, action);
  };

  // const rootReducer = combineReducers({
  //   global: globalReducer,
  //   router: connectRouter(history),
  //   auth: authReducer,
  //   ...injectedReducers,
  // });

  return rootReducer;
}
