import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectKitchenTicketProviderDomain = (state) => state.settingsKitchenTicketProvider || initialState;

const selectKitchenTicketDetails = createSelector(
  selectKitchenTicketProviderDomain,
  (subState) => subState.getKitchenTicket.order,
);

const selectKitchenTicketDetailsState = createSelector(
  selectKitchenTicketProviderDomain,
  ({ getKitchenTicket: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectKitchenTicketUpdateState = createSelector(
  selectKitchenTicketProviderDomain,
  ({ updateKitchenTicket: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectKitchenTicketProviderDomain,
  selectKitchenTicketDetails,
  selectKitchenTicketDetailsState,
  selectKitchenTicketUpdateState,
};
