import { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import camelCase from 'lodash/camelCase';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonNavigationBack from '@design-system/ButtonNavigationBack';
import InputTextFiled from '@design-system/InputTextFiled';
import Checkbox from '@design-system/Checkbox';
import IconButton from '@design-system/IconButton';

import useIconParrot from 'hooks/useIconParrot';

import { namespacesTypes, namespacesCheckIncludes } from 'utils/roles/namespacesTypes';

import { routesPaths } from './constants';
import useStyles from './styles';

function RoleCreateForm({ loading, assignablePermissions, onSubmit }) {
  const { t } = useTranslation('workforce');
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const { IconChevronDown } = useIconParrot({ icon: 'chevronDown' });

  const [namespacesState, setNamespacesState] = useState(namespacesTypes);
  const [permissionStates, setPermissionStates] = useState([]);
  const [namespacesIncludesAll, setNamesSpacesIncludesAll] = useState(namespacesCheckIncludes);

  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
  });

  const { isValid } = formState;

  function handleSubmitValues(values) {
    const savePayload = {
      name: values?.nameRolField,
      permissions: permissionStates,
    };
    onSubmit(savePayload);
  }

  function hangleOpenNamespace(value) {
    const namespacesStateTmp = [...namespacesState];
    if (namespacesState.includes(value)) {
      setNamespacesState([...namespacesStateTmp].filter((item) => item !== value));
    } else {
      namespacesStateTmp.push(value);
      setNamespacesState(namespacesStateTmp);
    }
  }

  function handleToggle(namespacesEnum, slug) {
    const newState = [...permissionStates];
    const newStateAll = [...namespacesIncludesAll[namespacesEnum]];
    if (newState.includes(slug)) {
      setPermissionStates([...newState].filter((item) => item !== slug));
      setNamesSpacesIncludesAll((state) => ({
        ...state,
        [namespacesEnum]: [...newStateAll].filter((item) => item !== slug),
      }));
    } else {
      newState.push(slug);
      newStateAll.push(slug);
      setPermissionStates(newState);
      setNamesSpacesIncludesAll((state) => ({
        ...state,
        [namespacesEnum]: newStateAll,
      }));
    }
  }

  function returnCheckNamespace(value) {
    return namespacesIncludesAll[value]?.length === permissionsByNamespace[value]?.length;
  }

  function handleGoBack() {
    history.push({
      pathname: routesPaths.ROUTE_ROLES,
    });
  }

  const permissionsByNamespace = groupBy(assignablePermissions, 'namespace');
  const sortedNamespaces = sortBy(Object.keys(permissionsByNamespace));

  function handleSelectAllPermisionsByNamespaces(value, checked) {
    let newState = [...permissionStates];
    const newStateAll = [...namespacesIncludesAll[value]];
    if (checked) {
      permissionsByNamespace[value]?.forEach((permission) => {
        if (!newState.includes(permission?.slug)) {
          newState.push(permission?.slug);
          newStateAll.push(permission?.slug);
        }
      });

      setPermissionStates(newState);
      setNamesSpacesIncludesAll((state) => ({
        ...state,
        [value]: newStateAll,
      }));
    } else {
      permissionsByNamespace[value]?.forEach((permission) => {
        newState = [...newState].filter((item) => item !== permission?.slug);
      });
      setPermissionStates(newState);
      setNamesSpacesIncludesAll((state) => ({
        ...state,
        [value]: [],
      }));
    }
  }

  return (
    <Box>
      <Box className={classes.header}>
        <ButtonNavigationBack disabled={loading} onClick={handleGoBack}>
          {t('common:buttons.toReturn')}
        </ButtonNavigationBack>
        <ButtonPrimary
          color="primary"
          disabled={loading || !isValid}
          endIcon={loading && <CircularProgress color="inherit" size={14} />}
          onClick={handleSubmit(handleSubmitValues)}
          variant="contained"
        >
          {t('common:buttons.save')}
        </ButtonPrimary>
      </Box>
      <Box>
        <Box p={`0 ${theme.typography.pxToRem(32)}`}>
          <Grid container direction="column" spacing={4}>
            <Grid item lg={5} md={8} xs={12}>
              <InputTextFiled
                autoFocus
                error={Boolean(errors?.nameRolField)}
                fullWidth
                helperText={errors?.nameRolField?.message}
                id="nameRolField"
                inputProps={{
                  maxLength: 150,
                }}
                inputRef={register({
                  required: t('common:errors.requiredField'),
                })}
                label={t('workforce:editForm.namePlaceholder')}
                name="nameRolField"
                required
              />
            </Grid>
          </Grid>
          <Box mt="3.5rem">
            <Box fontSize={theme.typography.pxToRem(18)} fontWeight={theme.typography.fontWeightMedium}>
              {t('workforce:editForm.permissionSection.label')}
            </Box>
            <Box color="darkGrey.main" mt={1}>
              {t('workforce:editForm.permissionSection.description')}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box id="permissions" mt="3.5rem">
        {sortedNamespaces?.map((namespace) => (
          <Box key={namespace}>
            <Box className={classes.namespaceHeader}>
              <Checkbox
                checked={returnCheckNamespace(namespace)}
                indeterminate={returnCheckNamespace(namespace)}
                onChange={(e) => handleSelectAllPermisionsByNamespaces(namespace, e.target.checked)}
              />
              <IconButton
                endIcon={
                  <IconChevronDown
                    className={clsx({ [classes.endIconRotate]: namespacesState.includes(namespace) })}
                    fontSize="small"
                  />
                }
                onClick={() => hangleOpenNamespace(namespace)}
              >
                <Box className={classes.labelButton} component="span">
                  {t(`workforce:permissions.namespaces.${namespace}`)}
                </Box>
              </IconButton>
            </Box>
            <Collapse in={namespacesState.includes(namespace)}>
              <List>
                {sortBy(permissionsByNamespace[namespace], 'description')?.map((permission) => (
                  <ListItem key={permission?.slug} onClick={() => handleToggle(namespace, permission?.slug)}>
                    <Box className={classes.itemPermissionNamespace}>
                      <Checkbox
                        checked={permissionStates.includes(permission?.slug)}
                        defaultChecked={permissionStates.includes(permission?.slug)}
                        inputRef={register()}
                        name={camelCase(namespace)}
                        value={permission?.slug}
                      />
                      <Box>{permission?.description}</Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

RoleCreateForm.propTypes = {
  loading: PropTypes.bool,
  assignablePermissions: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default RoleCreateForm;
