import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getInvoicesOrdersEditRequest({ startDate, endDate, invoicingConfig, brands, page }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    invoicing_config: invoicingConfig,
    brand: brands,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
    page,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateInvoicesOrderEditRequest(
  {
    startDate,
    endDate,
    invoicingConfig,
    brands,
    includedOrders,
    excludedOrders,
    editionType,
    quantity,
    appliedChanges,
  },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    invoicing_config: invoicingConfig,
    brand: brands,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/update/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      included_orders: includedOrders,
      excluded_orders: excludedOrders,
      editionType,
      quantity,
      appliedChanges,
    }),
    ...options,
  });
}

export function resetEditedInvoicesOrdersRequest(
  { startDate, endDate, invoicingConfig, brands, includedOrders, excludedOrders, appliedChanges },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    invoicing_config: invoicingConfig,
    brand: brands,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/reset/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      included_orders: includedOrders,
      excluded_orders: excludedOrders,
      appliedChanges,
    }),
    ...options,
  });
}

export function generateInvoicingRequest(
  { startDate, endDate, invoicingConfig, brands, includedOrders, excludedOrders, appliedChanges },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    invoicing_config: invoicingConfig,
    brand: brands,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/generate/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      included_orders: includedOrders,
      excluded_orders: excludedOrders,
      appliedChanges,
    }),
    ...options,
  });
}

export function getInvoicesOrderDetailsEditRequest({ orderUuid, invoicingConfig }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    invoicing_config: invoicingConfig,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/${orderUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function removeItemsOrderEditRequest({ orderUuid, invoicingConfig, sessionId, itemUuid, type }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    invoicing_config: invoicingConfig,
    session_id: sessionId,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/${orderUuid}/update-content/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      instanceType: type,
      instanceUuid: itemUuid,
    }),
    ...options,
  });
}

export function applyDiscountOrderEditRequest({ orderUuid, invoicingConfig, sessionId, quantity }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    invoicing_config: invoicingConfig,
    session_id: sessionId,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/${orderUuid}/update-discounts/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      quantity,
    }),
    ...options,
  });
}

export function resetChangesOrderEditRequest({ orderUuid, invoicingConfig, sessionId }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    invoicing_config: invoicingConfig,
    session_id: sessionId,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/${orderUuid}/reset/?${query}`, {
    method: 'PUT',
    ...options,
  });
}

export function saveChangesOrderEditRequest({ orderUuid, invoicingConfig, sessionId }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    invoicing_config: invoicingConfig,
    session_id: sessionId,
  });

  return request(`${API_BASE_URL}/v1/invoicing/edited-orders/${orderUuid}/apply-changes/?${query}`, {
    method: 'PUT',
    ...options,
  });
}
