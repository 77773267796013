import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import permissionsSaga from './sagas';
import reducer from './reducer';

export function PermissionsProvider({ children }) {
  useInjectSaga({ key: 'permissionsProvider', saga: permissionsSaga });
  useInjectReducer({ key: 'permissionsProvider', reducer });

  return React.Children.only(children);
}

PermissionsProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withPermissions } from './withPermissions';
export default compose(withConnect, memo)(PermissionsProvider);
