import { Children } from 'react';
import PropTypes from 'prop-types';

import SettingsPayInProvider from 'containers/SettingsPayInProvider';
import SettingsAdminTablesProvider from 'providers/SettingsAdminTablesProvider';
import SettingsServiceAreasProvider from 'providers/SettingsServiceAreasProvider';
import SettingsTablesConfigProvider from 'providers/SettingsTablesConfigProvider';
import SettingsKitchenTicketProvider from 'providers/SettingsKitchenTicketProvider';
import SettingsServiceChargesProvider from 'providers/SettingsServiceChargesProvider';
import SettingsStoreProvider from 'providers/SettingsStoreProvider';

import ComposeProviders from 'components/ComposeProviders';

function SettingsRootProvider({ children }) {
  return (
    <ComposeProviders
      providers={[
        <SettingsKitchenTicketProvider />,
        <SettingsServiceAreasProvider />,
        <SettingsAdminTablesProvider />,
        <SettingsTablesConfigProvider />,
        <SettingsServiceChargesProvider />,
        <SettingsPayInProvider />,
        <SettingsStoreProvider />,
      ]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

SettingsRootProvider.propTypes = {
  children: PropTypes.element,
};

export default SettingsRootProvider;
