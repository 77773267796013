import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import IconEmptyReports from '@experimental-components/IconsComponents/Empty';
import IconEmptyBrands from '@experimental-components/IconsComponents/EmptyBrands';
import IconEmptyDefault from '@experimental-components/IconsComponents/EmptyDefault';

const typeIconComponents = {
  empty: IconEmptyDefault,
  emptyBrands: IconEmptyBrands,
  emptyReports: IconEmptyReports,
};

function DisplayMessage({
  severity = 'empty',
  message,
  maxWidth = 200,
  sizeIcon = 57,
  title,
  sizeTitle = 24,
  sizeMessage = 18,
  ...others
}) {
  const TypeIconComponent = typeIconComponents[severity] ?? null;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      maxHeight={500}
      {...others}
    >
      <TypeIconComponent size={sizeIcon} />

      <Box display="flex" flexDirection="column" justifyContent="center" mt={title ? 5 : 3} textAlign="center">
        {title && (
          <Box mb={1}>
            <Box fontSize={sizeTitle} fontWeight="fontWeightMedium">
              {title}
            </Box>
          </Box>
        )}

        {message && (
          <Box maxWidth={maxWidth} width="100%">
            <Box fontSize={sizeMessage} textAlign="center">
              {message}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

DisplayMessage.propTypes = {
  severity: PropTypes.oneOf([
    'error',
    'info',
    'success',
    'warning',
    'searchInfo',
    'empty',
    'emptyBrands',
    'emptyReports',
  ]),
  message: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.number,
  sizeIcon: PropTypes.number,
  sizeTitle: PropTypes.number,
  sizeMessage: PropTypes.number,
};

export default DisplayMessage;
