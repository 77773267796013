import { createSelector } from 'reselect';

import { createInitialReportState, createInitialReportDetailsState } from './reducer';

const selectReportsState = (state, props) => {
  return state?.reportsList[props?.storeUuid] || createInitialReportState;
};

const selectReportDetailsState = (state, props) => {
  return state?.reportDetailsList[props?.reportKey] || createInitialReportDetailsState;
};

const selectReports = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.reports,
);

const selectReportsProductDetailsStatus = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.productDetailsSales,
);

const selectReportsProductDetails = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.productDetailsSales?.product,
);

const selectReportsPagination = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.pagination,
);

const selectReportsFetching = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.fetching,
);

const selectReportsFetched = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.fetched,
);

const selectReportsError = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.error,
);

const selectReportsPaginationStatus = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.loadPaginationStatus,
);

const selectExportReportsStatus = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.exportReportsToExcel,
);

const selectReportsSessions = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.cashRegisterSessions,
);

const selectPaginationStatusCashRegisterSessions = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.paginationStatusCashRegisterSessions,
);

const selectPaginationValuesCashRegisterSessions = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.paginationCashRegisterSessions,
);

const selectReportsCashSessionsDetails = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportDetailsState(reportsState, props)?.reportsDetails,
);

const selectCashSessionsDetailsExportReportStatus = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportDetailsState(reportsState, props)?.exportReportToExcel,
);

const selectReportsCashSessionsDetailsPaginationStatus = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportDetailsState(reportsState, props)?.paginationStatus,
);

const selectReportsCashSessionsDetailsPagination = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportDetailsState(reportsState, props)?.pagination,
);

const selectReportsCashSessionsDetailsAll = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportDetailsState(reportsState, props)?.reportsDetailsAll,
);

const selectReportsSortingStatus = createSelector(
  (state) => state.reportsProvider,
  (_, props) => props,
  (reportsState, props) => selectReportsState(reportsState, props)?.sortingStatus,
);

export {
  selectReports,
  selectReportsProductDetailsStatus,
  selectReportsProductDetails,
  selectReportsPagination,
  selectReportsFetching,
  selectReportsFetched,
  selectReportsError,
  selectReportsPaginationStatus,
  selectExportReportsStatus,
  selectReportsSessions,
  selectPaginationValuesCashRegisterSessions,
  selectPaginationStatusCashRegisterSessions,
  selectReportsCashSessionsDetails,
  selectReportsCashSessionsDetailsPaginationStatus,
  selectReportsCashSessionsDetailsPagination,
  selectCashSessionsDetailsExportReportStatus,
  selectReportsCashSessionsDetailsAll,
  selectReportsSortingStatus,
};
