import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ChevronDown = forwardRef(function ChevronDown(props, ref) {
  const { color = '#fff', size = 16, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M14.7071 4.29289C14.3166 3.90237 13.6834 3.90237 13.2929 4.29289L8 9.58579L2.70711 4.29289C2.31658 3.90237 1.68342 3.90237 1.29289 4.29289C0.902368 4.68342 0.902368 5.31658 1.29289 5.70711L7.29289 11.7071C7.68342 12.0976 8.31658 12.0976 8.70711 11.7071L14.7071 5.70711C15.0976 5.31658 15.0976 4.68342 14.7071 4.29289Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

ChevronDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ChevronDown;
