import React from 'react';
import PropTypes from 'prop-types';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/dayjs';

import ViewPanelProvider from 'containers/ViewPanelProvider';
import ContentLayoutProvider from 'containers/ContentLayoutProvider';
import SidebarLayoutProvider from 'containers/SidebarLayoutProvider';
import MenuMakerAdminProvider from 'providers/MenuMakerAdminProvider';
import MenuMakerDetailsProvider from 'containers/MenuMakerDetailsProvider';
import CategoriesProvider from 'containers/CategoriesProvider';
import ModifierGroupsProvider from 'containers/ModifierGroupsProvider';
import ModifierGroupProvider from 'containers/ModifierGroupProvider';
import ProductsProvider from 'containers/ProductsProvider';
import ProductProvider from 'containers/ProductProvider';
import CategoryDetailProvider from 'containers/CategoryDetailProvider';
import RolesProvider from 'containers/RolesProvider';
import MenuProductsProvider from 'containers/MenuProductsProvider';
import RoleProvider from 'containers/RoleProvider';
import PermissionsProvider from 'containers/PermissionsProvider';
import EmployeesProvider from 'containers/EmployeesProvider';
import EmployeesDetailsProvider from 'containers/EmployeesDetailsProvider';
import PinProvider from 'containers/PinProvider';
import RestaurantDiscountsProvider from 'containers/RestaurantDiscountsProvider';
import RestaurantPaymentsProvider from 'containers/RestaurantPaymentsProvider';
import RestaurantDiningOptionsProvider from 'containers/RestaurantDiningOptionsProvider';
import BrandsProvider from 'containers/BrandsProvider';
import EmailProvider from 'containers/EmailProvider';
import ReportsProvider from 'containers/ReportsProvider';
import ReportsFiltersProvider from 'containers/ReportsFiltersProvider';
import AddressProvider from 'containers/AddressProvider';
import MenuMakerPreviewProvider from 'providers/MenuMakerPreviewProvider';
import InvoicingProvider from 'providers/InvoicingProvider';
import RestaurantVoidReasonsProvider from 'containers/RestaurantVoidReasonsProvider';
import RestaurantReceiptCustomizationProvider from 'containers/RestaurantReceiptCustomizationProvider';
import SubscriptionProvider from 'providers/SubscriptionProvider';
import ReportsOrdersProvider from 'providers/ReportsOrdersProvider';
import ReportsPaymentsProvider from 'providers/ReportsPaymentsProvider';
import BrandsProviderV2 from 'providers/BrandsProviderV2';
import InvoicesTicketsEditorProvider from 'providers/InvoicesTicketsEditorProvider';
import KitchenAreasProvider from 'providers/KitchenAreasProvider';
import ReportsCancellationsProvider from 'providers/ReportsCancellationsProvider';
import StockCategoriesProvider from 'providers/StockCategoriesProvider';
import StorageAreasProvider from 'providers/StorageAreasProvider';
import OnlineOrderingProvider from 'providers/OnlineOrderingProvider';
import ReportsDiscountsProvider from 'providers/ReportsDiscountsProvider';
import ReportsResumeProvider from 'providers/ReportsResumeProvider';
import ReportsClientDataProvider from 'providers/ReportsClientDataProvider';
import ListsOptionsProvider from 'providers/ListsOptionsProvider';
import GoogleMapsProvider from 'providers/GoogleMapsProvider';

import ParrotGoogleMapProvider from '@experimental-components/GoogleMapsLocation/GoogleMapProvider';

import ComposeProviders from 'components/ComposeProviders';

import InventoriesRootsProvider from './AppProviders/InventoriesRootsProvider';
import SettingsRootProvider from './AppProviders/SettingsRootProvider';
import ReportsRootProvider from './AppProviders/ReportsRootProvider';
import PaymentsRootProvider from './AppProviders/PaymentsRootProvider';
import InvoicingRootProvider from './AppProviders/InvoicingRootProvider';

function AppProviders({ children }) {
  return (
    <ComposeProviders
      providers={[
        <MuiPickersUtilsProvider utils={DateFnsUtils} />,
        <ContentLayoutProvider />,
        <ViewPanelProvider />,
        <SidebarLayoutProvider />,
        <ParrotGoogleMapProvider
          config={{
            googleMapURLApi: 'https://maps.googleapis.com/maps/api/js',
            apiKey: 'AIzaSyBmuLEB-EAmaQ2LeHXQCV7IKChAYWF77Z8',
            libraries: ['geometry', 'drawing', 'places'],
          }}
        />,
        <GoogleMapsProvider />,
        <BrandsProvider />,
        <MenuMakerAdminProvider />,
        <MenuMakerDetailsProvider />,
        <CategoriesProvider />,
        <CategoryDetailProvider />,
        <ModifierGroupsProvider />,
        <ModifierGroupProvider />,
        <ProductsProvider />,
        <ProductProvider />,
        <RolesProvider />,
        <MenuProductsProvider />,
        <EmployeesProvider />,
        <RoleProvider />,
        <PermissionsProvider />,
        <EmployeesDetailsProvider />,
        <PinProvider />,
        <RestaurantDiscountsProvider />,
        <RestaurantPaymentsProvider />,
        <RestaurantDiningOptionsProvider />,
        <EmailProvider />,
        <ReportsProvider />,
        <ReportsFiltersProvider />,
        <AddressProvider />,
        <InvoicingProvider />,
        <MenuMakerPreviewProvider />,
        <RestaurantVoidReasonsProvider />,
        <RestaurantReceiptCustomizationProvider />,
        <InvoicingRootProvider />,
        <SubscriptionProvider />,
        <SettingsRootProvider />,
        <ReportsOrdersProvider />,
        <ReportsPaymentsProvider />,
        <BrandsProviderV2 />,
        <InvoicesTicketsEditorProvider />,
        <KitchenAreasProvider />,
        <StockCategoriesProvider />,
        <StorageAreasProvider />,
        <InventoriesRootsProvider />,
        <ReportsCancellationsProvider />,
        <OnlineOrderingProvider />,
        <ReportsDiscountsProvider />,
        <ReportsResumeProvider />,
        <PaymentsRootProvider />,
        <ReportsClientDataProvider />,
        <ReportsRootProvider />,
        <ListsOptionsProvider />,
      ]}
    >
      {React.Children.only(children)}
    </ComposeProviders>
  );
}

AppProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProviders;
