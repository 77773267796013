import queryString from 'query-string';

import request from 'utils/request';
import { paginationConfigDefaults } from 'utils/reports';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getReportsProductsSalesRequest(
  storeUuid,
  { reportFormat, startDate, endDate, brands, providers, orderTypes, categories, page, orderBy },
  options = {},
) {
  const query = queryString.stringify({
    report_format: reportFormat,
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    brand: brands,
    provider: providers,
    orderType: orderTypes,
    category: categories,
    page,
    page_size: paginationConfigDefaults.pageSize,
    order_by: orderBy,
  });

  return request(`${API_BASE_URL}/v1/reports/product-sales/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function exportReportsToExcelRequest(
  storeUuid,
  { startDate, endDate, brands, providers, orderTypes, categories },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    brand: brands,
    provider: providers,
    orderType: orderTypes,
    category: categories,
  });

  return request(`${API_BASE_URL}/v1/reports/product-sales/export/?${query}`, {
    method: 'GET',

    config: {
      responseType: 'blob',
    },
    ...options,
  });
}

export function getReportsProductDetailSalesRequest(
  storeUuid,
  { productUuid, startDate, endDate, brands, providers, orderTypes, categories },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    brand: brands,
    provider: providers,
    orderType: orderTypes,
    category: categories,
  });

  return request(`${API_BASE_URL}/v1/reports/product-sales/${productUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}
