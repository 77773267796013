import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { useSidebarLayout } from 'containers/SidebarLayoutProvider';

import useStyles from './styles';

function SidebarLayoutItem({ disabled, label, startIcon, endIcon, onClick, ...others }) {
  const classes = useStyles();
  const { setOpenDrawer } = useSidebarLayout();

  function handleClick() {
    setOpenDrawer(false);

    if (onClick) {
      onClick();
    }
  }

  return (
    <li className={classes.item} data-testid="navLinkItem">
      <NavLink
        activeClassName={classes.navLinkActive}
        className={clsx(classes.navLink, {
          [classes.navLinkEndIcon]: endIcon,
          [classes.navLinkStartIcon]: startIcon,
          [classes.disabled]: disabled,
        })}
        disabled={disabled}
        exact
        onClick={handleClick}
        {...others}
      >
        {startIcon && startIcon}

        <span className={clsx(classes.navLinkLabel)} data-testid="navLinkLabel">
          {label}
        </span>

        {endIcon && endIcon}
      </NavLink>
    </li>
  );
}

SidebarLayoutItem.propTypes = {
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
};

export default SidebarLayoutItem;
