import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionSummaryMui from '@material-ui/core/AccordionSummary';

import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '12px 40px',
    backgroundColor: theme.palette.darkGrey[2],
  },
  content: {
    display: 'flex',
    flexGrow: 'unset',
    margin: 0,
    padding: 0,
  },
}));

function AccordionSummary({ expandIcon, ...others }) {
  const classes = useStyles();
  const defaultExpandIcon = <ChevronDown color="black" size={18} stroke="black" strokeWidth="1" />;

  return (
    <AccordionSummaryMui
      classes={{ root: classes.root, content: classes.content }}
      expandIcon={expandIcon || defaultExpandIcon}
      IconButtonProps={{ disableRipple: true, disableTouchRipple: true }}
      {...others}
    />
  );
}

AccordionSummary.propTypes = {
  ...AccordionSummaryMui.propTypes,
};

export default AccordionSummary;
