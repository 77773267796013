import { Children } from 'react';
import PropTypes from 'prop-types';

import ReportsCashRegisterSessionDetailProvider from 'providers/ReportsCashRegisterSessionDetailProvider';

function ReportsRootProvider({ children }) {
  return <ReportsCashRegisterSessionDetailProvider>{Children.only(children)}</ReportsCashRegisterSessionDetailProvider>;
}

ReportsRootProvider.propTypes = {
  children: PropTypes.element,
};

export default ReportsRootProvider;
