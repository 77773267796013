/**
 *
 * ParrotSoftware
 * SettingsAdminTablesProvider constants
 *
 */
export const CREATE_TABLE = 'providers/SettingsAdminTablesProvider/CREATE_TABLE';
export const VALIDATE_NAME = 'providers/SettingsServiceAreasProvider/VALIDATE_NAME';
export const CREATE_TABLES_BULK = 'providers/SettingsAdminTablesProvider/CREATE_TABLES_BULK';
export const GET_TABLES = 'providers/SettingsAdminTablesProvider/GET_TABLES';
export const DELETE_TABLE = 'providers/SettingsAdminTablesProvider/DELETE_TABLE';
export const GET_TABLE_DETAILS = 'providers/SettingsAdminTablesProvider/GET_TABLE_DETAILS';
export const UPDATE_TABLE = 'providers/SettingsAdminTablesProvider/UPDATE_TABLE';
export const GET_TABLES_SCROLL_PAGINATION = 'providers/SettingsAdminTablesProvider/GET_TABLES_SCROLL_PAGINATION';
export const UPDATE_TABLE_POSITION = 'providers/SettingsAdminTablesProvider/UPDATE_TABLE_POSITION';
