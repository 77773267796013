import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getVoidReasons, getVoidReasonsByPagination, createVoidReason, deleteVoidReason } from './actions';

import {
  selectVoidReasons,
  selectVoidReasonsFetching,
  selectVoidReasonsFetched,
  selectVoidReasonsError,
  selectVoidReasonsPagination,
  selectVoidReasonsPaginationStatus,
  selectCreateVoidReason,
  selectCreateVoidReasonFetching,
  selectCreateVoidReasonFetched,
  selectCreateVoidReasonError,
  selectDeleteVoidReasonFetching,
  selectDeleteVoidReasonFetched,
  selectDeleteVoidReasonError,
} from './selectors';

export function withRestaurantVoidReasonsHoc(Component) {
  function withRestaurantVoidReasons(props) {
    return <Component {...props} />;
  }

  withRestaurantVoidReasons.displayName = `withRestaurantVoidReasons(${getDisplayName(Component)})`;

  return withRestaurantVoidReasons;
}

const mapStateToProps = createStructuredSelector({
  voidReasonsList: selectVoidReasons,
  voidReasonsFetching: selectVoidReasonsFetching,
  voidReasonsFetched: selectVoidReasonsFetched,
  voidReasonsError: selectVoidReasonsError,
  voidReasonsPaginationValues: selectVoidReasonsPagination,
  voidReasonsPaginationStatus: selectVoidReasonsPaginationStatus,
  createVoidReasonObject: selectCreateVoidReason,
  createVoidReasonFetching: selectCreateVoidReasonFetching,
  createVoidReasonFetched: selectCreateVoidReasonFetched,
  createVoidReasonError: selectCreateVoidReasonError,
  deleteVoidReasonFetching: selectDeleteVoidReasonFetching,
  deleteVoidReasonFetched: selectDeleteVoidReasonFetched,
  deleteVoidReasonError: selectDeleteVoidReasonError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadVoidReasons: ({ storeUuid }) => dispatch(getVoidReasons({ storeUuid })),
    loadVoidReasonsByPagination: ({ storeUuid, page }) => dispatch(getVoidReasonsByPagination({ storeUuid, page })),
    createVoidReason: ({ storeUuid, voidReason }) => dispatch(createVoidReason({ storeUuid, voidReason })),
    deleteVoidReason: ({ storeUuid, voidReasonUuid }) => dispatch(deleteVoidReason({ storeUuid, voidReasonUuid })),
    resetVoidReasons: () => dispatch(getVoidReasons.reset()),
    resetVoidReasonsByPagination: () => dispatch(getVoidReasonsByPagination.reset()),
    resetCreateVoidReason: () => dispatch(createVoidReason.reset()),
    resetDeleteVoidReason: () => dispatch(deleteVoidReason.reset()),
  };
}

export const withRestaurantVoidReasonsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRestaurantVoidReasonsState, withRestaurantVoidReasonsHoc);
