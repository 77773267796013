import PropTypes from 'prop-types';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    color: theme.palette.common.black,

    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },

  startIcon: {
    display: 'inherit',
    marginRight: 16,
    marginLeft: -4,
    // '&$iconSizeSmall': {
    //   marginLeft: -2,
    // },
  },

  endIcon: {
    display: 'inherit',
    marginRight: -4,
    marginLeft: 16,
    // '&$iconSizeSmall': {
    //   marginRight: -2,
    // },
  },

  disabledLabel: {
    color: theme.palette.darkGrey[15],
  },
}));

function IconButton({ children, startIcon, endIcon, disabled, ...others }) {
  const classes = useStyles();

  return (
    <ButtonBase disabled={disabled} disableRipple disableTouchRipple {...others}>
      <span
        className={clsx(classes.label, {
          [classes.disabledLabel]: disabled,
        })}
      >
        {startIcon && <span className={classes.startIcon}>{startIcon}</span>}

        {children}

        {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
      </span>
    </ButtonBase>
  );
}

IconButton.propTypes = {
  ...ButtonBase.propTypes,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export default IconButton;
