import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

export function RestaurantReceiptCustomizationProvider({ children }) {
  useInjectSaga({ key: 'restaurantReceiptCustomizationProvider', saga });
  useInjectReducer({ key: 'restaurantReceiptCustomizationProvider', reducer });

  return React.Children.only(children);
}

RestaurantReceiptCustomizationProvider.propTypes = {
  children: PropTypes.element,
};

const withConnect = connect();

export { default as withRestaurantReceiptCustomization } from './withRestaurantReceiptCustomization';
export default compose(withConnect, memo)(RestaurantReceiptCustomizationProvider);
