import { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { selectProductsPagination } from 'containers/ProductsProvider/selectors';
import { getProducts } from 'containers/ProductsProvider/actions';

function usePaginationProducts() {
  const dispatch = useDispatch();
  const pagination = useSelector(selectProductsPagination);

  function handleSetPagination({ page, pageSize }) {
    dispatch(getProducts.pagination({ page, pageSize }));
  }

  function handleResetPagination() {
    dispatch(getProducts.resetPagination());
  }

  return {
    pagination,
    setPagination: useCallback(handleSetPagination, [dispatch]),
    resetPagination: useCallback(handleResetPagination, [dispatch]),
  };
}

export default usePaginationProducts;
