import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getMenuPreviewRequest,
  getMenuCategoryProductsRequest,
  getMenuProductDetailsRequest,
  exportMenuRequest,
} from 'utils/api/baas/menuMaker/menuPreview';

import { getMenuPreview, getMenuCategoryProducts, getMenuProductDetails, exportMenu } from './actions';

export function* handleGetMenuPreviewRequest(action) {
  const { menuUuid, storeUuid } = action.payload;

  try {
    yield put(getMenuPreview.request());

    const { data } = yield call(baasRequest, getMenuPreviewRequest, {
      menuUuid,
      storeUuid,
    });

    yield put(getMenuPreview.success(data));
  } catch (err) {
    yield put(getMenuPreview.failure(err));
  } finally {
    yield put(getMenuPreview.fulfill());
  }
}

export function* handleGetMenuCategoryProductsRequest(action) {
  const { menuUuid, storeUuid, categoryUuid } = action.payload;

  try {
    yield put(getMenuCategoryProducts.request());

    const { data: products } = yield call(baasRequest, getMenuCategoryProductsRequest, {
      menuUuid,
      storeUuid,
      categoryUuid,
    });

    yield put(getMenuCategoryProducts.success({ products, categoryUuid }));
  } catch (err) {
    yield put(getMenuCategoryProducts.failure(err));
  } finally {
    yield put(getMenuCategoryProducts.fulfill());
  }
}

export function* handleGetMenuProductDetailsRequest(action) {
  const { menuUuid, storeUuid, productUuid } = action.payload;
  try {
    yield put(getMenuProductDetails.request());

    const { data: details } = yield call(baasRequest, getMenuProductDetailsRequest, {
      menuUuid,
      storeUuid,
      productUuid,
    });

    yield put(getMenuProductDetails.success({ productUuid, details }));
  } catch (err) {
    yield put(getMenuProductDetails.failure(err));
  } finally {
    yield put(getMenuProductDetails.fulfill());
  }
}

export function* exportMenuSaga({ payload }) {
  try {
    yield put(exportMenu.request());

    const result = yield call(baasRequest, exportMenuRequest, payload);

    yield put(exportMenu.success(result));
  } catch (error) {
    yield put(exportMenu.failure(error));
  }
}

export default function* menuMakerPreviewProviderSaga() {
  yield takeLatest(getMenuPreview.TRIGGER, handleGetMenuPreviewRequest);
  yield takeLatest(getMenuCategoryProducts.TRIGGER, handleGetMenuCategoryProductsRequest);
  yield takeLatest(getMenuProductDetails.TRIGGER, handleGetMenuProductDetailsRequest);
  yield takeLatest(exportMenu.TRIGGER, exportMenuSaga);
}
